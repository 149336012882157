import {
  MotifProgressIndicatorStep,
  MotifProgressIndicator,
} from "@ey-xd/motif-react";
import { StepperCompletedStage, StepperActiveStage } from "../../icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./Stepper.scss";
import { onClickStep } from "../../store/slices/engagementSlice";

export function Stepper({
  steps,
  stepIndex,
  currentStep,
  size = "md",
  className,
  disableStepper,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const simulateFlag = useSelector((state) => state.engagement.simulateFlag);
  const getStatus = (itemIdx) => {
    if (itemIdx === currentStep) {
      return "active";
    } else if (itemIdx <= stepIndex) {
      if (itemIdx === 4) {
        if (simulateFlag) {
          return "completed";
        } else {
          return "default";
        }
      } else {
        return "completed";
      }
    } else if (itemIdx > stepIndex) {
      return "default";
    }
  };

  const onStepperClick = (item) => {
    navigate(`/create-new-engagement`, {
      gestureEnabled: false,
    });
    dispatch(onClickStep(item.stepIndex));
  };

  return (
    <MotifProgressIndicator
      variant="row"
      className={`decarb-stepper ${className} ${
        size === "small" ? "decarb-stepper-small" : ""
      }`}
      size={size === "small" ? "sm" : "md"}
    >
      {steps.map((item, index) => {
        const stepStatus = getStatus(index);
        const currectIcon =
          stepStatus === "completed"
            ? { complete: <StepperCompletedStage /> }
            : { incomplete: <StepperActiveStage /> };

        return (
          <MotifProgressIndicatorStep
            heading={item.stepName}
            status={stepStatus}
            key={index}
            onClick={() => onStepperClick(item)}
            disabled={stepStatus !== "completed" && index !== currentStep}
            className={disableStepper === true ? "disableStep" : ""}
            icons={currectIcon}
          />
        );
      })}
    </MotifProgressIndicator>
  );
}
