import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "../../Table/Table";
import CustomInputRenderer from "./CustomInputRenderer";
import CustomCheckboxRenderer from "./CustomCheckbox";
import {
  disableEditButton,
  editData,
  emissionsEditButtonTooltip,
  emissionsFilterbuttonTooltip,
  filters,
  tablecolumnFilters,
} from "../../../util/constants";
import { suppressEnter, suppressNavigation } from "../../../util/tabUtil";
import {
  setSelectedRowIndex,
  setSelectedRowData,
  setIsAnyRowSelected,
  setSelected,
  setIsManualEdit,
  setManualEditRules,
  getLockingData,
  resetManualEditRules,
} from "../../../store/slices/projectSlice";
import { Button } from "../../Button/Button";
import { Select } from "../../Select/Select";
import { EditIcon, FilterIcon } from "../../../icons";
import { EmissionFilterModal } from "../EmissionsFilterModal/EmissionFilterModal";
import { Tooltip } from "../../CommonUIComponents/Tooltip/Tooltip";
export function EmissionsTable({ loader }) {
  const dispatch = useDispatch();
  const reduceDefaultViewData = useSelector(
    (state) => state.project.defaultViewData
  );
  const isSimulate = useSelector((state) => state.project.isSimulate);
  const simulateTableData = useSelector(
    (state) => state.project.simulateTableData
  );
  const isManualEdit = useSelector((state) => state.project.isManualEdit);
  const isExtend = useSelector((state) => state.project.isExtend);
  const [rowData, setRowData] = useState([]);
  const [enableEdit, setEnableEdit] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [enableCheckbox, setenablecheckBox] = useState(true);
  const emissionHeadersFilter = useSelector(
    (state) => state.project.selectedUIHeaderFilters
  );

  const project = useSelector((state) => state.project.project);
  const selectedDataType = useSelector(
    (state) => state.project.selectedDataType
  );
  const user = useSelector((state) => state.user.user);
  const [lockedData, setLock] = useState();
  const [lockedBy, setLockedby] = useState();
  // Refresh table view on click of edit button

  // Show loader
  useEffect(() => {
    if (loader) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
  }, [loader]);

  // Check Default M2 Data to create Dynamic rows and columns
  useEffect(() => {
    if (reduceDefaultViewData.tableData && !isSimulate) {
      setShowLoader(true);
      if (isManualEdit) {
        let data = reduceDefaultViewData?.tableData.filter(
          (element) => element.data_type === selectedDataType
        );
        setRowData([...data]);
      } else if (isExtend) {
        let data = reduceDefaultViewData?.tableData.filter(
          (element) => element.data_type !== "Emissions"
        );
        setRowData([...data]);
      }

      createTableColumns(
        reduceDefaultViewData.tableHeaderData,
        reduceDefaultViewData.tableHeaderData.length
      );
      // setselectedColumnFilter("Forecast start year");
      setShowLoader(false);
    }
    if (isSimulate) {
      if (isManualEdit) {
        let data = simulateTableData?.filter(
          (element) => element.data_type === selectedDataType
        );
        setRowData([...data]);
        createTableColumns(
          reduceDefaultViewData.tableHeaderData,
          reduceDefaultViewData.tableHeaderData.length
        );
      } else {
        setRowData([...simulateTableData]);
        createTableColumns(
          reduceDefaultViewData.tableHeaderData,
          reduceDefaultViewData.tableHeaderData.length
        );
      }
    }
  }, [reduceDefaultViewData, isSimulate, simulateTableData, selectedDataType]);

  // On input value change for Manual edit functionality
  const onValueChange = (original, field, value, data_type, rowIndex, data) => {
    if (
      parseFloat(value) !== parseFloat(original) &&
      !isNaN(value) &&
      original !== null
    ) {
      let obj = manualObject(original, field, value, data_type, rowIndex, data);
      if (
        obj.abatement_value !== null &&
        obj.abatement_value !== NaN &&
        obj.abatement_value !== undefined
      ) {
        dispatch(setManualEditRules({ array: obj }));
      }
    }
  };

  const manualObject = (original, field, value, data_type, rowIndex, data) => {
    let x = {};
    if (data_type === "Emission factor") {
      x.start_year = parseInt(field);
      x.end_year = parseInt(field);
      x.abatement_value = parseFloat(value);
      x.value = parseFloat(value);
      x.rowIndex = rowIndex;
      x.data = data;
    } else {
      let val = (x.start_year = parseInt(field));
      x.end_year = parseInt(field);
      x.abatement_value =
        parseFloat(original) === 0
          ? parseFloat(value)
          : ((parseFloat(value) - parseFloat(original)) /
              parseFloat(original)) *
            100;
      x.value = parseFloat(value);
      x.rowIndex = rowIndex;
      x.data = data;
    }
    return x;
  };

  // render collapsed table column
  const renderCollapsedColumn = (index, data) => {
    let dataArr = [];
    data.map((item, i) => {
      if (i === index) {
        dataArr.push({
          columnGroupShow: "closed",
          field: item.field,
          suppressMovable: true,
        });
        dataArr.push({
          columnGroupShow: "open",
          field: item.field,
          suppressMovable: true,
        });
      } else if (
        i > index &&
        i < (index === 0 || index === 7 ? index + 4 : index + 3)
      )
        dataArr.push({
          columnGroupShow: "open",
          field: item.field,
          suppressMovable: true,
        });
    });
    return dataArr;
  };

  // render collpased column name
  const showCollapsedHeaderName = (index) => {
    let headerName = "";
    switch (index) {
      case 0:
        headerName = "Emission";
        break;
      case 4:
        headerName = "Geographical";
        break;
      case 7:
        headerName = "Organisational";
        break;
      case 11:
        headerName = "Custom";
        break;
      default:
    }
    return headerName;
  };

  // Create Dy
  // namic Columns for Reduce Table
  const createTableColumns = (data, length) => {
    // dispatch(setIsAnyRowSelected({ isAnyRowSelected: false }));
    if (data?.length > 0) {
      let colDefs = [];
      data.map((item, i) => {
        if (i < length) {
          // if (enableEdit) {
          if (i > 15) {
            colDefs.push({
              field: item.field,
              minWidth: 200,
              flex: 1,
              headerName: item.headerName,
              headerTooltip: item.headerName,
              suppressMovable: true,
              cellRenderer: CustomInputRenderer,
              suppressCellFocus: false,
              suppressKeyboardEvent: (params) => {
                return suppressEnter(params) || suppressNavigation(params);
              },
              cellRendererParams: () => {
                return {
                  field: item.field,
                  onValueChange: onValueChange,
                };
              },
              resizable: true,
              wrapText: true,
              autoHeight: true,
              wrapHeaderText: true,
              autoHeaderHeight: true,
              sortable: false,
            });
          } else {
            if (i === 0 || i === 4 || i === 7 || i === 11) {
              colDefs.push({
                field: item.field,
                minWidth: 150,
                flex: 1,
                headerName: showCollapsedHeaderName(i),
                children: renderCollapsedColumn(i, data),
                marryChildren: true,
                headerTooltip: showCollapsedHeaderName(i),
                suppressMovable: true,
                pinned: i < 4 ? "left" : "",
                resizable: true,
                wrapText: true,
                autoHeight: true,
                wrapHeaderText: true,
                autoHeaderHeight: true,
                sortable: false,
              });
            } else if (i === 14 || i === 15) {
              colDefs.push({
                field: item.field,
                minWidth: 150,
                flex: 1,
                headerName: item.headerName,
                headerTooltip: item.headerName,
                suppressMovable: true,
                resizable: true,
                wrapText: true,
                autoHeight: true,
                wrapHeaderText: true,
                autoHeaderHeight: true,
                sortable: false,
              });
            }
          }
        }
      });
      setColumnDefs(colDefs);
    }
  };

  // to get a locked user flags
  useEffect(() => {
    if (project.projectId) {
      const request = {
        LockedBy: user.username,
        ModuleName: "Project",
        EngagementID: project.projectId,
      };

      dispatch(getLockingData(request)).then((action) => {
        if (action) {
          const lockedData = action.payload?.data?.lockStatus;
          const lockedBy = action.payload?.data?.lockedBy;
          setLock(lockedData);
          setLockedby(lockedBy);
        }
      });
    }
  }, [dispatch, project.projectId]);

  return (
    <div className="emissions-table">
      <div className="emissions-table-table-section">
        <Table
          colDefs={columnDefs}
          rowData={rowData}
          rowSelect={"single"}
          draggable={false}
          loading={showLoader}
        />
      </div>
    </div>
  );
}
