import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../Button/Button";
import { routes } from "../../routes/routes.constatns";
import { Table } from "../Table/Table";
import "./Analyse.scss";
import { ConfirmationModal } from "../CommonUIComponents/ConfirmationModal/ConfimationModal";
import CustomActionsRenderer from "../Analyse/customActionRenderer";
import CustomLinkRenderer from "../Analyse/customProjectLink";
import {
  getAllFlags,
  syncModulebyData,
  updateProject,
  uploadTextFile,
} from "../../store/services/engagement.service";
import {
  analyseBodyTitle,
  analyseBodyDescription,
  createNewProject,
  addGMPBtn,
  analyseTableHeaderProjectName,
  analyseTableHeaderProjectType,
  analyseTableHeaderSimplePaybackPeriod,
  analyseTableHeaderTotalAbatement,
  analyseTableHeaderAverageAnnualAbatement,
  analyseTableHeaderTechnicalAbatementPotential,
  analyseTableHeaderNetPresentValue,
  analyseTableHeaderInternalRateOfReturn,
  analyseTableHeaderDiscountedPaybackPeriod,
  analyseTableHeaderMarginalAbatementCost,
  deleteProjectModalHeader,
  deleteProjectModalBody,
  deleteProjectModalBodyDesc,
  projectToLibraryBody,
  syncData,
  analyseValidationMessage,
  datainSyncMessage,
  analyseDeleteMessage,
  copyFromPastVersions,
  copyProjectsMessage,
} from "../../util/constants";
import {
  setProjectName,
  setProjectType,
  setProjectDescrption,
  setProjectTags,
  setProjectId,
} from "../../store/slices/projectSlice";
import { getAllProjects } from "../../store/services/engagement.service";
import { useSelector, useDispatch } from "react-redux";
import { AddIcon, CopyIcon, RefreshIcon } from "../../icons";
import {
  setDefaultLoader,
  setIsSaveAndNextDisabled,
} from "../../store/slices/engagementSlice";
import { AnalyseLockModal } from "./AnalyseLockModal";
import { AddProjecttoProjectLibrary } from "../../store/services/decarbLibrary.service";
import { CheckIfUserIsAdmin } from "../../store/services/user.service";
import { LoadingIndicatorEmbed } from "../LoadingIndicator/LoadingIndicatorEmbed";
import {
  copyFromPastVersionsProject,
  getColumnValueFlags,
} from "../../store/services/project.service";
import { exportToExcel } from "../../store/services/gmp.service";
import { createExcelFile } from "../../util/excelUtil";
import { formarDateTime, numberFormatter } from "../../util/dateUtil";
import { saveAs } from "file-saver";
import trigger from "../../assets/templates/trigger.txt";
import { uploadFileToBlob } from "../../store/services/azureBlob.service";
export function Analyse() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [data, setSelectedProjectData] = useState([]);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const engagement = useSelector((state) => state.engagement.engagement);
  const user = useSelector((state) => state.user?.user);
  const selectedRegion = useSelector(
    (state) => state.engagement.selectedRegion
  );
  const [analyseTableLoader, setanalyseTableLoader] = useState(false);
  const [isMoveLibraryModalVisible, setMoveLibraryModalVisible] =
    useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isDataNotinSync, setIsDataNotinSync] = useState(true);
  const [isDataNotinSyncLoader, setisDataNotinSyncLoader] = useState(false);
  const [showSyncButton, setShowSyncButton] = useState(false);
  const [deleteInSync, setDeleteInsync] = useState(false);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [syncDatainProgress, setSyncDataInProgress] = useState(false);
  const [showDataSyncMessage, setdataSyncMessage] = useState(false);
  const [isExportM3DataInProgress, setIsExportM3DataInProgress] =
    useState(false);
  const [showCopyButton, setShowCopyButton] = useState(false);
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  let refreshIntervalId = undefined;
  let refreshIntervalCount = 0;
  const [screenRefresh, setScreenRefresh] = useState(false);
  const defaultLoader = useSelector((state) => state.engagement.defaultLoader);

  // Redirection to the create new Project page
  function CreateNewProject() {
    dispatch(setProjectName({ name: "" }));
    dispatch(setProjectType({ type: "" }));
    dispatch(setProjectDescrption({ descrp: "" }));
    dispatch(setProjectTags({ tags: "" }));
    dispatch(setProjectId({ id: "" }));
    navigate(`${routes.createNewProject}`, {
      state: {
        engagementId: engagement.id,
        regionId: selectedRegion?.id,
      },
      gestureEnabled: false,
      swipeEnabled: false,
    });
  }

  // Redirection to the add GMP page
  function addGMP() {
    navigate(`${routes.addGMP}`, {
      state: {
        engagementId: engagement.id,
        regionId: selectedRegion?.id,
      },
      gestureEnabled: false,
    });
  }

  const copyPastVersions = async () => {
    setisDataNotinSyncLoader(true);
    copyProjectsOnLoop();
  };

  // Check Data in Sync flag once delete flag is synced with M2
  useEffect(() => {
    if (deleteInSync) {
      checkDatainSync();
    }
  }, [deleteInSync]);

  // Enabling Sync data and Next button logic
  useEffect(() => {
    if (!isDataNotinSync) {
      setShowSyncButton(true);
      dispatch(setIsSaveAndNextDisabled({ isDisabled: false }));
    } else {
      setShowSyncButton(false);
      dispatch(setIsSaveAndNextDisabled({ isDisabled: true }));
    }
  }, [isDataNotinSync]);

  // Enabling Data in Sync after sinking M3 and M2 flag via API
  useEffect(() => {
    if (syncDatainProgress) {
      setisDataNotinSyncLoader(true);
      checkDatainSync();
    }
  }, [syncDatainProgress]);

  useEffect(() => {
    dispatch(setDefaultLoader({ loader: false }));
    if (engagement?.id) {
      checkProjects();
    }
  }, []);

  useEffect(() => {
    if (tableData.length > 0 && !screenRefresh && !showCopyButton) {
      checkDeleteDataFlag();
      setIsDataNotinSync(true);
    } else if (!screenRefresh) {
      setIsDataNotinSync(false);
    } else {
      setScreenRefresh(false);
    }
  }, [tableData]);

  // Function to check if projects exists or not

  const checkProjects = async () => {
    setanalyseTableLoader(true);
    let projects = [];
    const data = await getAllProjects({
      request: { id: engagement.id },
    });
    projects = [...data.data];
    getM2Flags();
    setanalyseTableLoader(false);
  };
  // function to enable copyprojects button and showing projects
  const getM2Flags = async () => {
    setTableData([]);
    let request = {
      engagementId: engagement.id,
      tableName: "Engagement",
    };
    const data = await getAllFlags({ request });
    if (data.m1_Reuploaded === "Y" && data.m2_Enabled === "Y") {
      let flagsPayload = {
        columnName: "Count",
        tableName: "Engagement",
        id: engagement.id,
      };
      const response = await getColumnValueFlags({ flagsPayload });
      if (parseFloat(response.columnValue) > 0) {
        setShowCopyButton(true);
        setisDataNotinSyncLoader(false);
        fetchData();
      }
    } else if (data.m1_Reuploaded === "Y" && data.m2_Enabled === "N") {
      copyProjectsOnLoop();
      fetchData();
    } else {
      fetchData();
    }
  };

  //function to call the flag checks for every 2 seconds
  const copyProjectsOnLoop = async () => {
    // await uploadFileToBlob({
    //   file: trigger,
    //   moduleName: "copy",
    //   fileName: `TriggerSync_${engagement.engagementCode}_${
    //     user.username
    //   }_${formarDateTime(new Date(), "ddMMyyyy HH:mm:ss")}.txt`,
    // });
    let request = {
      ModuleName: "copy",
      FileName: `TriggerSync_${engagement.engagementCode}_${
        user.username
      }_${formarDateTime(new Date(), "ddMMyyyy HH:mm:ss")}.txt`,
    };
    await uploadTextFile({ request });
    try {
      refreshIntervalId = window.setInterval(checkM2Flags, 2000);
    } catch (error) {
      window.clearInterval(refreshIntervalId);
      refreshIntervalId = undefined;
      setisDataNotinSyncLoader(false);
    }
  };

  // function to check M1 and M2 flags
  async function checkM2Flags() {
    setShowCopyMessage(true);
    refreshIntervalCount += 1;
    let request = {
      engagementId: engagement.id,
      tableName: "Engagement",
    };
    const data = await getAllFlags({ request });
    if (data.m1_Reuploaded === "N") {
      if (refreshIntervalId) {
        window.clearInterval(refreshIntervalId);
        refreshIntervalId = undefined;
        refreshIntervalCount = 0;
      }
      // setTimeout(() => {
      setisDataNotinSyncLoader(false);
      setShowCopyMessage(false);
      setShowCopyButton(false);
      fetchData();
      // }, 5000);
    }
    // } else {
    //   if (refreshIntervalCount >= 30) {
    //     if (refreshIntervalId) {
    //       window.clearInterval(refreshIntervalId);
    //       refreshIntervalId = undefined;
    //       refreshIntervalCount = 0;
    //       setisDataNotinSyncLoader(false);
    //       setShowCopyMessage(false);
    //       setShowCopyButton(false);
    //     }
    //   }
    // }
  }
  // Function to get the project details toload the table
  async function fetchData() {
    setanalyseTableLoader(true);
    const data = await getAllProjects({
      request: { id: engagement.id },
    });
    let dataSet = data.data;
    dataSet.map((item) => {
      if (item.projectTypeID === 1) item["projectTypeName"] = "Reduce";
      else if (item.projectTypeID === 2) item["projectTypeName"] = "Replace";
      else if (item.projectTypeID === 3) item["projectTypeName"] = "Compensate";
      else item["projectTypeName"] = "";
    });
    setTableData(dataSet);
    setanalyseTableLoader(false);
  }

  // checking delete flag status and calling repeatedly until it return 3 for 30 times
  const checkDeleteDataFlag = async () => {
    setisDataNotinSyncLoader(true);
    setSyncDataInProgress(false);
    dispatch(setIsSaveAndNextDisabled({ isDisabled: true }));
    try {
      refreshIntervalId = window.setInterval(checkFlags, 2000);
    } catch (error) {
      window.clearInterval(refreshIntervalId);
      refreshIntervalId = undefined;
      setisDataNotinSyncLoader(false);
    }
  };

  // Flag API implementations
  const checkFlags = async () => {
    refreshIntervalCount++;
    setisDataNotinSyncLoader(true);
    // if (refreshIntervalCount > 30) {
    //   window.clearInterval(refreshIntervalId);
    //   refreshIntervalId = undefined;
    //   refreshIntervalCount = 0;
    //   setisDataNotinSyncLoader(false);
    // }
    let flagsPayload = {
      columnName: deleteInSync ? "Syncdata" : "Delete",
      tableName: "Engagement",
      id: engagement.id,
    };
    const response = await getColumnValueFlags({ flagsPayload });
    if (response.columnValue === "3") {
      // setIsDataNotinSync(false);
      if (refreshIntervalId) {
        window.clearInterval(refreshIntervalId);
        refreshIntervalId = undefined;
      }
      if (flagsPayload.columnName === "Delete") {
        setDeleteInsync(true);
      }

      if (flagsPayload.columnName === "Syncdata") {
        // setTimeout(() => {
        setSyncDataInProgress(false);
        setShowSyncButton(false);
        setIsDataNotinSync(false);
        setisDataNotinSyncLoader(false);
        setdataSyncMessage(false);
        setScreenRefresh(true);
        fetchData();
        // }, 5000);
      }
    }
    if (response.columnValue === "1") {
      if (!syncDatainProgress && flagsPayload.columnName === "Syncdata") {
        setShowSyncButton(true);
        setisDataNotinSyncLoader(false);
        setdataSyncMessage(true);
        if (refreshIntervalId) {
          window.clearInterval(refreshIntervalId);
          refreshIntervalId = undefined;
          refreshIntervalCount = 0;
        }
      } else if (flagsPayload.columnName === "Delete") {
        setisDataNotinSyncLoader(false);
        setShowDeleteMessage(true);
        if (refreshIntervalId) {
          window.clearInterval(refreshIntervalId);
          refreshIntervalId = undefined;
          refreshIntervalCount = 0;
        }
      }
    }
  };

  // Checking Data in Sync flag status
  const checkDatainSync = async () => {
    try {
      refreshIntervalId = window.setInterval(checkFlags, 2000);
    } catch (error) {
      window.clearInterval(refreshIntervalId);
      refreshIntervalId = undefined;
      setisDataNotinSyncLoader(false);
    }
  };

  // Starting the data sync process by calling syncM3andM2data
  const startDataSync = async () => {
    setisDataNotinSyncLoader(true);
    let request = {
      engCode: engagement.engagementCode,
      moduleName: "M3",
    };
    // const data = await syncModulebyData({ request });
    // await uploadFileToBlob({
    //   file: trigger,
    //   moduleName: "m3",
    //   fileName: `TriggerSync_${engagement.engagementCode}_${
    //     user.username
    //   }_${formarDateTime(new Date(), "ddMMyyyy HH:mm:ss")}.txt`,
    // });

    request = {
      ModuleName: "m3",
      FileName: `TriggerSync_${engagement.engagementCode}_${
        user.username
      }_${formarDateTime(new Date(), "ddMMyyyy HH:mm:ss")}.txt`,
    };
    await uploadTextFile({ request });
    setSyncDataInProgress(true);
    // checkDatainSync();
  };

  async function onDeleteClick(data) {
    setSelectedProjectData(data);
    setDeleteModalVisible(true);
  }

  // on move project to decarb library
  async function onMoveProject(data) {
    setSelectedProjectData(data);
    setMoveLibraryModalVisible(true);
  }

  function moveNumberKeysToEnd(obj) {
    let keys = Object.keys(obj);
    let result = {};

    // First, add keys that are not numbers
    for (let key of keys) {
      if (isNaN(key)) {
        result[key] = obj[key];
      }
    }

    for (let key of keys) {
      if (!isNaN(key)) {
        result[`zvalueToReplace${key}`] = obj[key];
      }
    }

    return result;
  }

  async function onExportClick(data) {
    setIsExportM3DataInProgress(true);
    const request = {
      engagementCode: engagement.engagementCode,
      startYear: parseInt(engagement.mostRecentYear),
      projectName: data.projectName,
      module: "M3",
      scenarioName: "test",
    };

    try {
      const response = await exportToExcel({ request });

      let exportData = [];

      Object.keys(response).forEach((key) => {
        const data = response[key].map((item) => moveNumberKeysToEnd(item));
        exportData.push({ data: data, name: key });
      });

      const m3DataForExportBlob = await createExcelFile(exportData);

      const exportM3DataFileName = `EYDecarb_${
        engagement.engagementCode
      }_Module3_ExportedM3Data_${formarDateTime(
        new Date(),
        "ddMMyyyy HH:mm:ss"
      )}.xlsx`;
      saveAs(m3DataForExportBlob, exportM3DataFileName);

      setIsExportM3DataInProgress(false);
    } catch (error) {
      console.error(error);
      setIsExportM3DataInProgress(false);
    }
  }

  async function onDeleteProjectModalConfirm() {
    setDeleteModalVisible(false);
    data.netPresentValue =
      data.netPresentValue === null ? 0 : data.netPresentValue;
    data.deleteFlag = "Y";
    data.createdBy = user.username;
    data.updatedBy = user.username;
    data.totalAbatement =
      data.totalAbatement === null ? 0 : data.totalAbatement;
    data.marginalAbatementCost =
      data.marginalAbatementCost === null ? 0 : data.marginalAbatementCost;
    data.internalRateofReturn =
      data.internalRateofReturn === null ? 0 : data.internalRateofReturn;
    data.simplePayBackPeriod =
      data.simplePayBackPeriod === null ? 0 : data.simplePayBackPeriod;
    data.discountedPayBackPeriod =
      data.discountedPayBackPeriod === null ? 0 : data.discountedPayBackPeriod;
    const request = {
      data,
    };

    const response = await updateProject({ request });
    const table = await getAllProjects({
      request: { id: engagement.id },
    });
    setTableData(table.data);
  }
  // confirmation to move project to decarb library
  async function onMoveProjectModalConfirm() {
    setMoveLibraryModalVisible(false);
    const request = {
      EngagementCode: engagement.engagementCode,
      ProjectID: data.id,
    };

    const response = await AddProjecttoProjectLibrary({ request });
    const table = await getAllProjects({
      request: { id: engagement.id },
    });
    setTableData(table.data);
  }

  const onDeleteProjectModalClose = () => {
    setDeleteModalVisible(false);
  };
  // on pop up close
  const onMoveProjectModalClose = () => {
    setMoveLibraryModalVisible(false);
  };

  useEffect(() => {
    const request = {
      emailId: user.username,
    };

    CheckIfUserIsAdmin({ request }).then((data) => {
      setIsAdmin(data);
    });
  }, [user.username]);

  const stringFormatter = (params) => {
    return numberFormatter.format(params.data[params.colDef.field]);
  };
  return (
    <div className="analyse">
      <div className="analyse-header">
        <div className="analyse-header-h">
          <span className="analyse-header-title">{analyseBodyTitle}</span>
          <span className="analyse-header-description">
            {analyseBodyDescription}
          </span>
        </div>
        <div className="analyse-header-buttons">
          {showSyncButton && isDataNotinSync && (
            <Button
              onClick={startDataSync}
              className={"analyse-header-new-project"}
              variant="primary-alt"
              disabled={isDataNotinSyncLoader}
            >
              <RefreshIcon />
              {syncData}
            </Button>
          )}
          <Button
            onClick={addGMP}
            className={"analyse-header-new-project"}
            variant="primary-alt"
            disabled={isDataNotinSync}
          >
            <AddIcon />
            {addGMPBtn}
          </Button>
          {showCopyButton && (
            <Button
              onClick={copyPastVersions}
              className={"analyse-header-new-project"}
              variant="primary-alt"
            >
              <CopyIcon />
              {copyFromPastVersions}
            </Button>
          )}
          <Button
            onClick={CreateNewProject}
            className={"analyse-header-new-project"}
            variant="primary-alt"
            disabled={isDataNotinSync}
          >
            <AddIcon />
            {createNewProject}
          </Button>
        </div>
      </div>
      {isDataNotinSyncLoader && (
        <div>
          <LoadingIndicatorEmbed show={isDataNotinSyncLoader} />
          <span className="data-sync-message">{datainSyncMessage}</span>
        </div>
      )}
      {isDataNotinSync && showDataSyncMessage && (
        <div className="analyse-validation-message">
          <span>{analyseValidationMessage}</span>
        </div>
      )}
      {showDeleteMessage && (
        <div className="analyse-validation-message">
          <span>{analyseDeleteMessage}</span>
        </div>
      )}
      {showCopyMessage && (
        <div className="analyse-validation-message">
          <span>{copyProjectsMessage}</span>
        </div>
      )}
      <div className="analyse-table-section">
        <LoadingIndicatorEmbed
          show={analyseTableLoader || isExportM3DataInProgress}
        >
          <Table
            rowData={tableData?.filter(
              (element) => element.deleteFlag.toUpperCase() === "N"
            )}
            colDefs={[
              {
                field: "projectName",
                cellRenderer: CustomLinkRenderer,
                cellRendererParams: () => {
                  return {
                    isDataNotinSync: isDataNotinSync,
                  };
                },
                headerName: analyseTableHeaderProjectName,
                headerTooltip: analyseTableHeaderProjectName,
                suppressMovable: true,
                sortable: true,
                flex: 1,
                minWidth: 200,
                pinned: "left",
                suppressMovable: true,
              },
              {
                field: "projectTypeName",
                filter: true,
                headerName: analyseTableHeaderProjectType,
                headerTooltip: analyseTableHeaderProjectType,
                suppressMovable: true,
                flex: 1,
                minWidth: 150,
                suppressMovable: true,
              },
              {
                field: "simplePayBackPeriod",
                filter: true,
                headerName: analyseTableHeaderSimplePaybackPeriod,
                headerTooltip: analyseTableHeaderSimplePaybackPeriod,
                suppressMovable: true,
                flex: 1,
                minWidth: 210,
                suppressMovable: true,
                valueFormatter: stringFormatter,
                filterParams: {
                  valueFormatter: stringFormatter,
                },
              },
              {
                field: "totalAbatement",
                filter: true,
                headerName: analyseTableHeaderTotalAbatement,
                headerTooltip: analyseTableHeaderTotalAbatement,
                suppressMovable: true,
                flex: 1,
                minWidth: 210,
                suppressMovable: true,
                valueFormatter: stringFormatter,
                filterParams: {
                  valueFormatter: stringFormatter,
                },
              },
              {
                field: "averageAbatement",
                filter: true,
                headerName: analyseTableHeaderAverageAnnualAbatement,
                headerTooltip: analyseTableHeaderAverageAnnualAbatement,
                suppressMovable: true,
                flex: 1,
                minWidth: 230,
                suppressMovable: true,
                valueFormatter: stringFormatter,
                filterParams: {
                  valueFormatter: stringFormatter,
                },
              },
              {
                field: "technicalAbatementPotential",
                filter: true,
                headerName: analyseTableHeaderTechnicalAbatementPotential,
                headerTooltip: analyseTableHeaderTechnicalAbatementPotential,
                suppressMovable: true,
                flex: 1,
                minWidth: 190,
                suppressMovable: true,
                valueFormatter: stringFormatter,
                filterParams: {
                  valueFormatter: stringFormatter,
                },
              },
              {
                field: "netPresentValue",
                filter: true,
                headerName: analyseTableHeaderNetPresentValue,
                headerTooltip: analyseTableHeaderNetPresentValue,
                suppressMovable: true,
                flex: 1,
                minWidth: 200,
                suppressMovable: true,
                valueFormatter: stringFormatter,
                filterParams: {
                  valueFormatter: stringFormatter,
                },
              },
              {
                field: "internalRateofReturn",
                filter: true,
                headerName: analyseTableHeaderInternalRateOfReturn,
                headerTooltip: analyseTableHeaderInternalRateOfReturn,
                suppressMovable: true,
                flex: 1,
                minWidth: 200,
                suppressMovable: true,
                valueFormatter: stringFormatter,
                filterParams: {
                  valueFormatter: stringFormatter,
                },
              },
              {
                field: "discountedPayBackPeriod",
                filter: true,
                headerName: analyseTableHeaderDiscountedPaybackPeriod,
                headerTooltip: analyseTableHeaderDiscountedPaybackPeriod,
                suppressMovable: true,
                flex: 1,
                minWidth: 190,
                suppressMovable: true,
                valueFormatter: stringFormatter,
                filterParams: {
                  valueFormatter: stringFormatter,
                },
              },
              {
                field: "marginalAbatementCost",
                filter: true,
                headerName: analyseTableHeaderMarginalAbatementCost,
                headerTooltip: analyseTableHeaderMarginalAbatementCost,
                suppressMovable: true,
                flex: 1,
                minWidth: 190,
                suppressMovable: true,
                valueFormatter: stringFormatter,
                filterParams: {
                  valueFormatter: stringFormatter,
                },
              },
              {
                cellRenderer: CustomActionsRenderer,
                suppressMovable: true,
                cellRendererParams: () => {
                  return {
                    onDeleteClick: onDeleteClick,
                    onMoveProject: onMoveProject,
                    onExportClick: onExportClick,
                    isAdmin: isAdmin,
                    isDataNotinSync: isDataNotinSync,
                  };
                },
                field: "actions",
                headerName: "",
                flex: 1,
                minWidth: 220,
                suppressMovable: true,
              },
            ]}
            draggable={false}
            rowClassRules={{
              "analyse-table-pending-validation": (params) => {
                return params.data.isValidated === "N";
              },
              "analyse-table-data-validation-pending": () => {
                return isDataNotinSync === true;
              },
            }}
          />
        </LoadingIndicatorEmbed>
        <ConfirmationModal
          isModalVisible={isDeleteModalVisible}
          onClose={onDeleteProjectModalClose}
          onConfirm={onDeleteProjectModalConfirm}
          modalHeaderText={deleteProjectModalHeader}
          modalBodyText={deleteProjectModalBody}
        />
        {/* <AnalyseLockModal /> */}
        <ConfirmationModal
          isModalVisible={isMoveLibraryModalVisible}
          onClose={onMoveProjectModalClose}
          onConfirm={onMoveProjectModalConfirm}
          modalHeaderText={""}
          modalBodyText={projectToLibraryBody}
          modalDescriptionText={""}
        />
      </div>
    </div>
  );
}
