import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { PublicClientApplication } from "@azure/msal-browser";

const useRegRedirectionAuthTokenAPAC = () => {
  const { accounts, inProgress } = useMsal();
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    const initializeMsal = async () => {
      if (process.env.REACT_APP_MULTI_REGION_ALLOW === 'true') {
        const authConfig = {
          clientId: process.env.REACT_APP_AZURE_CLIENT_ID_APAC,
          authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_APPLICATION_ID}`,
          redirectUri: `${process.env.REACT_APP_URL_BASE}home`,
          navigateToLoginRequestUrl: true,
          applicationId: process.env.REACT_APP_AZURE_APPLICATION_ID,
        };

        const cache = {
          cacheLocation: "sessionStorage",
          storeAuthStateInCookie: true,
        };
        const msalConfig = {
          auth: authConfig,
          cache: cache,
        };

        const msalInstance = new PublicClientApplication(msalConfig);

        try {
          // Ensure MSAL instance is initialized
          await msalInstance.initialize();

          if (inProgress === "none" && accounts.length > 0) {
            const request = {
              scopes: [process.env.REACT_APP_APAC_TOKEN_SCOPE],
              account: accounts[0],
            };

            const response = await msalInstance.acquireTokenSilent(request);
            setAccessToken(response.accessToken);
          }
        } catch (error) {
          console.error("Token acquisition failed:", error);
        }
      }
    };

    initializeMsal();
  }, [inProgress, accounts]);

  return accessToken;
};

export { useRegRedirectionAuthTokenAPAC };
