import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Variwide from "highcharts/modules/variwide";
import "./SimulateAbatementGraph.scss";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import { LoadingIndicatorEmbed } from "../../../../../LoadingIndicator/LoadingIndicatorEmbed";

// Initialize the modules
Variwide(Highcharts);
NoDataToDisplay(Highcharts);

export function SimulateAbatementGraph({ scenarioMarginalCostData, loader }) {
  // const VariwideChart = () => {
  const [chartOptions, setChartOptions] = useState({});
  useEffect(() => {
    if (scenarioMarginalCostData) {
      updateChartDataSet(scenarioMarginalCostData);
    }
  }, [scenarioMarginalCostData]);

  const updateChartDataSet = (data) => {
    let chartData = data;
    // console.log(chartData);
    // let chartDataNew = chartData && chartData.map(item => ({
    //   name: item[0], // Ensure each data point has a name
    //   y: item[1],
    //   z: item[2]
    // }));
    setChartOptions({
      chart: {
        type: "variwide",
      },
      title: {
        text: "",
      },
      xAxis: {
        type: "category",
        title: {
          text: "Average annual abatement(tCO\u{2082}e)",
        },
        // categories: chartDataNew.map(item => item.name), // Set categories
        labels: {
          useHTML: true,
          rotation: -45,
          style: {
            color: "#afaeba",
          },
          step: 1,
          autoRotation: [-45, -90],
        },
      },
      yAxis: {
        title: {
          text: "Cost of abatement($/tCO\u{2082}e)",
          style: {
            color: "#afaeba",
          },
        },
        labels: {
          style: {
            color: "#afaeba",
          },
        },
      },
      plotOptions: {
        series: {
          borderRadius: 0,
          borderWidth: 0,
          colorByPoint: true,
        },
      },
      colors: [
        "#85B9FD",
        "#55A6FC",
        "#188CE5",
        "#1275C1",
        "#0F69AE",
        "#0A558E",
        "#064372",
        "#083153",
      ],
      legend: {
        enabled: false,
      },
      series: [
        {
          data: chartData,
          dataLabels: {
            enabled: true,
            format: "{point.y:.1f}",
          },
          tooltip: {
            pointFormat:
              "Average annual abatement: <b>{point.z} tCO2e</b><br/>Marginal abatement cost: <b>{point.y} $</b>",
          },
        },
      ],
      lang: {
        noData: "No data to display",
      },
      noData: {
        style: {
          fontWeight: "100",
          fontSize: "15px",
          color: "#DEDEE2",
        },
        position: {
          align: "center",
          verticalAlign: "middle",
        },
        text: "No data available",
      },
    });
  };

  return (
    <div className="emissions-graph-section">
      <div className="emissions-graph-title"></div>
      <div className="emissions-graph-content">
        <div className="emissions-mac-curve-content">
          <LoadingIndicatorEmbed show={loader} data-testid="loading-indicator">
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </LoadingIndicatorEmbed>
        </div>
      </div>
    </div>
  );
}
