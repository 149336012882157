import { useEffect, useState } from "react";
import "./CreateNewProject.scss";
import { Button, Emissions, ProjectInformation, Stepper } from "..";
import { useNavigate, useLocation } from "react-router-dom";
import { routes } from "../../routes/routes.constatns";
import { Financials } from "../Financials/Financials";
import { ProjectResults } from "../ProjectResults/ProjectResults";
import { useDispatch, useSelector } from "react-redux";
import { analyseProjectsData } from "../../util/constants";
import {
  dropProjectState,
  nextTab,
  previousTab,
  setProjectId,
  setIsNextTabButtonDisabled,
  setTabIndex,
  setCurrentTab,
  setIsEditProject,
  setProjectName,
  setProjectType,
  setProjectDescrption,
  setProjectTags,
  getLockingData,
  setIsValidated,
} from "../../store/slices/projectSlice";
import {
  createProjectDraft,
  createM3Project,
  saveProjectTags,
  getDistinctProjectsNames,
  updateProjectStep,
  getColumnValueFlags,
  SaveUserModuleLock,
  getProjectDetailsById,
  SetProjectStatustoFinalize,
} from "../../store/services/project.service";
import { TabNavigation } from "./components/TabNavigation/TabNavigation";
import { LoadingIndicatorEmbed } from "../LoadingIndicator/LoadingIndicatorEmbed";
import { ConfirmationM1M2 } from "../LockerModal";
import { ConfirmationModal } from "../CommonUIComponents/ConfirmationModal/ConfimationModal";
import { setDefaultLoader } from "../../store/slices/engagementSlice";

export function CreateNewProject() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const steps = useSelector((state) => state.stepper.steps);
  const engagement = useSelector((state) => state.engagement.engagement);
  const selectedRegion = useSelector(
    (state) => state.engagement.selectedRegion
  );
  const stepIndex = useSelector((state) => state.engagement.stepIndex);
  const currentStep = useSelector((state) => state.engagement.currentStep);
  const currentTab = useSelector((state) => state.project.currentTab);
  const tabIndex = useSelector((state) => state.project.tabIndex);
  const project = useSelector((state) => state.project.project);
  const user = useSelector((state) => state.user.user);
  const isEditProject = useSelector((state) => state.project.isEditProject);
  const [isDataSaveInProgress, setIsDataSaveInProgress] = useState(false);
  const [isfinalModalOpen, setIsFinalModalOpen] = useState(false);
  const isNextTabButtonDisabled = useSelector(
    (state) => state.project.isNextTabButtonDisabled
  );
  let refreshIntervalId = undefined;
  let refreshIntervalCount = 0;
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const lockingData = useSelector((state) => state.project?.lockingData);
  const [lockedData, setLock] = useState();
  const [lockedBy, setLockedby] = useState();
  const [lockType, setlockType] = useState();
  const defaultLoader = useSelector((state) => state.engagement.defaultLoader);
  let heading =
    project.projectType === 1
      ? "reduce"
      : project.projectType === 2
      ? "replace"
      : "compensate";

  useEffect(() => {
    if (state?.projectStatusId) {
      let index = state?.projectStatusId >= 4 ? 3 : state?.projectStatusId;
      dispatch(setTabIndex({ index: index }));
      dispatch(setCurrentTab({ currentTab: index }));
      state?.isValidated === "N" &&
        dispatch(setIsValidated({ isValidated: false }));
    }
  }, [state?.projectStatusId]);

  useEffect(() => {
    if (state?.projectId) {
      getProjectDetails(state?.projectId);
    }
  }, [state?.projectId]);

  // to get a locked user flags
  useEffect(() => {
    if (engagement?.id) {
      const request = {
        LockedBy: user.username,
        ModuleName: "Engagement",
        EngagementID: engagement.id,
      };

      dispatch(getLockingData(request)).then((action) => {
        if (action) {
          const lockedData = action.payload.data.lockStatus;
          const lockedBy = action.payload.data.lockedBy;
          const lockType = action.payload.data.lockType;
          setLock(lockedData);
          setLockedby(lockedBy);
          setlockType(lockType);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  async function getProjectDetails(projectId) {
    const request = { projectID: projectId };
    const data = await getProjectDetailsById({ request });
    dispatch(setProjectName({ name: data.data.projectName }));
    dispatch(setProjectType({ type: data.data.projectTypeID }));
    dispatch(setProjectDescrption({ descrp: data.data.projectDescription }));
    let tagArray = [];
    data.data.tags &&
      data.data.tags.map((item) => tagArray.push({ label: item, value: item }));
    dispatch(setProjectTags({ tags: tagArray }));
    dispatch(setProjectId({ id: projectId }));
    dispatch(setIsEditProject({ isEdit: true }));
  }

  const tabSteps = [
    {
      stepName: "Step 1: Project information",
      stepHeading: "Create project",
      stepComponent: (
        <ProjectInformation onTabStepCompleted={onTabStepCompleted} />
      ),
    },
    {
      stepName: "Step 2: Emissions",
      stepHeading: "Edit project `${} (replace)",
      stepComponent: <Emissions onTabStepCompleted={onTabStepCompleted} />,
    },
    {
      stepName: "Step 3: Financials",
      stepHeading: "Edit project electrification (replace)",
      stepComponent: <Financials onTabStepCompleted={onTabStepCompleted} />,
    },
    {
      stepName: "Step 4: Results",
      stepHeading: "Edit project electrification (replace)",
      stepComponent: <ProjectResults />,
    },
  ];

  function handleCancelClick() {
    dispatch(dropProjectState());
    navigate(`/${routes.createNewEngagement}`, {
      state: {
        engagementId: engagement.id,
        regionId: selectedRegion?.id,
      },
      gestureEnabled: false,
    });
  }

  async function onSaveAndNext() {
    let projectId = state?.projectId;
    setIsDataSaveInProgress(true);
    try {
      if (currentTab === 0) {
        if (isEditProject) {
          projectId = project.projectId;
          let tagArray = [];
          project.projectTags.length > 0 &&
            project.projectTags.forEach((item) => {
              tagArray.push({ tagName: item.value });
            });
          const payload = {
            projectID: projectId,
            createdBy: user.username,
            projectTags: tagArray,
            projectDescription: project.projectDescrption,
            projectName: project.projectName,
          };
          await saveProjectTags({ payload });
        } else {
          const isDuplicateProject = await handlecheckIfProjectNameExist();
          if (!isDuplicateProject) {
            const request = {
              engagementId: engagement.id,
              projectTypeId: parseInt(project.projectType),
              projectStatus: 1,
              projectDescription: project.projectDescrption
                ? project.projectDescrption
                : "",
              createdBy: user.username,
            };
            const response = await createProjectDraft({ request });
            dispatch(setProjectId({ id: response.data.projectId }));
            const dataSet = {
              engagementCode: engagement.engagementCode,
              projectID: response.data.projectId,
              createdBy: user.username,
            };
            await createM3Project({ dataSet });
            let tagArray = [];
            project.projectTags.length > 0 &&
              project.projectTags.forEach((item) => {
                tagArray.push({ tagName: item.value });
              });
            const payload = {
              projectID: response.data.projectId,
              createdBy: user.username,
              projectTags: tagArray,
              projectDescription: project.projectDescrption,
              projectName: project.projectName,
            };
            projectId = response.data.projectId;
            await saveProjectTags({ payload });
            const flagsPayload = {
              columnName: "Create",
              tableName: "Project",
              id: response.data.projectId,
            };
            const lockRequest = {
              moduleName: "Project",
              lockedBy: user.username,
              id: 0,
              lockGuid: response.data.projectId,
            };
            SaveUserModuleLock(lockRequest);
            try {
              refreshIntervalId = window.setInterval(
                checkFlags(flagsPayload),
                2000
              );
            } catch (error) {
              if (refreshIntervalId) {
                window.clearInterval(refreshIntervalId);
                refreshIntervalId = undefined;
              }
              setIsDataSaveInProgress(false);
            }
          }
        }
      }

      if (currentTab === tabIndex && currentTab !== 3) {
        project.projectStatus !== 5 &&
          (await updateProjectStep({
            request: {
              projectId: projectId ?? project.projectId,
              stepId: tabIndex + 1,
            },
          }));
        dispatch(setTabIndex({ index: tabIndex + 1 }));
      }
      if (currentTab === 2) {
        project.projectStatus !== 5 &&
          (await updateProjectStep({
            request: {
              projectId: projectId ?? project.projectId,
              stepId: tabIndex + 1,
            },
          }));
      }
      if (currentTab === 3) {
        setIsFinalModalOpen(true);
      } else {
        dispatch(setIsNextTabButtonDisabled({ isDisabled: true }));
        dispatch(nextTab());
      }

      setIsDataSaveInProgress(false);
    } catch (error) {
      setIsDataSaveInProgress(false);
    }
  }

  const checkFlags = async (flagsPayload) => {
    refreshIntervalCount++;
    if (refreshIntervalCount > 30) {
      window.clearInterval(refreshIntervalId);
      refreshIntervalId = undefined;
      return;
    }
    const response = await getColumnValueFlags({ flagsPayload });
    if (response.columnValue === "3") {
      if (refreshIntervalId) {
        window.clearInterval(refreshIntervalId);
        refreshIntervalId = undefined;
      }
      setIsDataSaveInProgress(false);
    }
  };
  const handlecheckIfProjectNameExist = async (event) => {
    if (!project.projectName) return;

    const data = await getDistinctProjectsNames({
      request: { engagementId: engagement.id },
    });
    const dataArray = data.map((v) => v.toLowerCase());
    const duplicateProjectName = dataArray.includes(
      project.projectName.toLowerCase()
    )
      ? true
      : false;

    if (duplicateProjectName) {
      dispatch(setIsNextTabButtonDisabled({ isDisabled: true }));
    }

    return duplicateProjectName;
  };

  function onPrevious() {
    if (currentTab === 1) {
      dispatch(setIsEditProject({ isEdit: true }));
    }
    dispatch(previousTab());
  }

  function onTabStepCompleted() {
    // dispatch(setIsNextTabButtonDisabled());
  }

  useEffect(() => {
    if (
      project.projectId &&
      lockedData === 1 &&
      lockType === "Engagement" &&
      lockedBy !== user.username
    ) {
      setIsConfirmationModalOpen(true);
    }
  }, [lockedBy, lockedData]);

  const finalise = async () => {
    const request = {
      projectId: project.projectId,
    };
    let data = await SetProjectStatustoFinalize({ request });
    handleCancelClick();
  };

  return (
    <div className="new-project">
      <div className="new-project-header">
        <div className="new-project-header-title">
          {steps[currentStep]?.stepHeading}
        </div>
        <div className="new-project-header-stepper">
          <Stepper
            steps={steps}
            currentStep={currentStep}
            stepIndex={stepIndex}
          />
        </div>
      </div>

      <div className="new-project-content">
        <div className="new-project-content-header">
          <div className="new-project-content-header-main">
            <span className="new-project-content-header-main-analyse">
              {analyseProjectsData}
            </span>
            <span>{">"}</span>
            <span>
              {currentTab === 0
                ? "Create project"
                : "Edit project " + project.projectName + " (" + heading + ")"}
            </span>
          </div>
          <span className="new-project-content-header-desc"></span>
        </div>
        <div className="new-project-content-body">
          <TabNavigation
            tabSteps={tabSteps}
            currentTab={currentTab}
            tabIndex={tabIndex}
          />
          {tabSteps[currentTab]?.stepComponent}
          <div className="new-project-footer">
            <Button variant="secondary" onClick={handleCancelClick}>
              {/* {"Cancel"} */}
              {"Close"}
            </Button>
            <div className="new-engagement-footer-nav-buttons">
              {currentTab !== 0 ? (
                <Button onClick={onPrevious} variant="secondary">
                  {"Previous"}
                </Button>
              ) : (
                <></>
              )}

              {currentTab !== 3 ? (
                <LoadingIndicatorEmbed show={isDataSaveInProgress}>
                  <Button
                    onClick={onSaveAndNext}
                    variant="primary"
                    disabled={
                      // currentTab === tabSteps.length ||
                      isNextTabButtonDisabled ||
                      isDataSaveInProgress ||
                      (state?.projectStatusId === 5 && currentTab === 3) ||
                      (currentTab === 3 &&
                        lockingData?.data?.lockStatus === 1 &&
                        lockingData?.data?.lockedBy !== user.username)
                    }
                  >
                    {/* {currentTab === tabSteps.length - 1 ? "Save" : "Next"} */}
                    {/* {currentTab === 3 ? "Finalise" : "Continue"} */}
                    {"Next"}
                  </Button>
                </LoadingIndicatorEmbed>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      {/* {isConfirmationModalOpen && (
        <ConfirmationM1M2
          isOpen={isConfirmationModalOpen}
          onClose={() => setIsConfirmationModalOpen(false)}
        />
      )} */}

      <ConfirmationModal
        isModalVisible={isfinalModalOpen}
        onClose={() => setIsFinalModalOpen(false)}
        onConfirm={() => finalise()}
        modalHeaderText={"Finalise project"}
        modalBodyText={
          "Upon confirmation, the finalized project will appear in Module 4 for project creation."
        }
      />
    </div>
  );
}
