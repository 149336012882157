import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "../../Table/Table";
import CustomActionsRenderer from "./customActionRenderer";
import CustomInputRenderer from "./CustomInputRenderer";
import { Button } from "../../Button/Button";
import { ConfirmationModal } from "../../CommonUIComponents/ConfirmationModal/ConfimationModal";
import {
  deleteGMPModalHeader,
  deleteGMPModalBody,
  deleteGMPModalBodyDesc,
  addGmpTitleTooltip,
} from "../../../util/constants";
import { suppressEnter, suppressNavigation } from "../../../util/tabUtil";
import {
  getGMPData,
  deleteGMPDetail,
  updateGMPDataManully,
} from "../../../store/services/gmp.service";
import {
  setGMPDetails,
  setSelectedGMPRowData,
  setSelectedGMPRowIndex,
} from "../../../store/slices/gmpSlice";
import { LoadingIndicatorEmbed } from "../../LoadingIndicator/LoadingIndicatorEmbed";
import { Tooltip } from "../../CommonUIComponents/Tooltip/Tooltip";
import { EmissionIcon } from "../../../icons";
let gmpYearData = [];
export function GMPTable({ loader }) {
  const dispatch = useDispatch();
  const gmp = useSelector((state) => state.gmp.gmp);
  const engagement = useSelector((state) => state.engagement.engagement);
  const user = useSelector((state) => state.user.user);
  const [rowData, setRowData] = useState([]);
  const [enableEdit, setEnableEdit] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedGmpRowData, setGMPRowData] = useState([]);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [disableGMPEditSave, setDisableGMPEditSave] = useState(true);
  const [saveLoader, setSaveLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  // Check GMP data to create Dynamic rows and columns
  useEffect(() => {
    if (gmp.gmpDataSet) {
      createTableColumns(gmp.gmpDataSet.tableHeaderData, 100);
      setRowData(gmp.gmpDataSet.tableData);
    }
  }, [gmp.gmpDataSet]);

  // Refresh table view on click of edit button
  useEffect(() => {
    if (enableEdit) {
      createTableColumns(gmp.gmpDataSet.tableHeaderData, 100);
    }
  }, [enableEdit]);

  // Onclick of edit icon
  async function onEditClick(event) {
    setEnableEdit(true);
    setDisableGMPEditSave(false);
    setGMPRowData(event.data);
    dispatch(setSelectedGMPRowData({ rowData: event.data }));
    dispatch(setSelectedGMPRowIndex({ rowDataIndex: event.rowIndex }));
  }
  //Onclick of save icon
  async function onSaveClick(data) {
    setDisableGMPEditSave(true);
    setSaveLoader(true);
    let unique = [...new Set(gmpYearData.map((x) => x.activityYear))];
    let array = [];
    unique.forEach((year) => {
      let x = false;
      for (let i = gmpYearData.length - 1; i >= 0; i--) {
        if (gmpYearData[i]["activityYear"] === year && !x) {
          array.push(gmpYearData[i]);
          x = true;
        }
      }
    });
    const payload = {
      engagementCode: engagement.engagementCode,
      parameterID: selectedGmpRowData.ParameterID,
      modifiedBy: user.username,
      viewType: "EditRule",
      // gmpManualEditYears: gmp.gmpEditData
      gmpManualEditYears: array,
    };
    const response = await updateGMPDataManully({ payload });
    dispatch(setGMPDetails({ gmpData: response }));
    gmpYearData = [];
    setEnableEdit(false);
    setDisableGMPEditSave(true);
    refreshGMPTable();
    dispatch(setSelectedGMPRowIndex({ rowDataIndex: null }));
    setSaveLoader(false);
  }

  //Onclick of Reset button
  async function onResetClick(data) {
    setEnableEdit(false);
    setDisableGMPEditSave(true);
    refreshGMPTable();
    dispatch(setSelectedGMPRowIndex({ rowDataIndex: null }));
  }
  // Onclick of delete icon
  async function onDeleteClick(data) {
    setGMPRowData(data);
    setDeleteModalVisible(true);
  }

  // On confirmation of deleteion
  async function onDeleteProjectModalConfirm() {
    setDeleteModalVisible(false);
    setDeleteLoader(true);
    const request = {
      engagementCode: engagement.engagementCode,
      modifiedBy: user.username,
      ParameterID: selectedGmpRowData?.ParameterID,
    };
    const response = await deleteGMPDetail({ request });
    setEnableEdit(false);
    dispatch(setSelectedGMPRowIndex({ rowDataIndex: null }));
    refreshGMPTable();
  }

  // Get GMP data to refresh table
  const refreshGMPTable = async () => {
    const request = {
      engagement: engagement.engagementCode,
      viewType: "Default",
    };
    const data = await getGMPData({ request });
    dispatch(setGMPDetails({ gmpData: data }));
    setDeleteLoader(false);
  };

  const onDeleteProjectModalClose = () => {
    setDeleteModalVisible(false);
  };

  // On input value change for gmp edit functionality
  const onValueChange = (field, value) => {
    gmpYearData.push({
      activityYear: parseInt(field),
      yearValue: parseFloat(value),
    });
  };


  // render collapsed table column
  const renderCollapsedColumn = (index, data) => {
    let dataArr = []
    data.map((item, i) => {
    if ( i === index) {
      dataArr.push({columnGroupShow: 'closed', field: item.field, suppressMovable: true})
      dataArr.push({columnGroupShow: 'open', field: item.field, suppressMovable: true})
    }
    else if ( i > index && i < (index === 2 || index === 9 ? index + 4 : index +3))
      dataArr.push({columnGroupShow: 'open', field: item.field, suppressMovable: true})
    });
    return dataArr;
  };

  // render collpased column name
  const showCollapsedHeaderName = (index) => {
    let headerName = "";
    switch(index) {
      case 2:
        headerName = "Emission";
        break;
      case 6:
        headerName = "Geographical";
        break;
      case 9:
        headerName = "Organisational";
          break;
      case 13:
        headerName = "Custom";
          break;
      default:
    }
    return headerName;
  }

  // Create Dynamic Columns for Reduce Table
  const createTableColumns = (data, length) => {
    if (data.length > 0) {
      let colDefs = [];
      colDefs.push({
        field: "actions",
        width: 100,
        headerName: "",
        suppressMovable: true,
        pinned: "left",
        cellRenderer: CustomActionsRenderer,
        suppressMovable: true,
        cellRendererParams: () => {
          return {
            onDeleteClick: onDeleteClick,
            onSaveClick: onSaveClick,
            onEditClick: onEditClick,
          };
        },
      });
      // let columnNames = Object.keys(data[0]);

      data.map((item, i) => {
        if (i < length) {
          if (enableEdit) {
            if (i > 26) {
              colDefs.push({
                field: item.field,
                width: 200,
                headerName: item.headerName,
                headerTooltip: item.headerName,
                suppressMovable: true,
                suppressNavigable: true,
                pinned: i < 2 ? "left" : "",
                cellRenderer: CustomInputRenderer,
                suppressCellFocus: true,
                suppressKeyboardEvent: (params) => {
                  return suppressEnter(params) || suppressNavigation(params);
                },
                cellRendererParams: () => {
                  return {
                    field: item.field,
                    onValueChange: onValueChange,
                  };
                },
                valueFormatter: (value) => {
                  if (value == null || value.value == null) {
                    return "";
                  }
                  return value.value.toLocaleString();
                },
              });
            } else {
              if ( i === 2 || i === 6 || i === 9 || i === 13){
                colDefs.push({
                  field: item.field,
                  width: 150,
                  headerName: showCollapsedHeaderName(i),
                  children: renderCollapsedColumn(i, data),
                  marryChildren: true,
                  headerTooltip: showCollapsedHeaderName(i),
                  suppressMovable: true,
                  pinned: i < 2 ? "left" : "",
                });
              }
              if( i < 2 || i > 15)
              {
              colDefs.push({
                field: item.field,
                width: 150,
                headerName: item.headerName,
                headerTooltip: item.headerName,
                suppressMovable: true,
                pinned: i < 2 ? "left" : "",
              });
            }
            }
          } else {
            if ( i === 2 || i === 6 || i === 9 || i === 13){
              colDefs.push({
                field: item.field,
                width: 150,
                headerName: showCollapsedHeaderName(i),
                children: renderCollapsedColumn(i, data),
                marryChildren: false,
                headerTooltip: showCollapsedHeaderName(i),
                suppressMovable: true,
                pinned: i < 2 ? "left" : "",
                valueFormatter: (value) => {
                  if (value == null || value.value == null) {
                    return "";
                  }
                  return value.colDef.field === "Start_Year_Value" ? Number(value.value).toLocaleString() : value.value.toLocaleString();
                },
              });
            }
          if( i < 2 || i > 15)
           {
            colDefs.push({
              field: item.field,
              width: 150,
              headerName: item.headerName,
              headerTooltip: item.headerName,
              suppressMovable: true,
              pinned: i < 2 ? "left" : "",
              valueFormatter: (value) => {
                if (value == null || value.value == null) {
                  return "";
                }
                return value.colDef.field === "Start_Year_Value" ? Number(value.value).toLocaleString() : value.value.toLocaleString();
              },
            });
          }
          }
        }
      });
      setColumnDefs(colDefs);
    }
  };
  return (
    <div className="add-gmp-table">
      <div className="add-gmp-table-filter-section">
        <div className="add-gmp-table-filter-section-custom">
          <div className="add-gmp-params-title">
            <span>Created financial parameters</span>
            <Tooltip
              trigger={<EmissionIcon />}
              variant="ghost"
              tooltipText={addGmpTitleTooltip}
            ></Tooltip>
          </div>
        </div>
      </div>
      <div className="add-gmp-table-table-section">
        <LoadingIndicatorEmbed
          show={deleteLoader}
          className={"decarb-loading-indicator-transparent"}
        >
          <Table
            colDefs={columnDefs}
            rowData={rowData}
            // selectionChanged={onSelectionChanged}
            rowSelect={"single"}
            draggable={false}
            loading={showLoader}
            rowClassRules={{
              "gmp-table-pending-validation": (params) => {
                return params?.data?.IsValidated === "N";
              },
            }}
          />
        </LoadingIndicatorEmbed>
        <ConfirmationModal
          isModalVisible={isDeleteModalVisible}
          onClose={onDeleteProjectModalClose}
          onConfirm={onDeleteProjectModalConfirm}
          modalHeaderText={deleteGMPModalHeader}
          modalBodyText={deleteGMPModalBody}
        />
      </div>
      <div>
        <LoadingIndicatorEmbed show={saveLoader}>
          <Button
            variant="primary-alt"
            className="add-gmp-params-Edit-save"
            onClick={() => onSaveClick()}
            disabled={disableGMPEditSave}
          >
            Save
          </Button>
        </LoadingIndicatorEmbed>
        <Button
          variant="primary-alt"
          className="add-gmp-params-edit-reset"
          onClick={() => onResetClick()}
          disabled={disableGMPEditSave}
        >
          Reset
        </Button>
      </div>
    </div>
  );
}
