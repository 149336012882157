import React, { useEffect, useState } from "react";
import { MotifCheckbox } from "@ey-xd/motif-react";
import { EmissionModal } from "./AddEmissionModal";
import { useLocation, useNavigate } from "react-router-dom";
import "./AddTargetSpecification.scss";
import { Stepper } from "../../../Stepper/Stepper";
import { useSelector } from "react-redux";
import { IntensityTargetTable } from "./IntensityTargetTable";
import { TargetIntensityValidationModal } from "./TargetIntensityValidationModal";
import { Tooltip } from "../../../CommonUIComponents/Tooltip/Tooltip";
import {
  CancelBtn,
  SaveBtn,
  addTargetSpecificationAddCustomBtn,
  activityOutputText,
  activityOutput,
  addTargetSpecificationAddEmissionBtn,
  addTargetSpecificationAddGeoBtn,
  addTargetSpecificationAddOrgBtn,
  addTargetSpecificationBaseYear,
  addTargetSpecificationBaseYearGreaterThanFEY,
  addTargetSpecificationBaseYearLessThanMRY,
  addTargetSpecificationBaseYearRequired,
  addTargetSpecificationCustom,
  addTargetSpecificationEmission,
  addTargetSpecificationEmissionRequired,
  addTargetSpecificationForecastNameRequired,
  addTargetSpecificationGeo,
  addTargetSpecificationGeoRequired,
  addTargetSpecificationName,
  addTargetSpecificationOrg,
  addTargetSpecificationOrgRequired,
  addTargetSpecificationPercentValueAnnual,
  addTargetSpecificationPercentValueTargetYear,
  addTargetSpecificationTargetDesc,
  addTargetSpecificationTargetName,
  addTargetSpecificationTargetTemporalType,
  addTargetSpecificationTargetType,
  addTargetSpecificationTargetTypeOptions,
  addTargetSpecificationTargetTypeRequired,
  addTargetSpecificationTargetYear,
  addTargetSpecificationTargetYearGreaterThanMRY,
  addTargetSpecificationTargetYearLessThanFEY,
  addTargetSpecificationTargetYearRequired,
  addTargetSpecificationTemporalTypeOptions,
  addTargetSpecificationTemporalTypeRequired,
  targetPercentageError,
  addTargetSpecificationPercentValueAnnualRequired,
  addTargetSpecificationPercentValueTargetYearRequired,
  targetDescriptiontooltip,
  targetTypetooltip,
  temporalTypeTooltip,
  targetPercentageTooltip,
  targetBaseYearTooltip,
  targetYearTooltip,
  targetEmissionTooltip,
  targetGeographytooltip,
  targetOrganisationTooltip,
  targetNameTooltip,
  targetCustomTooltip,
  intesityTooltip,
} from "../../../../util/constants";
import { Input } from "../../../Input/Input";
import { TextArea } from "../../../TextArea/TextArea";
import { Button } from "../../../Button/Button";
import { OrganizationModal } from "./AddOrganizationSpecsModal";
import { GeographicalModal } from "./AddGeoSpecModal";
import { CustomModal } from "./AddCustomModal";
import { AddIcon, EmissionIcon } from "../../../../icons";
import { routes } from "../../../../routes/routes.constatns";
import { Select } from "../../../Select/Select";
import { getHierarchyLevelData } from "../../../../store/services/engagement.service";
import {
  getForecastTargetByGroupId,
  saveTargetSpecification,
  updateTargetSpecification,
  allSaveTargetSpecification,
  checkName,
  allUpdateTarget,
} from "../../../../store/services/forecast.service";
import { MotifErrorMessage } from "@ey-xd/motif-react";
import { LoadingIndicatorEmbed } from "../../../LoadingIndicator/LoadingIndicatorEmbed";

export function AddTargetSpecification() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const steps = useSelector((state) => state.stepper.steps);
  const engagement = useSelector((state) => state.engagement.engagement);
  const stepIndex = useSelector((state) => state.engagement.stepIndex);
  const currentStep = useSelector((state) => state.engagement.currentStep);
  const user = useSelector((state) => state.user.user);
  const [isOpen, showModal] = useState(false);
  const [isOrgModalOpen, showOrgModal] = useState(false);
  const [isGeoModalOpen, showGeoModal] = useState(false);
  const [isCustomModalOpen, showCustomModal] = useState(false);
  const [isDataSaveInProgress, setIsDataSaveInProgress] = useState(false);

  const [targetSpecName, setTargetSpecName] = useState("");
  const [targetDescription, setTargetDescription] = useState("");
  const [targetSpecType, setTargetSpecType] = useState("");
  const [targetSpecTypeDisable, setTargetSpecTypeDisable] = useState(false);
  const [targetSpecTemporalType, setTargetSpecTemporalType] = useState("");
  const [percentageValue, setPercentage] = useState();
  const [baseSpecYear, setBaseSpecYear] = useState();
  const [targetSpecYear, setTargetSpecYear] = useState();

  const [targetErrorName, setTargetErrorName] = useState("");
  const [targetErrorType, setTargetErrorType] = useState("");
  const [targetErrorTemporal, setTargetErrorTemporal] = useState("");
  const [targetErrorPercent, setTargetErrorPercent] = useState("");
  const [targetErrorBaseYear, setTargetErrorBaseYear] = useState("");
  const [targetErrorTargetYear, setTargetErrorTargetYear] = useState("");

  const [emissionModalData, setEmissionModalData] = useState([]);
  const [geoSpecModalData, setGeoSpecModalData] = useState([]);
  const [orgSpecModalData, setOrgSpecModalData] = useState([]);
  const [customModalData, setCustomModalData] = useState([]);

  const [targetErrorEmissionModal, setTargetErrorEmissionModel] = useState("");
  const [targetErrorGeoModal, setTargetErrorGeoModal] = useState("");
  const [targetErrorOrganizationalData, setTargetErrorOrganizationalData] =
    useState("");

  const [targetEmissionData, setTargetEmissionData] = useState({});
  const [targetOrganizationalData, setTargetOrganizationalData] = useState({});
  const [targetGeoData, setTargetGeoData] = useState({});
  const [targetCustomData, setTargetCustomData] = useState({});
  const [scopeArray, setScopeArray] = useState([]);
  const [hierarchialData, setHierarchyData] = useState([]);
  const [targetData, setTargetData] = useState({});
  const [selectedemissionFilters, setSelectedEmissionFilters] = useState(null);
  const [selectedGeoFilters, setSelectedGeoFilters] = useState(null);
  const [selectedorgFilters, setSelectedOrgFilters] = useState(null);
  const [selectedCustomFilters, setSelectedCustomFilters] = useState(null);
  const [dropdownError, setDropDownError] = useState(false);
  const [alternativeOutput, showAlternativeOutput] = useState(false);
  const [showActivityOutputTable, showActivityOutputTableFun] = useState(false);
  const [isOutputCheckboxDisabled, disableOutputCheckbox] = useState(true);
  const [intenstiyTargetDataSet, setIntensityTargetData] = useState({});
  const [targetDataType, setTargetIntensityDataType] = useState("");
  const [targetOutput, setTargetIntensityOutputUnit] = useState("");
  const [targetyearData, setTargetIntensityYearData] = useState({});
  const [showTargetValidationMsg, setShowTargetValidationMsg] = useState(false);
  const [isEditTarget, setIsEditTarget] = useState(false);
  const decimalNumberRegExp = /^\d{1,3}(\.\d{1,2})?$/;

  useEffect(() => {
    if (
      targetSpecType === "Intensity" &&
      baseSpecYear &&
      targetSpecYear &&
      !isEditTarget &&
      !targetErrorBaseYear &&
      !targetErrorTargetYear
    ) {
      disableOutputCheckbox(false);
    } else if (
      isEditTarget &&
      targetSpecType === "Intensity" &&
      (targetDataType !== "" ||
        targetOutput !== "" ||
        targetyearData.length > 0)
    ) {
      // disableOutputCheckbox(true)
      showAlternativeOutput(true);
      showActivityOutputTableFun(true);
    } else {
      showAlternativeOutput(false);
      disableOutputCheckbox(true);
    }
  }, [
    targetSpecType,
    baseSpecYear,
    targetSpecYear,
    isEditTarget,
    targetErrorBaseYear,
    targetErrorTargetYear,
    targetDataType,
    targetOutput,
    targetyearData,
  ]);

  const onUpdate = (data) => {
    setIntensityTargetData(data);
  };
  const onChangeAlternativeOutput = () => {
    alternativeOutput === true
      ? showActivityOutputTableFun(false)
      : showActivityOutputTableFun(true);
    showAlternativeOutput(!alternativeOutput);
  };

  function handleCancelClick() {
    navigate(`/${routes.createNewEngagement}/${routes.addTarget}`, {
      state: {
        engagementId: engagement.id,
      },
      gestureEnabled: false,
    });
  }

  function validateForm() {
    let isValid = true;

    if (!targetSpecName) {
      setTargetErrorName(addTargetSpecificationForecastNameRequired);
      isValid = false;
    }

    if (!targetSpecType) {
      setTargetErrorType(addTargetSpecificationTargetTypeRequired);
      isValid = false;
    }

    if (!targetSpecTemporalType) {
      setTargetErrorTemporal(addTargetSpecificationTemporalTypeRequired);
      isValid = false;
    }

    if (!percentageValue) {
      setTargetErrorPercent(
        targetSpecTemporalType === "Annual"
          ? addTargetSpecificationPercentValueAnnualRequired
          : addTargetSpecificationPercentValueTargetYearRequired
      );
      isValid = false;
    }

    if (
      percentageValue &&
      (!decimalNumberRegExp.test(percentageValue) || percentageValue > 100)
    ) {
      setTargetErrorPercent(targetPercentageError);
      isValid = false;
    }

    if (!baseSpecYear) {
      setTargetErrorBaseYear(addTargetSpecificationBaseYearRequired);
      isValid = false;
    }

    if (baseSpecYear && parseFloat(baseSpecYear) > engagement.mostRecentYear) {
      setTargetErrorBaseYear(addTargetSpecificationBaseYearLessThanMRY);
      isValid = false;
    }

    if (
      baseSpecYear &&
      parseFloat(baseSpecYear) <= engagement.mostRecentYear - 10
    ) {
      setTargetErrorBaseYear(addTargetSpecificationBaseYearGreaterThanFEY);
      isValid = false;
    }

    if (!targetSpecYear) {
      setTargetErrorTargetYear(addTargetSpecificationTargetYearRequired);
      isValid = false;
    }

    if (
      targetSpecYear &&
      parseFloat(targetSpecYear) <= engagement.mostRecentYear
    ) {
      setTargetErrorTargetYear(addTargetSpecificationTargetYearGreaterThanMRY);
      isValid = false;
    }

    if (
      targetSpecYear &&
      parseFloat(targetSpecYear) > engagement?.foreCastEndYear
    ) {
      setTargetErrorTargetYear(addTargetSpecificationTargetYearLessThanFEY);
      isValid = false;
    }

    if (emissionModalData?.length === 0) {
      setTargetErrorEmissionModel(addTargetSpecificationEmissionRequired);
      isValid = false;
    } else {
      if (emissionModalData?.scope?.length === 0) {
        setTargetErrorEmissionModel(addTargetSpecificationEmissionRequired);
        isValid = false;
      }
    }

    if (geoSpecModalData?.length === 0) {
      setTargetErrorGeoModal(addTargetSpecificationGeoRequired);
      isValid = false;
    } else {
      if (geoSpecModalData?.region_level1?.length === 0) {
        setTargetErrorGeoModal(addTargetSpecificationGeoRequired);
        isValid = false;
      }
    }

    if (orgSpecModalData?.length === 0) {
      setTargetErrorOrganizationalData(addTargetSpecificationOrgRequired);
      isValid = false;
    } else {
      if (orgSpecModalData?.entity_level1?.length === 0) {
        setTargetErrorOrganizationalData(addTargetSpecificationOrgRequired);
        isValid = false;
      }
    }

    return isValid;
  }
  async function findSmallest(arrays) {
    let smallest = arrays[0];
    let minlength = arrays[0].length;
    arrays.forEach((array) => {
      if (array.length < minlength) {
        smallest = array;
        minlength = array.length;
      }
    });
    let finalArray = Array.from(new Set(smallest.map(JSON.stringify))).map(
      JSON.parse
    );
    if (emissionModalData.selectAll === "subCategory1") {
      finalArray.forEach((record) => {
        record.category_level1 = "All";
        record.category_level2 = "All";
        record.category_level3 = "All";
      });
    } else if (emissionModalData.selectAll === "subCategory2") {
      finalArray.forEach((record) => {
        record.category_level2 = "All";
        record.category_level3 = "All";
      });
    } else if (emissionModalData.selectAll === "subCategory3") {
      finalArray.forEach((record) => {
        record.category_level3 = "All";
      });
    }
    if (geoSpecModalData.selectAll === "regionLevel1") {
      finalArray.forEach((record) => {
        record.region_level1 = "All";
        record.region_level2 = "All";
        record.region_level3 = "All";
      });
    } else if (geoSpecModalData.selectAll === "regionLevel2") {
      finalArray.forEach((record) => {
        record.region_level2 = "All";
        record.region_level3 = "All";
      });
    } else if (geoSpecModalData.selectAll === "regionLevel3") {
      finalArray.forEach((record) => {
        record.region_level3 = "All";
      });
    }
    if (customModalData.selectAll === "customLevel1") {
      finalArray.forEach((record) => {
        record.custom_specification1 = "All";
        record.custom_specification2 = "All";
        record.custom_specification3 = "All";
      });
    } else if (customModalData.selectAll === "customLevel2") {
      finalArray.forEach((record) => {
        record.custom_specification2 = "All";
        record.custom_specification3 = "All";
      });
    } else if (customModalData.selectAll === "customLevel3") {
      finalArray.forEach((record) => {
        record.custom_specification3 = "All";
      });
    }
    if (orgSpecModalData.selectAll === "orgLevel1") {
      finalArray.forEach((record) => {
        record.entity_level1 = "All";
        record.entity_level2 = "All";
        record.entity_level3 = "All";
        record.entity_level4 = "All";
      });
    } else if (orgSpecModalData.selectAll === "orgLevel2") {
      finalArray.forEach((record) => {
        record.entity_level2 = "All";
        record.entity_level3 = "All";
        record.entity_level4 = "All";
      });
    } else if (orgSpecModalData.selectAll === "orgLevel3") {
      finalArray.forEach((record) => {
        record.region_level3 = "All";
        record.entity_level4 = "All";
      });
    } else if (orgSpecModalData.selectAll === "orgLevel4") {
      finalArray.forEach((record) => {
        record.entity_level4 = "All";
      });
    }
    finalArray = Array.from(new Set(finalArray.map(JSON.stringify))).map(
      JSON.parse
    );
    return finalArray;
  }
  async function applyChanges() {
    if (!validateForm()) return;
    setIsDataSaveInProgress(true);
    let allpayload = [];
    let finalUniquerecords = [];
    if (customModalData?.allPayload?.length > 0) {
      let arrays = [
        geoSpecModalData.allPayload,
        orgSpecModalData.allPayload,
        customModalData.allPayload,
      ];
      let finalArray = await findSmallest(arrays);
      finalUniquerecords = finalArray;
    } else {
      let arrays = [geoSpecModalData.allPayload, orgSpecModalData.allPayload];

      let finalArray = await findSmallest(arrays);
      finalArray.forEach((record) => {
        record.custom_specification1 = "All";
        record.custom_specification2 = "All";
        record.custom_specification3 = "All";
      });
      finalUniquerecords = finalArray;
    }

    try {
      if (targetData?.id) {
        finalUniquerecords.forEach((record) => {
          let request = {
            engagementID: engagement.id,
            clientname: engagement.clientName,
            name: targetSpecName,
            description: targetDescription,
            region_level1: record.region_level1,
            region_level2: record.region_level2,
            region_level3: record.region_level3,
            entity_level1: record.entity_level1,
            entity_level2: record.entity_level2,
            entity_level3: record.entity_level3,
            entity_asset: record.entity_level4,
            scope: record.scope,
            category_level1: record.category_level1,
            category_level2: record.category_level2,
            category_level3: record.category_level3,
            custom_specification1: record.custom_specification1,
            custom_specification2: record.custom_specification2,
            custom_specification3: record.custom_specification3,
            target_type: targetSpecType,
            temporal_type: targetSpecTemporalType,
            target_percentage: percentageValue,
            target_year: parseFloat(targetSpecYear),
            baseYear: parseFloat(baseSpecYear),
            batchId: targetData?.batchId ?? 0,
            batchGroupID: targetData?.batchGroupID ?? 0,
            statusFlag: targetData?.statusFlag ?? "",
            aD_Key: targetData?.aD_Key ?? "",
            activeFlag: targetData?.activeFlag ?? 1,
            id: targetData?.id ?? 0,
            modifiedby: targetData?.modifiedby ?? user.username,
            modifieddate: targetData?.modifieddate ?? new Date(),
            status: "",
            updatestatus: "",
            record_status: "",
            target_status: "",
            isValidated: "Y",
          };
          allpayload.push(request);
        });

        await allUpdateTarget({ allpayload });
      } else {
        finalUniquerecords.forEach((record) => {
          let request = {
            engagementID: engagement.id,
            clientname: engagement.clientName,
            name: targetSpecName,
            description: targetDescription,
            region_level1: record.region_level1,
            region_level2: record.region_level2,
            region_level3: record.region_level3,
            entity_level1: record.entity_level1,
            entity_level2: record.entity_level2,
            entity_level3: record.entity_level3,
            entity_asset: record.entity_level4,
            scope: record.scope,
            category_level1: record.category_level1,
            category_level2: record.category_level2,
            category_level3: record.category_level3,
            custom_specification1: record.custom_specification1,
            custom_specification2: record.custom_specification2,
            custom_specification3: record.custom_specification3,
            target_type: targetSpecType,
            temporal_type: targetSpecTemporalType,
            target_percentage: percentageValue,
            target_year: parseFloat(targetSpecYear),
            baseYear: parseFloat(baseSpecYear),
            createddate: targetData?.createddate ?? new Date(),
            createdby: targetData?.createdby ?? user.username,
            batchId: targetData?.batchId ?? 0,
            batchGroupID: targetData?.batchGroupID ?? 0,
            statusFlag: targetData?.statusFlag ?? "",
            aD_Key: targetData?.aD_Key ?? "",
            activeFlag: targetData?.activeFlag ?? 1,
            id: targetData?.batchGroupID ?? 0,
            status: "",
            updatestatus: "",
            record_status: "",
            target_status: "",
            isValidated: "Y",
          };
          allpayload.push(request);
        });
        await allSaveTargetSpecification({ allpayload });
      }
    } catch (e) {
      console.error(e);
    }

    let payload = {
      engagementId: engagement.id,
      engagementCode: engagement.engagementCode,
      name: targetSpecName,
      clientname: engagement.clientName,
      description: targetDescription,
      target_type: targetSpecType,
      temporal_type: targetSpecTemporalType,
      target_percentage: percentageValue,
      baseYear: parseFloat(baseSpecYear),
      target_year: parseFloat(targetSpecYear),
      createddate: targetData?.createddate ?? new Date(),
      createdby: targetData?.createdby ?? user.username,
      id: targetData?.batchGroupID ?? 0,
      dropDownMetaData: [],
    };

    // Emission Data
    const selectedScope = emissionModalData.scope.map((x) => {
      return { columnType: "Scope", columnValue: x };
    });
    const selectedCatLevel1 = emissionModalData.category_level1.map((x) => {
      return { columnType: "Category_level1", columnValue: x };
    });
    const selectedCatLevel2 = emissionModalData.category_level2.map((x) => {
      return { columnType: "Category_level2", columnValue: x };
    });
    const selectedCatLevel3 = emissionModalData.category_level3.map((x) => {
      return { columnType: "Category_level3", columnValue: x };
    });

    //Geo Data
    const selectedRegionLevel1 = geoSpecModalData.region_level1.map((x) => {
      return { columnType: "Region_level1", columnValue: x };
    });
    const selectedRegionLevel2 = geoSpecModalData.region_level2.map((x) => {
      return { columnType: "Region_level2", columnValue: x };
    });
    const selectedRegionLevel3 = geoSpecModalData.region_level3.map((x) => {
      return { columnType: "Region_level3", columnValue: x };
    });

    //Org Data
    const selectedEntityLevel1 = orgSpecModalData.entity_level1.map((x) => {
      return { columnType: "Entity_level1", columnValue: x };
    });
    const selectedEntityLevel2 = orgSpecModalData.entity_level2.map((x) => {
      return { columnType: "Entity_level2", columnValue: x };
    });
    const selectedEntityLevel3 = orgSpecModalData.entity_level3.map((x) => {
      return { columnType: "Entity_level3", columnValue: x };
    });
    const selectedEntityLevel4 = orgSpecModalData.entity_level4.map((x) => {
      return { columnType: "Entity_level4", columnValue: x };
    });

    //Custom Data
    let selectedCustom1,
      selectedCustom2,
      selectedCustom3 = [];
    if (
      customModalData.custom_specification1 &&
      !customModalData.custom_specification1.includes("N/A")
    ) {
      selectedCustom1 = customModalData.custom_specification1.map((x) => {
        return { columnType: "Custom_specification1", columnValue: x };
      });
      selectedCustom2 = customModalData.custom_specification2.map((x) => {
        return { columnType: "Custom_specification2", columnValue: x };
      });
      selectedCustom3 = customModalData.custom_specification3.map((x) => {
        return { columnType: "Custom_specification3", columnValue: x };
      });
    } else {
      selectedCustom1 = [
        { columnType: "Custom_specification1", columnValue: "All" },
      ];
      selectedCustom2 = [
        { columnType: "Custom_specification2", columnValue: "All" },
      ];
      selectedCustom3 = [
        { columnType: "Custom_specification3", columnValue: "All" },
      ];
    }

    payload.dropDownMetaData = [
      ...selectedScope,
      ...selectedCatLevel1,
      ...selectedCatLevel2,
      ...selectedCatLevel3,
      ...selectedRegionLevel1,
      ...selectedRegionLevel2,
      ...selectedRegionLevel3,
      ...selectedEntityLevel1,
      ...selectedEntityLevel2,
      ...selectedEntityLevel3,
      ...selectedEntityLevel4,
      ...selectedCustom1,
      ...selectedCustom2,
      ...selectedCustom3,
    ];

    let isEditTargetDuplicate = false;
    let isNewTargetDuplicate = false;
    // if (targetSpecType === "Absolute"){
    //   try {
    //     if (targetData?.id) {
    //       await updateTargetSpecification({ payload });
    //     } else {
    //       await saveTargetSpecification({ payload });
    //     }
    //   } catch (e) {
    //     console.error(e);
    //   }
    // }else

    if (alternativeOutput === true && targetSpecType === "Intensity") {
      const intensityTargetData = intenstiyTargetDataSet;
      let dataArr = [];
      var result = Object.keys(intensityTargetData).map((key) => [
        key,
        intensityTargetData[key],
      ]);
      if (result.length > 2) {
        result.map((item, i) => {
          if (i < result.length - 2) {
            dataArr.push({
              activityYear: Number(item[0]),
              manualIntensity_Value: Number(item[1]),
            });
          }
        });
      } else {
        for (let i = 0; i <= +targetSpecYear - +baseSpecYear; i++) {
          dataArr.push({
            activityYear: Number(baseSpecYear) + i,
            manualIntensity_Value: 0,
          });
        }
      }
      // payload["targetTypeID"] = 1
      payload["manualIntensity_flag"] = "Y";
      payload["data_type"] = intenstiyTargetDataSet?.dataType;
      payload["manualIntensity_Unit"] = intenstiyTargetDataSet?.outputUnit;
      payload["intensityTargetData"] = dataArr;
      try {
        if (targetData?.id) {
          await updateTargetSpecification({ payload });
          // isEditTargetDuplicate = editTargetResult.data[0].isDuplicate === "Y" ? true : false;
        } else {
          await saveTargetSpecification({ payload });
          // isNewTargetDuplicate = newTargetResult.data[0].isDuplicate === "Y" ? true : false
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      payload["manualIntensity_flag"] = "N";
      payload["data_type"] = "";
      payload["manualIntensity_Unit"] = "";
      payload["intensityTargetData"] = [];
      try {
        if (targetData?.id) {
          const editTargetResult = await updateTargetSpecification({ payload });
          isEditTargetDuplicate =
            editTargetResult?.data[0]?.isDuplicate === "Y" ? true : false;
        } else {
          const newTargetResult = await saveTargetSpecification({ payload });
          isNewTargetDuplicate =
            newTargetResult?.data[0]?.isDuplicate === "Y" ? true : false;
        }
      } catch (e) {
        console.error(e);
      }
    }

    if (isEditTargetDuplicate || isNewTargetDuplicate) {
      setIsDataSaveInProgress(false);
      setShowTargetValidationMsg(true);
      return;
    } else {
      setIsDataSaveInProgress(false);

      navigate(`/${routes.createNewEngagement}/${routes.addTarget}`, {
        state: {
          engagementId: engagement.id,
        },
        gestureEnabled: false,
      });
    }
  }

  const onTargetValidationModalClose = () => {
    setShowTargetValidationMsg(false);
  };

  const handleEmissionModalClose = () => {
    showModal(false);
  };

  const handleEmissionModalConfirm = (data) => {
    showModal(false);
    setEmissionModalData(data);
    setSelectedEmissionFilters(data);
    setScopeArray(data.scope);
    setGeoSpecModalData([]);
    setOrgSpecModalData([]);
    setCustomModalData([]);
    setSelectedGeoFilters([]);
    setSelectedOrgFilters([]);
    setSelectedCustomFilters([]);
    setTargetEmissionData([]);
    setTargetGeoData([]);
    setTargetOrganizationalData([]);
    setTargetCustomData([]);
    if (data.category_level1.length > 0) {
      setTargetErrorEmissionModel("");
    }
  };

  const handleEditData = (data) => {
    setDropDownError(true);
    setEmissionModalData(data);
    setScopeArray(data.scope);
    setDropDownError(false);
  };

  const handleGeoModalClose = () => {
    showGeoModal(false);
  };

  const handleGeoModalConfirm = (data) => {
    showGeoModal(false);
    setGeoSpecModalData(data);
    setSelectedGeoFilters(data);
    if (data.region_level1.length > 0) {
      setTargetErrorGeoModal("");
    }
    setTargetGeoData(data);
  };

  const handleGeoEditData = (data) => {
    setGeoSpecModalData(data);
  };

  const handleOrgModalClose = () => {
    showOrgModal(false);
  };

  const handleOrgModalConfirm = (data) => {
    showOrgModal(false);
    setOrgSpecModalData(data);
    setSelectedOrgFilters(data);
    if (data.entity_level1.length > 0) {
      setTargetErrorOrganizationalData("");
    }
    setTargetOrganizationalData(data);
  };

  const handleOrgEditData = (data) => {
    setOrgSpecModalData(data);
  };

  const handleCustomModalClose = () => {
    showCustomModal(false);
  };
  const hanldeCustomEditData = (data) => {
    setCustomModalData(data);
  };
  const handleCustomModalConfirm = (data) => {
    showCustomModal(false);
    setCustomModalData(data);
    setSelectedCustomFilters(data);
    setTargetCustomData(data);
  };

  async function GetAllHierarchyData() {
    const request = {
      engagement: engagement.engagementCode,
      clientName: engagement.clientName,
    };
    const data = await getHierarchyLevelData({ request });
    setHierarchyData(data);
  }

  useEffect(() => {
    if (engagement?.id && state?.batchGroupId) {
      getTargetByGroupId(engagement?.id, state?.batchGroupId);
    }
  }, [engagement?.id, state?.batchGroupId, engagement.id]);

  async function checkTargetName(value) {
    if (!value) return;

    const request = {
      name: value,
      type: "Target",
      engagementId: engagement.id,
    };
    const data = await checkName({ request });
    if (data.code === 1) {
      setTargetErrorName(data.status);
    } else {
      setTargetErrorName("");
    }
  }
  async function getTargetByGroupId(engId, groupId) {
    const request = {
      engagementId: engId,
      batchGroupId: groupId,
    };
    setDropDownError(true);
    const data = await getForecastTargetByGroupId({
      request,
    });

    setIsEditTarget(true);
    setTargetData(data.data[data.data.length - 1]);
    setTargetSpecName(data.data[data.data.length - 1].name);
    setTargetDescription(data.data[data.data.length - 1].description);
    setTargetSpecType(data.data[data.data.length - 1].target_type);
    setTargetSpecTypeDisable(true);
    setTargetSpecTemporalType(data.data[data.data.length - 1].temporal_type);
    setPercentage(data.data[data.data.length - 1].target_percentage);
    setBaseSpecYear(data.data[data.data.length - 1].baseYear);
    setTargetSpecYear(data.data[data.data.length - 1].target_year);
    setTargetIntensityDataType(data.data[data.data.length - 1].data_type);
    setTargetIntensityOutputUnit(data.data[data.data.length - 1].output_Unit);
    setTargetIntensityYearData(
      data.data[data.data.length - 1].intensityTargetData
    );

    let scopeArray = [...new Set(data.data.map((element) => element.scope))];
    setScopeArray(scopeArray);

    let category_level1 = [
      ...new Set(data.data.map((element) => element.category_level1)),
    ];
    let category_level2 = [
      ...new Set(data.data.map((element) => element.category_level2)),
    ];
    let category_level3 = [
      ...new Set(data.data.map((element) => element.category_level3)),
    ];
    if (state.isValidated === "N") {
      setTargetEmissionData({
        scopeArray: [],
        category_level1: [],
        category_level2: [],
        category_level3: [],
      });
    } else {
      setTargetEmissionData({
        scopeArray: scopeArray,
        category_level1: category_level1,
        category_level2: category_level2,
        category_level3: category_level3,
      });
    }

    let entity_level1 = [
      ...new Set(data.data.map((element) => element.entity_level1)),
    ];
    let entity_level2 = [
      ...new Set(data.data.map((element) => element.entity_level2)),
    ];
    let entity_level3 = [
      ...new Set(data.data.map((element) => element.entity_level3)),
    ];
    let entity_level4 = [
      ...new Set(data.data.map((element) => element.entity_asset)),
    ];

    if (state.isValidated === "N") {
      setTargetOrganizationalData({
        entity_level1: [],
        entity_level2: [],
        entity_level3: [],
        entity_level4: [],
      });
    } else {
      setTargetOrganizationalData({
        entity_level1: entity_level1,
        entity_level2: entity_level2,
        entity_level3: entity_level3,
        entity_level4: entity_level4,
      });
    }

    let region_level1 = [
      ...new Set(data.data.map((element) => element.region_level1)),
    ];
    let region_level2 = [
      ...new Set(data.data.map((element) => element.region_level2)),
    ];
    let region_level3 = [
      ...new Set(data.data.map((element) => element.region_level3)),
    ];

    if (state.isValidated === "N") {
      setTargetGeoData({
        region_level1: [],
        region_level2: [],
        region_level3: [],
      });
    } else {
      setTargetGeoData({
        region_level1: region_level1,
        region_level2: region_level2,
        region_level3: region_level3,
      });
    }

    let custom_specification1 = [
      ...new Set(data.data.map((element) => element.custom_specification1)),
    ];
    let custom_specification2 = [
      ...new Set(data.data.map((element) => element.custom_specification2)),
    ];
    let custom_specification3 = [
      ...new Set(data.data.map((element) => element.custom_specification3)),
    ];

    if (state.isValidated === "N") {
      setTargetCustomData({
        custom_specification1: [],
        custom_specification2: [],
        custom_specification3: [],
      });
    } else {
      setTargetCustomData({
        custom_specification1: custom_specification1,
        custom_specification2: custom_specification2,
        custom_specification3: custom_specification3,
      });
    }

    setTimeout(() => {
      setDropDownError(false);
    }, 2000);
  }

  useEffect(() => {
    if (engagement?.id) {
      GetAllHierarchyData();
    }
  }, [engagement?.id]);

  useEffect(
    () => () => {
      setTargetErrorBaseYear("");
      setTargetErrorEmissionModel("");
      setTargetErrorGeoModal("");
      setTargetErrorName("");
      setTargetErrorOrganizationalData("");
      setTargetErrorPercent("");
      setTargetErrorTargetYear("");
      setTargetErrorTemporal("");
      setTargetErrorType("");
      setTargetSpecName("");
      setTargetDescription("");
      setTargetSpecType("");
      setTargetSpecTemporalType("");
      setPercentage("");
      setBaseSpecYear("");
      setTargetSpecYear("");
      setEmissionModalData([]);
      setGeoSpecModalData([]);
      setOrgSpecModalData([]);
      setCustomModalData([]);
      setTargetEmissionData({});
      setTargetOrganizationalData({});
      setTargetGeoData({});
      setTargetCustomData({});
      setScopeArray([]);
      setHierarchyData([]);
      setTargetData({});
      setIsDataSaveInProgress(false);
      setTargetSpecTypeDisable(false);
    },
    []
  );

  return (
    <div className="add-target-specification">
      <div className="add-target-specification-header">
        <div className="add-target-specification-header-title">
          {steps[currentStep]?.stepHeading}
        </div>
        <div className="add-target-specification-header-stepper">
          <Stepper
            steps={steps}
            currentStep={currentStep}
            stepIndex={stepIndex}
          />
        </div>
      </div>
      <div className="add-target-specification-content">
        <div className="add-target-specification-content-header">
          <div className="add-target-specification-content-header-main">
            <span>{addTargetSpecificationName}</span>
          </div>
        </div>
        <div className="add-target-specification-content-body">
          <div className="add-target-specification-content-body-row">
            <span className="add-target-specification-content-body-name-title decarb-input-error-label">
              {addTargetSpecificationTargetName}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="default"
                tooltipText={targetNameTooltip}
              ></Tooltip>
            </span>
            <Input
              type={"text"}
              value={targetSpecName}
              onChange={(event) => {
                setTargetSpecName(event.target.value);
                setTargetErrorName("");
              }}
              onBlur={(event) => {
                checkTargetName(event.target.value);
              }}
              errorMessage={targetErrorName}
              isError={targetErrorName !== ""}
              disableErrorPlaceholder={false}
              disabled={isDataSaveInProgress || targetData?.id}
            ></Input>
          </div>
          <div className="add-target-specification-content-body-row">
            <span className="add-target-specification-content-body-desc-title">
              {addTargetSpecificationTargetDesc}
              <Tooltip
                trigger={<EmissionIcon />}
                variant="default"
                tooltipText={targetDescriptiontooltip}
              ></Tooltip>
            </span>
            <div
              style={{ flex: 1 }}
              className="add-forecast-specification-body-error-button"
            >
              <TextArea
                value={targetDescription}
                onChange={(event) => {
                  setTargetDescription(event.target.value);
                }}
                disabled={isDataSaveInProgress}
              />
              <div className="decarb-input-hide-error">No Error</div>
            </div>
          </div>
          <div className="add-target-specification-content-body-row">
            <span className="add-target-specification-content-body-name-title decarb-input-error-label">
              {addTargetSpecificationTargetType}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="default"
                tooltipText={targetTypetooltip}
              ></Tooltip>
            </span>
            <div style={{ flex: 1 }}>
              <Select
                value={targetSpecType}
                options={addTargetSpecificationTargetTypeOptions}
                onChange={(event) => {
                  setTargetSpecType(event);
                  setTargetErrorType("");
                }}
                errorMessage={targetErrorType}
                isError={targetErrorType !== ""}
                disableErrorPlaceholder={false}
                disabled={isDataSaveInProgress || targetSpecTypeDisable}
              />
            </div>
            <span className="add-target-specification-content-body-name-title decarb-input-error-label">
              {addTargetSpecificationTargetTemporalType}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="default"
                tooltipText={temporalTypeTooltip}
              ></Tooltip>
            </span>
            <div style={{ flex: 1 }}>
              <Select
                value={targetSpecTemporalType}
                options={addTargetSpecificationTemporalTypeOptions}
                onChange={(event) => {
                  setTargetSpecTemporalType(event);
                  setTargetErrorTemporal("");
                  setTargetErrorPercent("");
                }}
                errorMessage={targetErrorTemporal}
                isError={targetErrorTemporal !== ""}
                disableErrorPlaceholder={false}
                disabled={isDataSaveInProgress}
              />
            </div>
          </div>
          <div className="add-target-specification-content-body-row">
            <span className="add-target-specification-content-body-name-title decarb-input-error-label">
              {targetSpecTemporalType === "Annual"
                ? addTargetSpecificationPercentValueAnnual
                : addTargetSpecificationPercentValueTargetYear}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="default"
                tooltipText={targetPercentageTooltip}
              ></Tooltip>
            </span>
            <Input
              type="decimal"
              maxLength={5}
              value={percentageValue}
              onChange={(event) => {
                setPercentage(event.target.value);
                setTargetErrorPercent("");

                if (!event.target.value) {
                  setTargetErrorPercent(
                    targetSpecTemporalType === "Annual"
                      ? addTargetSpecificationPercentValueAnnualRequired
                      : addTargetSpecificationPercentValueTargetYearRequired
                  );
                }

                if (
                  event.target.value &&
                  (!decimalNumberRegExp.test(event.target.value) ||
                    event.target.value > 100 ||
                    event.target.value < -99.99)
                ) {
                  setTargetErrorPercent(targetPercentageError);
                }
              }}
              errorMessage={targetErrorPercent}
              isError={targetErrorPercent !== ""}
              disableErrorPlaceholder={false}
              disabled={isDataSaveInProgress}
            ></Input>
            <span className="add-target-specification-content-body-name-title decarb-input-hide-error">
              <span className="required-star">*</span>
            </span>
            <Input
              className={"decarb-input-hide-error"}
              hideClearButton
              disabled={isDataSaveInProgress}
              type={"text"}
            ></Input>
          </div>
          <div className="add-target-specification-content-body-row">
            <span className="add-target-specification-content-body-name-title decarb-input-error-label">
              {addTargetSpecificationBaseYear}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="default"
                tooltipText={targetBaseYearTooltip}
              ></Tooltip>
            </span>
            <Input
              type="number"
              maxLength={4}
              value={baseSpecYear}
              onChange={(event) => {
                let year = event.target.value;
                setBaseSpecYear(year);
                setTargetErrorBaseYear("");
                setTargetIntensityYearData({});

                if (!year) {
                  setTargetErrorBaseYear(
                    addTargetSpecificationBaseYearRequired
                  );
                }

                if (year && parseFloat(year) > engagement.mostRecentYear) {
                  setTargetErrorBaseYear(
                    addTargetSpecificationBaseYearLessThanMRY
                  );
                }

                if (
                  year &&
                  parseFloat(year) <= engagement.mostRecentYear - 10
                ) {
                  setTargetErrorBaseYear(
                    addTargetSpecificationBaseYearGreaterThanFEY
                  );
                }
              }}
              errorMessage={targetErrorBaseYear}
              isError={targetErrorBaseYear !== ""}
              disableErrorPlaceholder={false}
              disabled={isDataSaveInProgress}
            ></Input>
            <span className="add-target-specification-content-body-name-title decarb-input-error-label">
              {addTargetSpecificationTargetYear}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="default"
                tooltipText={targetYearTooltip}
              ></Tooltip>
            </span>
            <Input
              type="number"
              maxLength={4}
              value={targetSpecYear}
              onChange={(event) => {
                let year = event.target.value;
                setTargetSpecYear(year);
                setTargetErrorTargetYear("");
                setTargetIntensityYearData({});
                if (!year) {
                  setTargetErrorTargetYear(
                    addTargetSpecificationTargetYearRequired
                  );
                }

                if (year && parseFloat(year) <= engagement.mostRecentYear) {
                  setTargetErrorTargetYear(
                    addTargetSpecificationTargetYearGreaterThanMRY
                  );
                }

                if (year && parseFloat(year) > engagement?.foreCastEndYear) {
                  setTargetErrorTargetYear(
                    addTargetSpecificationTargetYearLessThanFEY
                  );
                }
              }}
              errorMessage={targetErrorTargetYear}
              isError={targetErrorTargetYear !== ""}
              disableErrorPlaceholder={false}
              disabled={isDataSaveInProgress}
            ></Input>
          </div>
          <div className="add-target-specification-content-body-row">
            <span className="add-target-specification-content-body-name-title decarb-input-error-label">
              {addTargetSpecificationEmission}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="default"
                tooltipText={targetEmissionTooltip}
              ></Tooltip>
            </span>
            <div className="add-forecast-specification-body-error-button">
              <LoadingIndicatorEmbed show={dropdownError}>
                <Button
                  variant="primary-alt"
                  onClick={() => {
                    setTargetErrorEmissionModel("");
                    showModal(true);
                  }}
                  className={"add-target-specification-content-body-button"}
                  disabled={
                    isDataSaveInProgress ||
                    (state?.batchGroupID && dropdownError) ||
                    (state?.batchGroupID &&
                      targetEmissionData.category_level1?.length === 0)
                  }
                >
                  <AddIcon />
                  {addTargetSpecificationAddEmissionBtn}
                  <p className="number">
                    {" "}
                    {emissionModalData.scope?.length > 0
                      ? emissionModalData.scope?.length
                      : targetEmissionData?.scopeArray?.length ?? 0}
                  </p>
                </Button>
              </LoadingIndicatorEmbed>
              {targetErrorEmissionModal ? (
                <MotifErrorMessage className="decarb-input-error-message">
                  {targetErrorEmissionModal}
                </MotifErrorMessage>
              ) : (
                <div className="decarb-input-hide-error">No Error</div>
              )}
            </div>
            <span className="add-target-specification-content-body-name-title decarb-input-error-label">
              {addTargetSpecificationGeo}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="default"
                tooltipText={targetGeographytooltip}
              ></Tooltip>
            </span>
            <div className="add-forecast-specification-body-error-button">
              <LoadingIndicatorEmbed show={dropdownError}>
                <Button
                  variant="primary-alt"
                  onClick={() => {
                    setTargetErrorGeoModal("");
                    showGeoModal(true);
                  }}
                  className={"add-target-specification-content-body-button"}
                  disabled={
                    isDataSaveInProgress ||
                    (state?.batchGroupID && dropdownError) ||
                    (state?.batchGroupID &&
                      targetGeoData.region_level1?.length === 0)
                  }
                >
                  <AddIcon />
                  {addTargetSpecificationAddGeoBtn}
                  <p className="number">
                    {" "}
                    {geoSpecModalData.region_level1?.length > 0
                      ? geoSpecModalData.region_level1?.length
                      : targetGeoData?.region_level1?.length ?? 0}
                  </p>
                </Button>
              </LoadingIndicatorEmbed>
              {targetErrorGeoModal ? (
                <MotifErrorMessage className="decarb-input-error-message">
                  {targetErrorGeoModal}
                </MotifErrorMessage>
              ) : (
                <div className="decarb-input-hide-error">No Error</div>
              )}
            </div>
          </div>
          <div className="add-target-specification-content-body-row">
            <span className="add-target-specification-content-body-name-title decarb-input-error-label">
              {addTargetSpecificationOrg}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="default"
                tooltipText={targetOrganisationTooltip}
              ></Tooltip>
            </span>
            <div className="add-forecast-specification-body-error-button">
              <LoadingIndicatorEmbed show={dropdownError}>
                <Button
                  variant="primary-alt"
                  onClick={() => {
                    setTargetErrorOrganizationalData("");
                    showOrgModal(true);
                  }}
                  className={"add-target-specification-content-body-button"}
                  disabled={
                    isDataSaveInProgress ||
                    (state?.batchGroupID && dropdownError) ||
                    (state?.batchGroupID &&
                      targetOrganizationalData.entity_level1?.length === 0)
                  }
                >
                  <AddIcon />
                  {addTargetSpecificationAddOrgBtn}
                  <p className="number">
                    {" "}
                    {orgSpecModalData.entity_level1?.length > 0
                      ? orgSpecModalData.entity_level1?.length
                      : targetOrganizationalData?.entity_level1?.length ?? 0}
                  </p>
                </Button>
              </LoadingIndicatorEmbed>
              {targetErrorOrganizationalData ? (
                <MotifErrorMessage className="decarb-input-error-message">
                  {targetErrorOrganizationalData}
                </MotifErrorMessage>
              ) : (
                <div className="decarb-input-hide-error">No Error</div>
              )}
            </div>
            <span className="add-target-specification-content-body-name-title decarb-input-error-label">
              {addTargetSpecificationCustom}
              <Tooltip
                trigger={<EmissionIcon />}
                variant="default"
                tooltipText={targetCustomTooltip}
              ></Tooltip>
            </span>
            <div className="add-forecast-specification-body-error-button">
              <LoadingIndicatorEmbed show={dropdownError}>
                <Button
                  variant="primary-alt"
                  onClick={() => {
                    showCustomModal(true);
                  }}
                  className={"add-target-specification-content-body-button"}
                  disabled={
                    isDataSaveInProgress ||
                    (state?.batchGroupId && dropdownError)
                  }
                >
                  <AddIcon />
                  {addTargetSpecificationAddCustomBtn}
                  <p className="number">
                    {" "}
                    {customModalData.custom_specification1?.length > 0
                      ? customModalData.custom_specification1?.length
                      : targetCustomData?.custom_specification1?.length ?? 0}
                  </p>
                </Button>
              </LoadingIndicatorEmbed>
              <div className="decarb-input-hide-error">No Error</div>
            </div>
          </div>
        </div>
        <div className="add-target-specification-content-body-row">
          <span className="add-target-specification-content-body-alternative-output-description decarb-input-error-label">
            {activityOutputText} &nbsp;
            <Tooltip
              trigger={<EmissionIcon />}
              variant="default"
              tooltipText={intesityTooltip}
            ></Tooltip>
            <MotifCheckbox
              checked={alternativeOutput}
              className="decarb-target-checkbox"
              disabled={isOutputCheckboxDisabled}
              onChange={() => {
                onChangeAlternativeOutput(true);
              }}
              id={crypto.randomUUID()}
              name="motif-checkbox"
            />
          </span>
        </div>

        {showActivityOutputTable && alternativeOutput && (
          <>
            <div className="add-target-specification-content-body-row">
              <span className="add-target-specification-content-body-alternative-output decarb-input-error-label">
                {activityOutput}
              </span>
            </div>
            <div className="add-target-intensity-table">
              <IntensityTargetTable
                startYear={baseSpecYear}
                endYear={targetSpecYear}
                onUpdate={(data) => {
                  onUpdate(data);
                }}
                targetDataType={targetDataType}
                targetOutput={targetOutput}
                targetyearData={targetyearData}
              ></IntensityTargetTable>
            </div>
          </>
        )}
      </div>
      <div className="add-target-specification-footer">
        <Button
          variant="secondary"
          onClick={handleCancelClick}
          disabled={isDataSaveInProgress}
        >
          {CancelBtn}
        </Button>
        <div className="new-engagement-footer-nav-buttons">
          <LoadingIndicatorEmbed show={isDataSaveInProgress}>
            <Button
              variant="primary"
              onClick={applyChanges}
              disabled={isDataSaveInProgress || targetErrorName !== ""}
            >
              {SaveBtn}
            </Button>
          </LoadingIndicatorEmbed>
        </div>
      </div>

      <EmissionModal
        isModalVisible={isOpen}
        onClose={handleEmissionModalClose}
        hierarchyData={hierarchialData}
        emissionData={targetEmissionData}
        onConfirm={handleEmissionModalConfirm}
        onEdit={handleEditData}
      />
      <OrganizationModal
        isModalVisible={isOrgModalOpen}
        onClose={handleOrgModalClose}
        onConfirm={handleOrgModalConfirm}
        scopeArray={scopeArray}
        hierarchyData={hierarchialData}
        targetOrganizationalData={targetOrganizationalData}
        selectedEmissionModalData={selectedemissionFilters}
        selectedOrgModalData={selectedorgFilters}
        selectedGeoData={selectedGeoFilters}
        onEdit={handleOrgEditData}
      />
      <GeographicalModal
        isModalVisible={isGeoModalOpen}
        onClose={handleGeoModalClose}
        scopeArray={scopeArray}
        hierarchyData={hierarchialData}
        targetGeoData={targetGeoData}
        onConfirm={handleGeoModalConfirm}
        selectedEmissionModalData={selectedemissionFilters}
        selectedOrgModalData={selectedorgFilters}
        selectedGeoData={selectedGeoFilters}
        onEdit={handleGeoEditData}
      />
      <CustomModal
        isModalVisible={isCustomModalOpen}
        onClose={handleCustomModalClose}
        onConfirm={handleCustomModalConfirm}
        scopeArray={scopeArray}
        hierarchyData={hierarchialData}
        targetCustomData={targetCustomData}
        selectedEmissionModalData={selectedemissionFilters}
        selectedOrgModalData={selectedorgFilters}
        selectedGeoData={selectedGeoFilters}
        selectedCustomData={selectedCustomFilters}
        onEdit={hanldeCustomEditData}
      />
      <TargetIntensityValidationModal
        isModalVisible={showTargetValidationMsg}
        onClose={onTargetValidationModalClose}
      />
    </div>
  );
}
