import React, { useState, useCallback, useEffect } from "react";
import { models } from "powerbi-client";
import "powerbi-report-authoring";
import "./PowerBI.css";
import { PowerBIEmbed } from "powerbi-client-react";
import { getPowerBIEmbedToken } from "../../store/services/powerBI.service";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../routes/routes.constatns";
import { useSelector } from "react-redux";
import { useGetPowerBiAuthToken } from "../../hooks/useAppToken";
import { Button } from "../Button/Button";
import { ToggleSwitch } from "../CommonUIComponents/ToggleSwitch/ToggleSwitch";
import {
  getPowerBIToggle,
  updatePowerBIToggle,
} from "../../store/services/engagement.service";

export function ExternalUserPowerBI() {
  const engagement = useSelector((state) => state.engagement.engagement);
  const project = useSelector((state) => state.project.project);
  const { state } = useLocation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const engagementCodeExUser = queryParams.get("engagementCode");
  const moduleExUser = queryParams.get("module");
  const user = useSelector((state) => state.user.user);
  const accessToken = useGetPowerBiAuthToken();
  const selectedRegion = useSelector(
    (state) => state.engagement.selectedRegion
  );
  const history = useNavigate();
  const [toggle, setToggle] = useState(true);

  useEffect(() => {
    if (engagement.id) {
      setInitialToggle();
    }
  }, [engagement]);

  const setInitialToggle = async () => {
    let request = { engagementId: engagement.id };
    const data = await getPowerBIToggle({ request });
    if (state?.module === "M1") {
      data.M1_toggle_enable === "Y" ? setToggle(true) : setToggle(false);
    } else if (state?.module === "M2") {
      data.M2_toggle_enable === "Y" ? setToggle(true) : setToggle(false);
    } else if (state?.module === "M3") {
      data.M3_toggle_enable === "Y" ? setToggle(true) : setToggle(false);
    } else if (state?.module === "M4") {
      data.M4_toggle_enable === "Y" ? setToggle(true) : setToggle(false);
    }
  };
  // const { reportId, module, engagementId, engagementCode } = state;

  const [reportConfig, setReportConfig] = useState({
    type: "report",
    embedUrl: undefined,
    id: undefined,
    tokenType: models.TokenType.Embed,
    permissions: models.Permissions.All,
    viewMode: models.ViewMode.View,
    accessToken: undefined,
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
        pageNavigation: {
          visible: false,
        },
      },
    },
  });

  const engagementCodeFilter = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "vw_EngagementMaster",
      column: "EngagementCode",
    },
    operator: "In",
    values: [engagementCodeExUser],
    filterType: models.FilterType.BasicFilter,
  };

  const getReport = useCallback(
    async (token) => {
      const response = await getPowerBIEmbedToken({
        request: {
          username: user.username,
          datasetId:
            moduleExUser === "M1"
              ? process.env.REACT_APP_POWER_BI_DATASET_ID_M1
              : moduleExUser === "M2"
              ? process.env.REACT_APP_POWER_BI_DATASET_ID_M2
              : moduleExUser === "M3"
              ? process.env.REACT_APP_POWER_BI_DATASET_ID_M3
              : moduleExUser === "M4"
              ? process.env.REACT_APP_POWER_BI_DATASET_ID_M4
              : "",
          accessToken: token,
          baseUrl: process.env.REACT_APP_API_URL_REG_US,
          module: moduleExUser,
        },
      });

      const reportConfig = {
        Id: response.reportId,
        EmbedUrl: response.embedUrl,
        Type: "PowerBIReport",
        EmbedToken: {
          Token: response.embedToken,
          TokenId: response.embedTokenId,
          Expiration: response.expiration,
        },
        MinutesToExpiration: 1,
        AccessToken: response.embedToken,
        DefaultPage: null,
        MobileDefaultPage: null,
        settings: {
          panes: {
            filters: {
              expanded: false,
              visible: false,
            },
          },
        },
        filters: [engagementCodeFilter],
      };

      setReportConfig({
        ...reportConfig,
        embedUrl: reportConfig.EmbedUrl,
        accessToken: reportConfig.AccessToken,
      });
    },
    [state?.reportId]
  );

  useEffect(() => {
    if (!accessToken) return;

    getReport(accessToken);
  }, [getReport, accessToken]);

  const goBack = () => {
    window.location.href = `${process.env.REACT_APP_URL_REG_ALL}${routes.home}`;
  };
  const replaceToggle = async (event) => {
    setToggle(!toggle);
    let request = {
      module: state?.module,
      engagementId: engagement.id,
      flagValue: event.target.checked ? "Y" : "N",
    };
    const data = await updatePowerBIToggle({ request });
  };

  return (
    <>
      <PowerBIEmbed
        embedConfig={reportConfig}
        eventHandlers={
          new Map([
            [
              "loaded",
              function () {
                console.log("Report loaded");
              },
            ],
            [
              "rendered",
              function () {
                console.log("Report rendered");
              },
            ],
            [
              "error",
              function (event) {
                console.log(event.detail);
              },
            ],
          ])
        }
        cssClassName={"report-container"}
        getEmbeddedComponent={(embeddedReport) => {
          window.Report = embeddedReport;
        }}
      />
      <div className="powerbi-button">
        <Button
          variant="primary-alt"
          onClick={() => {
            goBack();
          }}
        >
          {"Close"}
        </Button>
      </div>
    </>
  );
}
