import {
  MotifCard,
  MotifCardHeader,
  MotifCardBody,
  MotifCardImage,
} from "@ey-xd/motif-react";
import { useNavigate } from "react-router-dom";
import "./Home.scss";
import { routes } from "../../routes/routes.constatns";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  homeHeader,
  homeHeaderBody,
  homescreencards,
  CreateNewEngBtn,
  homeViewDashboard,
  homeSolutionModules,
} from "../../util/constants";
import { Button } from "../Button/Button";
import { UsersConsent } from "../UsersConsent/UsersConsent";
import {
  dropEngagementState,
  getUserConsent,
} from "../../store/slices/engagementSlice";
import { SelectRegionModal } from "../SelectRegionModal/SelectRegionModal";
import { LoadingIndicatorEmbed } from "../LoadingIndicator/LoadingIndicatorEmbed";

function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const userConsent = useSelector(
    (state) => state.engagement?.externalUserFlag
  );
  const [isSelectRegionVisible, setIsSelectRegionVisible] = useState(false);
  const [loader, setLoader] = useState(true);

  function viewEngagementPage() {
    navigate(`/${routes.viewEngagement}`, { gestureEnabled: false });
  }

  function handleCreateEngagement() {
    setIsSelectRegionVisible(true);
    dispatch(dropEngagementState());
    dispatch(getUserConsent({ email: user.username }));
  }

  const renderCards = () => {
    return homescreencards.map((element) => {
      return (
        <MotifCard className="home-bottom-cards-card" key={crypto.randomUUID()}>
          <MotifCardImage className="home-bottom-cards-card-image">
            <img
              src={require(`../../assets/Images/${element.image}.png`)}
              alt="Emission Data"
              loading="lazy"
            />
          </MotifCardImage>
          <MotifCardHeader
            variant="alt"
            className="home-bottom-cards-card-header"
          >
            <span>{element.heading}</span>
          </MotifCardHeader>
          <MotifCardBody className="home-bottom-cards-card-body">
            <span>{element.description}</span>
          </MotifCardBody>
        </MotifCard>
      );
    });
  };

  useEffect(() => {
    setLoader(true);
    if (user?.username) dispatch(getUserConsent({ email: user.username }));
  }, [dispatch, user?.username]);

  useEffect(() => {
    if (userConsent) {
      viewEngagementPage();
    }
    setLoader(false);
  }, [userConsent]);

  return (
    <LoadingIndicatorEmbed show={loader} fullscreen={false}>
      {userConsent === false && (
        <div className="home">
          <div className="home-top">
            <MotifCard className="home-top-welcome-card">
              <MotifCardHeader className="home-top-welcome-card-header">
                <span className="home-top-welcome-card-header-title">
                  {homeHeader}
                </span>
              </MotifCardHeader>
              <MotifCardBody className="home-top-welcome-card-body">
                <p>{homeHeaderBody}</p>
                <div className="home-top-welcome-card-footer-buttons">
                  {userConsent === false && (
                    <Button
                      className="home-top-welcome-card-footer-button"
                      variant="primary"
                      onClick={handleCreateEngagement}
                      disabled={userConsent !== false}
                    >
                      {CreateNewEngBtn}
                    </Button>
                  )}
                  <Button
                    className="home-top-welcome-card-footer-button alt"
                    variant="secondary"
                    onClick={viewEngagementPage}
                  >
                    {homeViewDashboard}
                  </Button>
                </div>
              </MotifCardBody>
            </MotifCard>
          </div>
          <div className="home-bottom">
            <span className="home-bottom-header">{homeSolutionModules}</span>
            <div className="home-bottom-cards">{renderCards()}</div>
          </div>
          <UsersConsent />
          <SelectRegionModal
            isModalVisible={isSelectRegionVisible}
            onClose={() => setIsSelectRegionVisible(false)}
          />
        </div>
      )}
    </LoadingIndicatorEmbed>
  );
}

export default Home;
