import React, { useState, useEffect } from "react";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import Highcharts from "highcharts";
import "./SimulateEmissionGraph.scss";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import { LoadingIndicatorEmbed } from "../../../../../LoadingIndicator/LoadingIndicatorEmbed";
NoDataToDisplay(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});
export function SimulateEmissionGraph({ sceanrioTrajectoryData, loader }) {
  HighchartsExporting(Highcharts);
  const [chartOptions, setChartOptions] = useState({});
  useEffect(() => {
    if (sceanrioTrajectoryData) {
      updateChartDataSet(sceanrioTrajectoryData);
    }
  }, [sceanrioTrajectoryData]);
  const updateChartDataSet = (data) => {
    let graphDataArray = JSON.parse(JSON.stringify(data));

    setChartOptions({
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: graphDataArray?.xAxis,
        labels: {
          style: {
            color: "#afaeba",
          },
        },
      },
      yAxis: [
        {
          min: 0,
          title: {
            text: "Emissions (tCO\u{2082}e)",
            style: {
              color: "#afaeba",
            },
          },
          labels: {
            style: {
              color: "#afaeba",
            },
          },
          stackLabels: {
            enabled: false,
            style: {
              color: "#afaeba",
            },
          },
        },
        {
          title: {
            text: "",
            style: {
              color: "#afaeba",
            },
          },
          opposite: true,
        },
      ],
      legend: {
        align: "center",
        verticalAlign: "bottom",
        layout: "horizontal",
      },
      tooltip: {
        shared: true,
        style: {
          fontSize: "12px", // Increase the font size here
        },
        formatter: function () {
          return this.points.reduce((s, point) => {
            return (
              s +
              `<br/><span style="color:${point.series.color}">\u25CF</span> ${
                point.series.name
              }:<b> ${Highcharts.numberFormat(point.y, 0)} tCO2e</b>`
            );
          }, `<b>${this.x}</b>`);
        },
      },
      plotOptions: {
        column: {
          stacking: "normal",
          dataLabels: {
            enabled: false,
          },
        },
        series: {
          borderRadius: 0,
          borderWidth: 0,
        },
      },
      marker: {
        enabled: false,
      },
      series: [
        {
          name: "Forecast emissions (without target)",
          data: graphDataArray?.forecast_without_target,
          color: "#0F69AE",
        },
        {
          name: "Forecast emissions (with target)",
          data: graphDataArray?.forecast_with_target,
          color: "#55A6FC",
        },
        {
          type: "line",
          name: "Target",
          data: graphDataArray?.target,
          yAxis: 0,
          marker: {
            enabled: false, // Disable markers
          },
          color: "#FFFFFF",
        },
      ],
      lang: {
        noData: "No data to display",
      },
      noData: {
        style: {
          fontWeight: "100",
          fontSize: "15px",
          color: "#DEDEE2",
        },
        position: {
          align: "center",
          verticalAlign: "middle",
        },
        text: "No data available",
      },
    });
  };

  return (
    <div className="emissions-graph-section">
      <div className="emissions-graph-title"></div>
      <div className="emissions-graph-content">
        <div className="emissions-trajectory-graph-content">
          <LoadingIndicatorEmbed show={loader} data-testid="loading-indicator">
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </LoadingIndicatorEmbed>
        </div>
      </div>
    </div>
  );
}
