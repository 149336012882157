import { MotifIconButton } from "@ey-xd/motif-react";
import { CloseIcon } from "../../icons/close";
import { Stepper } from "../Stepper/Stepper";
import { MotifTooltip } from "@ey-xd/motif-react";
import { CloseProjectHint } from "../../util/constants";

export function inputCustomRenderer({ steps, node }) {
  return (
    <Stepper
      steps={steps}
      size={"small"}
      stepIndex={node.data.setupStepID}
      currentStep={node.data.setupStepID}
      className={"view-engagement-stepper"}
      disableStepper={true}
    />
  );
}

export function customButtonRenderer({ onDeleteEngagementClick, node }) {
  return (
    <MotifTooltip
      hideCloseButton
      hide={node.data.activeFlag === 0}
      placement="bottom"
      variant="alt"
      trigger={
        <MotifIconButton
          aria-label="Close project"
          onClick={() =>
            node.data.activeFlag !== 0
              ? onDeleteEngagementClick(node.data.id)
              : undefined
          }
          className={`view-engagement-close-project ${
            node.data.activeFlag === 0 ? "view-engagement-link-inactive" : ""
          }`}
        >
          <CloseIcon />
        </MotifIconButton>
      }
    >
      {CloseProjectHint}
    </MotifTooltip>
  );
}

export function customTextRenderer({ node, column }) {
  return (
    <span
      className={
        node.data.activeFlag !== 0 ? "" : "view-engagement-link-inactive"
      }
    >
      {column.getColId() === "engagementCode"
        ? node.data.engagementCode
        : node.data.clientName}
    </span>
  );
}
