import React, { useState, useEffect } from "react";
import "./Setup.scss";
import {
  checkIfEngagementCodeExist,
  deleteEngagementTeamMember,
  getEngagement,
  saveTeamMember,
} from "../../store/services/engagement.service";
import { Input } from "../Input/Input";
import { Select } from "../Select/Select";
import { Table } from "../Table/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  createEngagement,
  setEngagementClientName,
  setEngagementCode,
  setEngagementName,
  setIsSaveAndNextDisabled,
} from "../../store/slices/engagementSlice";
import {
  setupEngagementCodeRequired,
  AddUserBtn,
  setupTableHeaderEmailId,
  setupTableHeaderName,
  setupTableHeaderTeam,
  setupTeamSetup,
  setupSector,
  setupClientName,
  setupEngagementName,
  setupEngagementCode,
  setupHeader,
  setupEngagementCodeLength,
  sectorTooltip,
  SaveBtn,
} from "../../util/constants";
import {
  addTeamMember,
  deleteTeamMember,
  getAllMasterSectors,
  getEngagementTeam,
  getSelectedMasterSectors,
  setSelectedSectors,
} from "../../store/slices/setupSlice";
import { AddUserIcon } from "../../icons/addUser";
import { AddUserModal } from "./SetupModal";
import CustomDeleteComponent from "./customDelete";
import { UserDeleteModal } from "./SetupDeleteModal";
import { Button } from "../Button/Button";
import { getLockingData } from "../../store/slices/projectSlice";
import { LockerModal } from "../LockerModal/lockerModal";
import { SaveUserModuleLock } from "../../store/services/project.service";
import { Tooltip } from "../CommonUIComponents/Tooltip/Tooltip";
import { EmissionIcon } from "../../icons";
import { AddGroupMember } from "../../store/services/user.service";
import { LoadingIndicatorEmbed } from "../LoadingIndicator/LoadingIndicatorEmbed";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";

export function Setup() {
  const engagement = useSelector((state) => state.engagement.engagement);
  const createEngagementLoading = useSelector(
    (state) => state.engagement.createEngagementLoading
  );
  const selectedRegion = useSelector(
    (state) => state.engagement.selectedRegion
  );
  const allSectors = useSelector((state) => state.setup.allSectors);
  const selectedSectors = useSelector((state) => state.setup.selectedSectors);
  const teamList = useSelector((state) => state.setup.teamList);
  const teamListLoading = useSelector((state) => state.setup.teamListLoading);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const [showAdduserModal, setOpenUserModal] = useState(false);
  const [showDeleteModal, setDeleteModel] = useState(false);
  const [teamMembertobeDeleted, setTeammembertobeDeleted] = useState("");
  const [engCodeErrorMessage, setEngCodeErrorMessage] = useState("");
  const engagementCodeRegExp = /^([a-zA-Z0-9-]){0,150}$/;
  const [lockedData, setLock] = useState();
  const [lockedBy, setLockedby] = useState();
  const [isLockerModalOpen, setLockerModalOpen] = useState(false);
  const [disableSaveEngagement, setSaveEngagement] = useState(true);
  const [showLoader, setLoader] = useState(false);
  const hanleInputChange1 = (event) => {
    let error = "";
    if (event.target.value?.length > 20) {
      error = setupEngagementCodeLength;
      dispatch(setIsSaveAndNextDisabled({ isDisabled: true }));
      setEngCodeErrorMessage(error);
      return;
    } else {
      setEngCodeErrorMessage("");
    }
    if (
      engagementCodeRegExp.test(event.target.value) &&
      event.target.value !== "-"
    ) {
      dispatch(setEngagementCode({ code: event.target.value }));

      if (event.target.value === "") {
        error = setupEngagementCodeRequired;
      }

      setEngCodeErrorMessage(error);
    }

    if (
      event.target.value &&
      !error &&
      engagement.engagementName &&
      engagement.clientName &&
      selectedSectors &&
      selectedSectors.length !== 0
    ) {
      dispatch(setIsSaveAndNextDisabled({ isDisabled: false }));
    } else {
      dispatch(setIsSaveAndNextDisabled({ isDisabled: true }));
    }
  };

  const hanleInputChange2 = (event) => {
    dispatch(setEngagementName({ name: event.target.value }));

    if (
      engagement.engagementCode &&
      event.target.value &&
      engagement.clientName &&
      selectedSectors &&
      selectedSectors.length !== 0
    ) {
      dispatch(setIsSaveAndNextDisabled({ isDisabled: false }));
    } else {
      dispatch(setIsSaveAndNextDisabled({ isDisabled: true }));
    }
  };
  const hanleInputChange3 = (event) => {
    dispatch(setEngagementClientName({ clientName: event.target.value }));

    if (
      engagement.engagementCode &&
      !engCodeErrorMessage &&
      engagement.engagementName &&
      event.target.value &&
      selectedSectors &&
      selectedSectors.length !== 0
    ) {
      dispatch(setIsSaveAndNextDisabled({ isDisabled: false }));
    } else {
      dispatch(setIsSaveAndNextDisabled({ isDisabled: true }));
    }
  };

  const hanleInputChange4 = async (event) => {
    if (Array.isArray(event)) {
      dispatch(setSelectedSectors({ selectedOptions: event }));
    }
    if (
      engagement.engagementCode &&
      !engCodeErrorMessage &&
      engagement.engagementName &&
      engagement.clientName &&
      event.length !== 0
    ) {
      dispatch(setIsSaveAndNextDisabled({ isDisabled: false }));
    } else {
      dispatch(setIsSaveAndNextDisabled({ isDisabled: true }));
    }
  };

  function openModal() {
    setOpenUserModal(true);
  }

  async function onDeleteUserClick(record) {
    setDeleteModel(true);
    setTeammembertobeDeleted(record.id);
  }

  const handleDeleteClose = () => {
    setDeleteModel(false);
    setTeammembertobeDeleted("");
  };

  async function handleDeleteConfirm() {
    const request = {
      engagementId: engagement.id,
      engagementTeamMemberId: teamMembertobeDeleted,
    };
    const data = await deleteEngagementTeamMember({ request });

    if (data.status === "Success") {
      dispatch(deleteTeamMember({ member: { id: teamMembertobeDeleted } }));
    }
    setDeleteModel(false);
  }

  async function handleAddUserModalClose() {
    setOpenUserModal(false);
  }

  const handleAddUSerModalSave = async (data) => {
    setOpenUserModal(false);
    let request = {
      ...data,
      activeFlag: true,
      engagementID: engagement.id,
    };
    const save = await saveTeamMember({ request });
    if (save.statusCode === 200) {
      dispatch(addTeamMember({ member: save.data }));
    }
    request = {
      emailId: data.email,
    };
    await AddGroupMember({ request });
  };

  const handlecheckIfEngagementCodeExist = async (event) => {
    if (!engagement.engagementCode) return;
    const data = await checkIfEngagementCodeExist({
      request: { engCode: engagement.engagementCode },
    });
    if (data.code === 0) {
      if (engagement?.id) {
        const request = {
          id: engagement.id,
        };
        const code = await getEngagement({ request });
        if (code.data.engagementCode !== event.target.value) {
          setEngCodeErrorMessage(data.status);
          dispatch(setIsSaveAndNextDisabled({ isDisabled: true }));
        }
      } else {
        setEngCodeErrorMessage(data.status);
        dispatch(setIsSaveAndNextDisabled({ isDisabled: true }));
      }
    } else {
      engCodeErrorMessage === ""
        ? setEngCodeErrorMessage("")
        : setEngCodeErrorMessage(engCodeErrorMessage);
      await handleCheckIfDataComplete();
    }
  };

  const handleCheckIfDataComplete = async () => {
    if (
      engagement.engagementCode &&
      !engCodeErrorMessage &&
      engagement.engagementName &&
      engagement.clientName &&
      !engagement?.id &&
      !createEngagementLoading
    ) {
      setSaveEngagement(false);
    } else {
      setSaveEngagement(true);
    }
  };

  useEffect(() => {
    dispatch(getAllMasterSectors());
  }, [dispatch]);

  useEffect(() => {
    if (engagement?.id) {
      const request = {
        id: engagement.id,
      };

      if (allSectors.length !== 0 && !selectedSectors) {
        dispatch(
          getSelectedMasterSectors({
            masterSectors: allSectors,
            request: request,
          })
        );
      }

      if (selectedSectors && selectedSectors.length !== 0) {
        dispatch(setIsSaveAndNextDisabled({ isDisabled: false }));
      }
    }
  }, [engagement?.id, dispatch, selectedSectors, allSectors]);

  useEffect(() => {
    if (engagement?.id) {
      dispatch(getEngagementTeam({ id: engagement.id }));
    }
  }, [engagement?.id, dispatch]);

  async function createNewEngagement() {
    setLoader(true);
    const request = {
      engagementName: engagement.engagementName,
      engagementCode: engagement.engagementCode,
      clientName: engagement.clientName,
      createdBy: user.username,
      engagementSetupStepID: 0,
      userName: user.username,
      regionID: selectedRegion.id,
    };

    dispatch(createEngagement(request)).then((data) => {
      if (data.payload.id) {
        setSaveEngagement(true);
        const request = {
          LockedBy: user.username,
          ModuleName: "Engagement",
          lockGuid: data.payload.id,
          Id: 0,
        };
        SaveUserModuleLock(request);
      }
    });
    setLoader(false);
  }

  // to get a locked user flags
  useEffect(() => {
    if (engagement?.id) {
      const request = {
        LockedBy: user.username,
        ModuleName: "Engagement",
        EngagementID: engagement.id,
      };

      dispatch(getLockingData(request))
        .then((action) => {
          if (action) {
            const lockedData = action.payload.data.lockStatus;
            const lockedBy = action.payload.data.lockedBy;
            setLock(lockedData);
            setLockedby(lockedBy);
            if (lockedData === 2) {
              setLockerModalOpen(true);
            }
            if (engagement?.id && lockedData === 0) {
              const request = {
                LockedBy: user.username,
                ModuleName: "Engagement",
                lockGuid: engagement.id,
                Id: 0,
              };
              SaveUserModuleLock(request);
            }
          } else {
            console.error("Failed to fetch locking data:", action.error);
          }
        })
        .catch((error) => {
          console.error("Error fetching locking data:", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div className="setup">
      <div className="setup-body">
        <div className="setup-header">
          <span className="setup-header-main">{`${setupHeader}  |  Hosting location - ${selectedRegion?.name}`}</span>
        </div>
        <div className="setup-body-details">
          <div className="setup-body-row">
            <div className="setup-row-half">
              <span className="setup-body-engagement-title">
                {setupEngagementCode}
                <span className="required-star">*</span>
              </span>
              <Input
                type={"text"}
                value={engagement.engagementCode}
                onChange={hanleInputChange1}
                errorMessage={engCodeErrorMessage}
                isError={engCodeErrorMessage}
                disableErrorPlaceholder={true}
                onMouseOut={handlecheckIfEngagementCodeExist}
                disabled={
                  engagement.id ||
                  (lockedData === 1 &&
                    lockedBy !== user.username &&
                    engagement.id)
                }
              />
            </div>
            <div className="setup-row-half">
              <span>
                {setupEngagementName}
                <span className="required-star">*</span>
              </span>
              <Input
                type={"text"}
                value={engagement.engagementName}
                onMouseOut={handleCheckIfDataComplete}
                onChange={hanleInputChange2}
                disabled={
                  engagement.id ||
                  (lockedData === 1 &&
                    lockedBy !== user.username &&
                    engagement.id)
                }
              />
            </div>
          </div>
          <div className="setup-body-row">
            <div className="setup-row-half">
              <span>
                {setupClientName}
                <span className="required-star">*</span>
              </span>
              <Input
                type={"text"}
                value={engagement.clientName}
                onMouseOut={handleCheckIfDataComplete}
                onChange={hanleInputChange3}
                disabled={
                  engagement.id ||
                  (lockedData === 1 &&
                    lockedBy !== user.username &&
                    engagement.id)
                }
              />
            </div>
            <div className="setup-row-half"></div>
          </div>
          <div className="setup-body-row">
            <div className="setup-row-half">
              <LoadingIndicatorEmbed show={createEngagementLoading}>
                <Button
                  variant="primary"
                  onClick={createNewEngagement}
                  disabled={disableSaveEngagement}
                >
                  {SaveBtn}
                </Button>
              </LoadingIndicatorEmbed>
            </div>
          </div>
        </div>
        <div className="setup-body-people">
          <div className="setup-body-row">
            <div className="setup-row-half">
              <span>
                {setupSector}
                <span className="required-star">*&nbsp;</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={sectorTooltip}
                ></Tooltip>
              </span>
              <Select
                onChange={hanleInputChange4}
                value={selectedSectors}
                multiple={true}
                visibleOptions={3}
                options={allSectors}
                disabled={
                  !engagement.id ||
                  (lockedData === 1 &&
                    lockedBy !== user.username &&
                    engagement.id)
                }
              />
            </div>
          </div>
          <div className="setup-body-row">
            <div className="setup-row-half">
              <span className="setup-user">{setupTeamSetup}</span>
              <Button
                className="setup-user-button"
                onClick={openModal}
                variant="primary-alt"
                disabled={
                  !engagement?.id ||
                  !selectedSectors ||
                  (lockedData === 1 &&
                    lockedBy !== user.username &&
                    engagement.id)
                }
              >
                <AddUserIcon />
                {AddUserBtn}
              </Button>
            </div>
            <div className="setup-row-half"></div>
          </div>
          <div className="setup-body-people-table">
            <Table
              rowData={teamList
                .map((value) => {
                  return {
                    name: value.name,
                    email: value.email,
                    engagementTeamTypeID:
                      value.engagementTeamTypeID === 0
                        ? "Internal"
                        : "External",
                    activeFlag: value.activeFlag,
                    id: value.id,
                  };
                })
                .filter((element) => element.activeFlag === true)}
              colDefs={[
                {
                  field: "name",
                  headerComponentParams: {
                    enableSorting: true,
                    showFilter: false,
                  },
                  headerName: setupTableHeaderName,
                  headerTooltip: setupTableHeaderName,
                  suppressMovable: true,
                  sortable: true,
                  flex: 1,
                },
                {
                  field: "email",
                  filter: true,
                  headerComponentParams: {
                    enableSorting: false,
                    showFilter: true,
                  },
                  headerName: setupTableHeaderEmailId,
                  headerTooltip: setupTableHeaderEmailId,
                  suppressMovable: true,
                  flex: 1,
                },
                {
                  field: "engagementTeamTypeID",
                  filter: true,
                  headerComponentParams: {
                    enableSorting: false,
                    showFilter: true,
                  },
                  headerName: setupTableHeaderTeam,
                  headerTooltip: setupTableHeaderTeam,
                  suppressMovable: true,
                  flex: 1,
                },
                {
                  field: "action",
                  cellRenderer: CustomDeleteComponent,
                  suppressMovable: true,
                  cellRendererParams: () => {
                    return {
                      onDeleteUserClick: onDeleteUserClick,
                    };
                  },
                  headerName: "",
                  flex: 0.2,
                  minWidth: 30,
                },
              ]}
              draggable={false}
              pagination={false}
              loading={teamListLoading}
            />
          </div>
        </div>
      </div>
      <AddUserModal
        isModalVisible={showAdduserModal}
        onClose={handleAddUserModalClose}
        onConfirm={handleAddUSerModalSave}
        userData={teamList}
      />
      <UserDeleteModal
        isModalVisible={showDeleteModal}
        onClose={handleDeleteClose}
        onConfirm={handleDeleteConfirm}
      />
      {lockedData === 2 && lockedBy === user.username && (
        <LockerModal
          isOpen={isLockerModalOpen}
          onClose={() => setLockerModalOpen(false)}
        />
      )}
    </div>
  );
}
