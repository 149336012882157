import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEmissionAbatementData } from "../../../../store/slices/projectSlice";
import {
  forecastChartDataLoadingMessage,
  forecastChartYAxisLabel,
  projectResultsEmissionAbatementChartAE,
  projectResultsEmissionAbatementChartFAP,
  projectResultsEmissionAbatementChartFBP,
  projectResultsEmissionAbatementChartRE,
  projectResultsEmissionAbatementChartYears,
} from "../../../../util/constants";
import { createTheme, styled, ThemeProvider } from "@mui/material";
import { LoadingIndicator } from "../../../LoadingIndicator/LoadingIndicator";
import { EmptyChart } from "../../../CommonUIComponents/EmptyChart/EmptyChart";
import {
  AreaPlot,
  axisClasses,
  ChartsAxisHighlight,
  ChartsGrid,
  ChartsLegend,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  MarkPlot,
  ResponsiveChartContainer,
  useDrawingArea,
  useXScale,
  useYScale,
} from "@mui/x-charts";
import "./EmissionsAbatementChart.scss";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const LegendText = styled("text")(({ theme }) => ({
  stroke: "none",
  fill: "#DEDEE2",
  shapeRendering: "crispEdges",
  textAnchor: "middle",
  dominantBaseline: "middle",
}));

const LegendSvg = styled("svg")(({ theme }) => ({
  stroke: "none",
  width: "40",
  height: "41",
  viewBox: "0 0 40 41",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
}));

function CustomLegend() {
  const yScale = useYScale();
  const { left, width, height } = useDrawingArea();

  const [bottom, top] = yScale.range();

  return (
    <g>
      <LegendSvg x={(left + width) / 2 - 270} y={top + height + 76}>
        <rect y="0.386719" width="15" height="7" fill="#188CE5" />
      </LegendSvg>
      <LegendText x={(left + width) / 2 - 170} y={top + height + 82}>
        {projectResultsEmissionAbatementChartFBP}
      </LegendText>
      <LegendSvg x={(left + width) / 2 - 70} y={top + height + 76}>
        <rect y="0.386719" width="10" height="10" rx="10" fill="#85B9FDBF" />
      </LegendSvg>
      <LegendText x={(left + width) / 2 + 10} y={top + height + 83}>
        {projectResultsEmissionAbatementChartAE}
      </LegendText>
      <LegendSvg x={(left + width) / 2 + 90} y={top + height + 78}>
        <rect y="0.386719" width="15" height="7" fill="#8965CC" />
      </LegendSvg>
      <LegendText x={(left + width) / 2 + 190} y={top + height + 83}>
        {projectResultsEmissionAbatementChartFAP}
      </LegendText>
      <LegendSvg x={(left + width) / 2 + 285} y={top + height + 76}>
        <rect y="0.386719" width="10" height="10" rx="10" fill="#C2ABE3BF" />
      </LegendSvg>
      <LegendText x={(left + width) / 2 + 370} y={top + height + 83}>
        {projectResultsEmissionAbatementChartRE}
      </LegendText>
    </g>
  );
}

export function EmissionsAbatementChart() {
  const dispatch = useDispatch();
  const engagement = useSelector((state) => state.engagement.engagement);
  const project = useSelector((state) => state.project.project);
  const resultsEmissionAbatementChartData = useSelector(
    (state) => state.project.resultsEmissionAbatementChartData
  );
  const resultsEmissionAbatementChartDataLoading = useSelector(
    (state) => state.project.resultsEmissionAbatementChartDataLoading
  );

  const [series, setSeries] = useState([]);
  const [xAxis, setXAxis] = useState([
    {
      scaleType: "band",
      id: "x-axis-id",
      disableTicks: true,
      label: "Years",
      data: [],
    },
  ]);
  const [yAxis, setYAxis] = useState([
    {
      label: forecastChartYAxisLabel,
      min: 0,
      max: 1000,
      id: "y-axis-id",
    },
  ]);

  useEffect(() => {
    if (engagement?.id && project?.projectId) {
      const request = {
        engagementCode: engagement.engagementCode,
        projectId: project.projectId,
      };
      dispatch(getEmissionAbatementData(request));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, engagement?.id, project?.projectId]);

  useEffect(() => {
    if (engagement?.id) {
      let tempSeries = [];
      const minYear =
        resultsEmissionAbatementChartData &&
        resultsEmissionAbatementChartData.length > 0
          ? Math.min(
              ...resultsEmissionAbatementChartData?.map(
                (item) => item.activity_Year
              )
            )
          : 0;
      const maxYear =
        resultsEmissionAbatementChartData &&
        resultsEmissionAbatementChartData.length > 0
          ? Math.max(
              ...(resultsEmissionAbatementChartData &&
                resultsEmissionAbatementChartData?.map(
                  (item) => item.activity_Year
                ))
            )
          : 0;
      const sequence = getSequence(minYear, maxYear);

      if (
        resultsEmissionAbatementChartData &&
        resultsEmissionAbatementChartData.length !== 0
      ) {
        let m2EmData = [];
        let m3Data = [];

        if (minYear !== 0) {
          sequence.forEach((year) => {
            const item = resultsEmissionAbatementChartData.find(
              (item) => item.activity_Year === year
            );
            if (!item) {
              m2EmData.push(null);
              m3Data.push(null);
            } else {
              m2EmData.push(item.m2_EM);
              m3Data.push(item.m3);
            }
          });
        }

        tempSeries.push({
          type: "line",
          data: m3Data,
          label: projectResultsEmissionAbatementChartRE,
          showMark: true,
          color: "#C2ABE3BF",
          highlightScope: { highlighted: "item", faded: "global" },
        });
        tempSeries.push({
          type: "line",
          data: m3Data,
          label: projectResultsEmissionAbatementChartFAP,
          color: "#8965CC",
          area: true,
          highlightScope: { highlighted: "item", faded: "global" },
        });
        tempSeries.push({
          type: "line",
          data: m2EmData,
          showMark: true,
          label: projectResultsEmissionAbatementChartAE,
          valueFormatter: (v, { dataIndex }) =>
            v === null
              ? ""
              : Number(
                  (m2EmData[dataIndex] - m3Data[dataIndex]).toFixed(2)
                ).toLocaleString(),
          color: "#85B9FDBF",
          highlightScope: { highlighted: "item", faded: "global" },
        });
        tempSeries.push({
          type: "line",
          data: m2EmData,
          label: projectResultsEmissionAbatementChartFBP,
          color: "#188CE5",
          area: true,
          highlightScope: { highlighted: "item", faded: "global" },
        });
        setYAxis([
          {
            label: forecastChartYAxisLabel,
            min: resultsEmissionAbatementChartData.length === 0 ? 0 : undefined,
            max:
              resultsEmissionAbatementChartData.length === 0 ? 1000 : undefined,
            id: "y-axis-id",
          },
        ]);
      }

      setSeries(tempSeries);
      setXAxis([
        {
          scaleType: "point",
          id: "x-axis-id",
          disableTicks: true,
          label: projectResultsEmissionAbatementChartYears,
          data: minYear && maxYear ? sequence : [],
          valueFormatter: yearFormatter,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, resultsEmissionAbatementChartData]);

  const yearFormatter = (date) => date.toString();

  function getSequence(min, max) {
    const sequence = [];
    for (let i = min; i <= max; i++) {
      sequence.push(i);
    }
    return sequence;
  }

  return (
    <div className="emissions-abatement-chart-section">
      <div className="emissions-abatement-chart">
        <div
          className={
            resultsEmissionAbatementChartDataLoading
              ? "show-file-upload-status"
              : "hide-file-upload-status"
          }
        >
          <span>{forecastChartDataLoadingMessage}</span>
        </div>
        <LoadingIndicator
          show={resultsEmissionAbatementChartDataLoading}
          fullscreen={false}
          transparent
        >
          <ThemeProvider theme={darkTheme}>
            {series.length === 0 ? (
              <EmptyChart chartYAxisLabel={forecastChartYAxisLabel} />
            ) : (
              <ResponsiveChartContainer
                height={400}
                margin={{ top: 5, bottom: 100, left: 125, right: 15 }}
                series={series}
                xAxis={xAxis}
                yAxis={yAxis}
                sx={{
                  [`.${axisClasses.left} .${axisClasses.label}`]: {
                    transform: "translateX(-90px)",
                  },
                }}
              >
                <ChartsGrid horizontal />
                <ChartsXAxis axisId="x-axis-id" disableTicks />
                <ChartsYAxis axisId="y-axis-id" disableTicks disableLine />
                <ChartsLegend
                  direction="row"
                  position={{ vertical: "bottom", horizontal: "middle" }}
                  slots={{ legend: CustomLegend }}
                />
                <ChartsTooltip />

                <AreaPlot />
                <LinePlot />
                <MarkPlot />
                <ChartsAxisHighlight x="line" />
              </ResponsiveChartContainer>
            )}
          </ThemeProvider>
        </LoadingIndicator>
      </div>
    </div>
  );
}
