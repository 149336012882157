import React, { useEffect, useState } from "react";
import { EmissionModal } from "./AddEmissionModal";
import { useNavigate, useLocation } from "react-router-dom";
import "./AddForecastSpecification.scss";
import { Stepper } from "../../../Stepper/Stepper";
import { useSelector } from "react-redux";
import {
  SaveBtn,
  CancelBtn,
  addForecastSpecificationAddCustomBtn,
  addForecastSpecificationCustom,
  addForecastSpecificationAddOrgBtn,
  addForecastSpecificationOrg,
  addForecastSpecificationAddGeoBtn,
  addForecastSpecificationGeo,
  addForecastSpecificationAddEmissionBtn,
  emission,
  addForecastSpecificationEndYear,
  addForecastSpecificationStartYear,
  addForecastSpecificationPercentValue,
  addForecastSpecificationDataTypeOptions,
  addForecastSpecificationForecastDesc,
  addForecastSpecificationForecastName,
  addForecastSpecificationHeader,
  addForecastSpecificationDataType,
  addForecastSpecificationOrgRequired,
  addForecastSpecificationGeoRequired,
  addForecastSpecificationEmissionRequired,
  addForecastSpecificationStartYearLessThanFEY,
  addForecastSpecificationStartYearGreaterThanMRY,
  addForecastSpecificationStartYearRequired,
  addForecastSpecificationEndYearRequired,
  addForecastSpecificationPercentValueRequired,
  addForecastSpecificationDataTypeRequired,
  addForecastSpecificationForecastNameRequired,
  addForecastSpecificationEndYearGreaterThanMRY,
  addForecastSpecificationEndYearLessThanFEY,
  addForecastSpecificationEndYearLessThanStartYear,
  addForecastSpecificationStartYearGreaterThanEndYear,
  targetPercentageError,
  forecastDescriptionTooltip,
  forecastPercentageTooltip,
  forecastStartYearTooltip,
  forecastEndYeartooltip,
  emissionTooltip,
  forecastNameTooltip,
  forecastDataTypeTooltip,
  forecastGeographyTooltip,
  forecastOrgfTooltip,
  forecastCustomTooltip,
} from "../../../../util/constants";
import { Input } from "../../../Input/Input";
import { TextArea } from "../../../TextArea/TextArea";
import { Button } from "../../../Button/Button";
import { AddIcon, EmissionIcon } from "../../../../icons";
import { routes } from "../../../../routes/routes.constatns";
import { OrganizationModal } from "./AddOrganizationSpecsModal";
import { GeographicalModal } from "./AddGeoSpecModal";
import { CustomModal } from "./AddCustomModal";
import { Select } from "../../../Select/Select";
import { getHierarchyLevelData } from "../../../../store/services/engagement.service";
import {
  getForecastByGroupId,
  updateForecastSpecification,
  saveForecastSpecification,
  allSaveForecastSpecification,
  checkName,
  allUpdateForecast,
} from "../../../../store/services/forecast.service";
import { MotifErrorMessage } from "@ey-xd/motif-react";
import { LoadingIndicatorEmbed } from "../../../LoadingIndicator/LoadingIndicatorEmbed";
import { Tooltip } from "../../../CommonUIComponents/Tooltip/Tooltip";

export function AddForecastSpecification() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const steps = useSelector((state) => state.stepper.steps);
  const engagement = useSelector((state) => state.engagement.engagement);
  const stepIndex = useSelector((state) => state.engagement.stepIndex);
  const currentStep = useSelector((state) => state.engagement.currentStep);
  const user = useSelector((state) => state.user.user);
  const [isOpen, showModal] = useState(false);
  const [isOrgModalOpen, showOrgModal] = useState(false);
  const [isGeoModalOpen, showGeoModal] = useState(false);
  const [isCustomModalOpen, showCustomModal] = useState(false);
  const [isDataSaveInProgress, setIsDataSaveInProgress] = useState(false);
  const [forecastName, setForecastName] = useState("");
  const [forecastErrorName, setForecastErrorName] = useState("");
  const [forecastDescription, setForecastDescription] = useState("");
  const [percentageValue, setPercentage] = useState();
  const [forecastErrorPercent, setForecastErrorPercent] = useState("");
  const [startYear, setStartYear] = useState();
  const [forecastErrorStartYear, setForecastErrorStartYear] = useState("");
  const [endYear, setEndYear] = useState();
  const [forecastErrorEndYear, setForecastErrorEndYear] = useState("");
  const [scopeArray, setScopeArray] = useState([]);
  const [hierarchialData, setHierarchyData] = useState([]);
  const [dataTypefield, setDatatype] = useState();
  const [dataTypeError, setDataTypeError] = useState("");
  const [emissionModalData, setEmissionModalData] = useState([]);
  const [forecastErrorEmissionModal, setForecastErrorEmissionModal] =
    useState("");
  const [geoSpecModalData, setGeoSpecModalData] = useState([]);
  const [forecastErrorGeoModal, setForecastErrorGeoModal] = useState("");
  const [orgSpecModalData, setOrgSpecModalData] = useState([]);
  const [forecastErrorOrgModal, setForecastErrorOrgModal] = useState("");
  const [customModalData, setCustomModalData] = useState([]);
  const [forecastEmissionData, setForecastEmissionData] = useState({});
  const [forecastOrganizationalData, setForecastOrganizationalData] = useState(
    {}
  );
  const [forecastGeoData, setForecastGeoData] = useState({});
  const [forecastCustomData, setForecastCustomData] = useState({});
  const [forecastData, setForecastData] = useState({});
  const [selectedemissionFilters, setSelectedEmissionFilters] = useState(null);
  const [selectedGeoFilters, setSelectedGeoFilters] = useState(null);
  const [selectedorgFilters, setSelectedOrgFilters] = useState(null);
  const [selectedCustomFilters, setSelectedCustomFilters] = useState(null);
  const [dropdownloader, setDropdownLoader] = useState(false);
  const decimalNumberRegExp = /^-?\d{1,3}(\.\d{1,2})?$/;

  function handleCancelClick() {
    navigate(`/${routes.createNewEngagement}/${routes.addforecast}`, {
      state: {
        engagementId: engagement.id,
      },
      gestureEnabled: false,
    });
  }

  function validateForm() {
    let isValid = true;
    if (!forecastName) {
      setForecastErrorName(addForecastSpecificationForecastNameRequired);
      isValid = false;
    }

    if (!percentageValue) {
      setForecastErrorPercent(addForecastSpecificationPercentValueRequired);
      isValid = false;
    }

    if (
      percentageValue &&
      (!decimalNumberRegExp.test(percentageValue) ||
        percentageValue > 100 ||
        percentageValue < -100)
    ) {
      setForecastErrorPercent(targetPercentageError);
      isValid = false;
    }

    if (!dataTypefield) {
      setDataTypeError(addForecastSpecificationDataTypeRequired);
      isValid = false;
    }

    if (!startYear) {
      setForecastErrorStartYear(addForecastSpecificationStartYearRequired);
      isValid = false;
    }

    if (startYear && parseFloat(startYear) <= engagement.mostRecentYear) {
      setForecastErrorStartYear(
        addForecastSpecificationStartYearGreaterThanMRY
      );
      isValid = false;
    }

    if (startYear && parseFloat(startYear) >= engagement?.foreCastEndYear) {
      setForecastErrorStartYear(addForecastSpecificationStartYearLessThanFEY);
      isValid = false;
    }

    if (startYear && endYear && parseFloat(startYear) >= parseFloat(endYear)) {
      setForecastErrorStartYear(
        addForecastSpecificationStartYearGreaterThanEndYear
      );
      isValid = false;
    }

    if (!endYear) {
      setForecastErrorEndYear(addForecastSpecificationEndYearRequired);
      isValid = false;
    }

    if (endYear && parseFloat(endYear) <= engagement.mostRecentYear) {
      setForecastErrorEndYear(addForecastSpecificationEndYearGreaterThanMRY);
      isValid = false;
    }

    if (endYear && parseFloat(endYear) > engagement?.foreCastEndYear) {
      setForecastErrorEndYear(addForecastSpecificationEndYearLessThanFEY);
      isValid = false;
    }

    if (endYear && startYear && parseFloat(endYear) <= parseFloat(startYear)) {
      setForecastErrorEndYear(addForecastSpecificationEndYearLessThanStartYear);
      isValid = false;
    }

    if (emissionModalData?.length === 0) {
      setForecastErrorEmissionModal(addForecastSpecificationEmissionRequired);
      isValid = false;
    } else {
      if (emissionModalData?.scope?.length === 0) {
        setForecastErrorEmissionModal(addForecastSpecificationEmissionRequired);
        isValid = false;
      }
    }

    if (geoSpecModalData?.length === 0) {
      setForecastErrorGeoModal(addForecastSpecificationGeoRequired);
      isValid = false;
    } else {
      if (geoSpecModalData?.region_level1?.length === 0) {
        setForecastErrorGeoModal(addForecastSpecificationGeoRequired);
        isValid = false;
      }
    }

    if (orgSpecModalData?.length === 0) {
      setForecastErrorOrgModal(addForecastSpecificationOrgRequired);
      isValid = false;
    } else {
      if (orgSpecModalData?.entity_level1?.length === 0) {
        setForecastErrorOrgModal(addForecastSpecificationOrgRequired);
        isValid = false;
      }
    }

    return isValid;
  }
  async function findSmallest(arrays) {
    let smallest = arrays[0];
    let minlength = arrays[0]?.length;
    arrays.forEach((array) => {
      if (array.length < minlength) {
        smallest = array;
        minlength = array.length;
      }
    });
    let finalArray = Array.from(new Set(smallest.map(JSON.stringify))).map(
      JSON.parse
    );
    if (emissionModalData.selectAll === "subCategory1") {
      finalArray.forEach((record) => {
        record.category_level1 = "All";
        record.category_level2 = "All";
        record.category_level3 = "All";
      });
    } else if (emissionModalData.selectAll === "subCategory2") {
      finalArray.forEach((record) => {
        record.category_level2 = "All";
        record.category_level3 = "All";
      });
    } else if (emissionModalData.selectAll === "subCategory3") {
      finalArray.forEach((record) => {
        record.category_level3 = "All";
      });
    }
    if (geoSpecModalData.selectAll === "regionLevel1") {
      finalArray.forEach((record) => {
        record.region_level1 = "All";
        record.region_level2 = "All";
        record.region_level3 = "All";
      });
    } else if (geoSpecModalData.selectAll === "regionLevel2") {
      finalArray.forEach((record) => {
        record.region_level2 = "All";
        record.region_level3 = "All";
      });
    } else if (geoSpecModalData.selectAll === "regionLevel3") {
      finalArray.forEach((record) => {
        record.region_level3 = "All";
      });
    }
    if (orgSpecModalData.selectAll === "orgLevel1") {
      finalArray.forEach((record) => {
        record.entity_level1 = "All";
        record.entity_level2 = "All";
        record.entity_level3 = "All";
        record.entity_level4 = "All";
      });
    } else if (orgSpecModalData.selectAll === "orgLevel2") {
      finalArray.forEach((record) => {
        record.entity_level2 = "All";
        record.entity_level3 = "All";
        record.entity_level4 = "All";
      });
    } else if (orgSpecModalData.selectAll === "orgLevel3") {
      finalArray.forEach((record) => {
        record.region_level3 = "All";
        record.entity_level4 = "All";
      });
    } else if (orgSpecModalData.selectAll === "orgLevel4") {
      finalArray.forEach((record) => {
        record.entity_level4 = "All";
      });
    }
    if (customModalData.selectAll === "customLevel1") {
      finalArray.forEach((record) => {
        record.custom_specification1 = "All";
        record.custom_specification2 = "All";
        record.custom_specification3 = "All";
      });
    } else if (customModalData.selectAll === "customLevel2") {
      finalArray.forEach((record) => {
        record.custom_specification2 = "All";
        record.custom_specification3 = "All";
      });
    } else if (customModalData.selectAll === "customLevel3") {
      finalArray.forEach((record) => {
        record.custom_specification3 = "All";
      });
    }
    finalArray = Array.from(new Set(finalArray.map(JSON.stringify))).map(
      JSON.parse
    );
    return finalArray;
  }
  async function applyChanges() {
    if (!validateForm()) return;
    setIsDataSaveInProgress(true);
    let allpayload = [];
    let finalUniquerecords = [];
    if (customModalData?.allPayload?.length > 0) {
      let arrays = [
        geoSpecModalData.allPayload,
        orgSpecModalData.allPayload,
        customModalData.allPayload,
      ];
      let finalArray = await findSmallest(arrays);
      finalUniquerecords = finalArray;
    } else {
      let arrays = [geoSpecModalData.allPayload, orgSpecModalData.allPayload];
      let finalArray = await findSmallest(arrays);
      finalArray.forEach((record) => {
        record.custom_specification1 = "All";
        record.custom_specification2 = "All";
        record.custom_specification3 = "All";
      });
      finalUniquerecords = finalArray;
    }
    try {
      if (forecastData?.id) {
        finalUniquerecords.forEach((record) => {
          let request = {
            engagementID: engagement.id,
            clientname: engagement.clientName,
            name: forecastName,
            description: forecastDescription,
            region_level1: record.region_level1,
            region_level2: record.region_level2,
            region_level3: record.region_level3,
            entity_level1: record.entity_level1,
            entity_level2: record.entity_level2,
            entity_level3: record.entity_level3,
            entity_asset: record.entity_level4,
            scope: record.scope,
            category_level1: record.category_level1,
            category_level2: record.category_level2,
            category_level3: record.category_level3,
            custom_specification1: record.custom_specification1,
            custom_specification2: record.custom_specification2,
            custom_specification3: record.custom_specification3,
            data_type: dataTypefield,
            forecast_percentage: percentageValue,
            startYear: parseFloat(startYear),
            endYear: parseFloat(endYear),
            batchId: forecastData?.batchId ?? 0,
            batchGroupID: forecastData?.batchGroupID ?? 0,
            statusFlag: forecastData?.statusFlag ?? "",
            aD_Key: forecastData?.aD_Key ?? "",
            activeFlag: forecastData?.activeFlag ?? 1,
            id: forecastData?.batchGroupID ?? 0,
            modifiedby: forecastData?.modifiedby ?? user.username,
            modifieddate: forecastData?.modifieddate ?? new Date(),
            forecast_Status: "",
            forecastEndYear: engagement?.foreCastEndYear,
            record_status: "",
            forecastUIInputKey: "",
            isValidated: "Y",
          };
          allpayload.push(request);
        });
        await allUpdateForecast({ allpayload });
      } else {
        finalUniquerecords.forEach((record) => {
          let request = {
            engagementID: engagement.id,
            clientname: engagement.clientName,
            name: forecastName,
            description: forecastDescription,
            region_level1: record.region_level1,
            region_level2: record.region_level2,
            region_level3: record.region_level3,
            entity_level1: record.entity_level1,
            entity_level2: record.entity_level2,
            entity_level3: record.entity_level3,
            entity_asset: record.entity_level4,
            scope: record.scope,
            category_level1: record.category_level1,
            category_level2: record.category_level2,
            category_level3: record.category_level3,
            custom_specification1: record.custom_specification1,
            custom_specification2: record.custom_specification2,
            custom_specification3: record.custom_specification3,
            data_type: dataTypefield,
            forecast_percentage: percentageValue,
            startYear: parseFloat(startYear),
            endYear: parseFloat(endYear),
            createddate: forecastData?.createddate ?? new Date(),
            createdby: forecastData?.createdby ?? user.username,
            batchId: forecastData?.batchId ?? 0,
            batchGroupID: forecastData?.batchGroupID ?? 0,
            statusFlag: forecastData?.statusFlag ?? "",
            aD_Key: forecastData?.aD_Key ?? "",
            activeFlag: forecastData?.activeFlag ?? 1,
            id: forecastData?.batchGroupID ?? 0,
            forecast_Status: "",
            forecastEndYear: engagement?.foreCastEndYear,
            record_status: "",
            forecastUIInputKey: "",
            isValidated: "Y",
          };
          allpayload.push(request);
        });
        await allSaveForecastSpecification({ allpayload });
      }
    } catch (e) {
      console.error(e);
    }

    let payload = {
      engagementId: engagement.id,
      engagementCode: engagement.engagementCode,
      name: forecastName,
      clientname: engagement.clientName,
      description: forecastDescription,
      data_type: dataTypefield,
      forecast_percentage: percentageValue,
      startYear: parseFloat(startYear),
      endYear: parseFloat(endYear),
      createddate: forecastData?.createddate ?? new Date(),
      createdby: forecastData?.createdby ?? user.username,
      forecastEndYear: engagement?.foreCastEndYear,
      id: forecastData?.batchGroupID ?? 0,
      dropDownMetaData: [],
    };

    // Emission Data
    const selectedScope = emissionModalData.scope.map((x) => {
      return { columnType: "Scope", columnValue: x };
    });
    const selectedCatLevel1 = emissionModalData.category_level1.map((x) => {
      return { columnType: "Category_level1", columnValue: x };
    });
    const selectedCatLevel2 = emissionModalData.category_level2.map((x) => {
      return { columnType: "Category_level2", columnValue: x };
    });
    const selectedCatLevel3 = emissionModalData.category_level3.map((x) => {
      return { columnType: "Category_level3", columnValue: x };
    });

    //Geo Data
    const selectedRegionLevel1 = geoSpecModalData.region_level1.map((x) => {
      return { columnType: "Region_level1", columnValue: x };
    });
    const selectedRegionLevel2 = geoSpecModalData.region_level2.map((x) => {
      return { columnType: "Region_level2", columnValue: x };
    });
    const selectedRegionLevel3 = geoSpecModalData.region_level3.map((x) => {
      return { columnType: "Region_level3", columnValue: x };
    });

    //Org Data
    const selectedEntityLevel1 = orgSpecModalData.entity_level1.map((x) => {
      return { columnType: "Entity_level1", columnValue: x };
    });
    const selectedEntityLevel2 = orgSpecModalData.entity_level2.map((x) => {
      return { columnType: "Entity_level2", columnValue: x };
    });
    const selectedEntityLevel3 = orgSpecModalData.entity_level3.map((x) => {
      return { columnType: "Entity_level3", columnValue: x };
    });
    const selectedEntityLevel4 = orgSpecModalData.entity_level4.map((x) => {
      return { columnType: "Entity_level4", columnValue: x };
    });

    //Custom Data
    let selectedCustom1,
      selectedCustom2,
      selectedCustom3 = [];
    if (
      customModalData.custom_specification1 &&
      !customModalData.custom_specification1.includes("N/A")
    ) {
      selectedCustom1 = customModalData.custom_specification1.map((x) => {
        return { columnType: "Custom_specification1", columnValue: x };
      });
      selectedCustom2 = customModalData.custom_specification2.map((x) => {
        return { columnType: "Custom_specification2", columnValue: x };
      });
      selectedCustom3 = customModalData.custom_specification3.map((x) => {
        return { columnType: "Custom_specification3", columnValue: x };
      });
    } else {
      selectedCustom1 = [
        { columnType: "Custom_specification1", columnValue: "All" },
      ];
      selectedCustom2 = [
        { columnType: "Custom_specification2", columnValue: "All" },
      ];
      selectedCustom3 = [
        { columnType: "Custom_specification3", columnValue: "All" },
      ];
    }

    payload.dropDownMetaData = [
      ...selectedScope,
      ...selectedCatLevel1,
      ...selectedCatLevel2,
      ...selectedCatLevel3,
      ...selectedRegionLevel1,
      ...selectedRegionLevel2,
      ...selectedRegionLevel3,
      ...selectedEntityLevel1,
      ...selectedEntityLevel2,
      ...selectedEntityLevel3,
      ...selectedEntityLevel4,
      ...selectedCustom1,
      ...selectedCustom2,
      ...selectedCustom3,
    ];

    try {
      if (forecastData?.id) {
        await updateForecastSpecification({ payload });
      } else {
        await saveForecastSpecification({ payload });
      }
    } catch (e) {
      console.error(e);
    }

    setIsDataSaveInProgress(false);

    navigate(`/${routes.createNewEngagement}/${routes.addforecast}`, {
      state: {
        engagementId: engagement.id,
      },
      gestureEnabled: false,
    });
  }

  async function GetAllHierarchyData() {
    const request = {
      engagement: engagement.engagementCode,
      clientName: engagement.clientName,
    };

    const data = await getHierarchyLevelData({ request });
    setHierarchyData(data);
  }

  function handleDatatypeChange(event) {
    setDatatype(event);
    setDataTypeError("");
  }

  const handleEmissionModalClose = () => {
    showModal(false);
  };

  const handleEmissionModalConfirm = (data) => {
    showModal(false);
    setEmissionModalData(data);
    setSelectedEmissionFilters(data);
    setScopeArray(data.scope);
    setGeoSpecModalData([]);
    setOrgSpecModalData([]);
    setCustomModalData([]);
    setSelectedGeoFilters([]);
    setSelectedOrgFilters([]);
    setSelectedCustomFilters([]);
    setForecastEmissionData([]);
    setForecastGeoData([]);
    setForecastOrganizationalData([]);
    setForecastCustomData([]);
    if (data.category_level1.length > 0) {
      setForecastErrorEmissionModal("");
    }
  };

  const handleEditData = (data) => {
    setEmissionModalData(data);
    setScopeArray(data.scope);
  };

  const handleGeoModalClose = () => {
    showGeoModal(false);
  };

  const handleGeoModalConfirm = (data) => {
    showGeoModal(false);
    setGeoSpecModalData(data);
    setSelectedGeoFilters(data);
    setForecastGeoData(data);
    if (data.region_level1.length > 0) {
      setForecastErrorGeoModal("");
    }
  };

  const handleGeoEditData = (data) => {
    setGeoSpecModalData(data);
  };
  const handleOrgModalClose = () => {
    showOrgModal(false);
  };

  const handleOrgModalConfirm = (data) => {
    showOrgModal(false);
    setOrgSpecModalData(data);
    setSelectedOrgFilters(data);
    setForecastOrganizationalData(data);
    if (data.entity_level1.length > 0) {
      setForecastErrorOrgModal("");
    }
  };

  const handleOrgEditData = (data) => {
    setOrgSpecModalData(data);
  };

  const handleCustomModalClose = (data) => {
    showCustomModal(false);
  };
  const hanldeCustomEditData = (data) => {
    setCustomModalData(data);
  };

  const handleCustomModalConfirm = (data) => {
    showCustomModal(false);
    setCustomModalData(data);
    setSelectedCustomFilters(data);
    setForecastCustomData(data);
  };

  function openEmissionModal() {
    setForecastErrorEmissionModal("");
    showModal(true);
  }

  useEffect(() => {
    if (engagement?.id && state?.batchGroupId) {
      setDropdownLoader(true);
      getForecastDataByGroupId(engagement?.id, state?.batchGroupId);
    }
  }, [engagement?.id]);

  async function getForecastDataByGroupId(engId, groupId) {
    setDropdownLoader(true);
    const request = {
      engagementId: engId,
      batchGroupId: groupId,
    };
    const data = await getForecastByGroupId({
      request,
    });
    setForecastData(data.data[data.data.length - 1]);
    setForecastName(data.data[data.data.length - 1].name);
    setForecastDescription(data.data[data.data.length - 1].description);
    setDatatype(data.data[data.data.length - 1].data_type);
    setPercentage(data.data[data.data.length - 1].forecast_percentage);
    setStartYear(data.data[data.data.length - 1].startYear);
    setEndYear(data.data[data.data.length - 1].endYear);

    let scopeArray = [...new Set(data.data.map((element) => element.scope))];
    setScopeArray(scopeArray);

    let category_level1 = [
      ...new Set(data.data.map((element) => element.category_level1)),
    ];
    let category_level2 = [
      ...new Set(data.data.map((element) => element.category_level2)),
    ];
    let category_level3 = [
      ...new Set(data.data.map((element) => element.category_level3)),
    ];

    if (state.isValidated === "N") {
      setForecastEmissionData({
        scopeArray: [],
        category_level1: [],
        category_level2: [],
        category_level3: [],
      });
    } else {
      setForecastEmissionData({
        scopeArray: scopeArray,
        category_level1: category_level1,
        category_level2: category_level2,
        category_level3: category_level3,
      });
    }

    let entity_level1 = [
      ...new Set(data.data.map((element) => element.entity_level1)),
    ];
    let entity_level2 = [
      ...new Set(data.data.map((element) => element.entity_level2)),
    ];
    let entity_level3 = [
      ...new Set(data.data.map((element) => element.entity_level3)),
    ];
    let entity_level4 = [
      ...new Set(data.data.map((element) => element.entity_asset)),
    ];

    if (state.isValidated === "N") {
      setForecastOrganizationalData({
        entity_level1: [],
        entity_level2: [],
        entity_level3: [],
        entity_level4: [],
      });
    } else {
      setForecastOrganizationalData({
        entity_level1: entity_level1,
        entity_level2: entity_level2,
        entity_level3: entity_level3,
        entity_level4: entity_level4,
      });
    }

    let region_level1 = [
      ...new Set(data.data.map((element) => element.region_level1)),
    ];
    let region_level2 = [
      ...new Set(data.data.map((element) => element.region_level2)),
    ];
    let region_level3 = [
      ...new Set(data.data.map((element) => element.region_level3)),
    ];

    if (state.isValidated === "N") {
      setForecastGeoData({
        region_level1: [],
        region_level2: [],
        region_level3: [],
      });
    } else {
      setForecastGeoData({
        region_level1: region_level1,
        region_level2: region_level2,
        region_level3: region_level3,
      });
    }

    let custom_specification1 = [
      ...new Set(data.data.map((element) => element.custom_specification1)),
    ];
    let custom_specification2 = [
      ...new Set(data.data.map((element) => element.custom_specification2)),
    ];
    let custom_specification3 = [
      ...new Set(data.data.map((element) => element.custom_specification3)),
    ];
    if (state.isValidated === "N") {
      setForecastCustomData({
        custom_specification1: [],
        custom_specification2: [],
        custom_specification3: [],
      });
    } else {
      setForecastCustomData({
        custom_specification1: custom_specification1,
        custom_specification2: custom_specification2,
        custom_specification3: custom_specification3,
      });
    }

    setTimeout(() => {
      setDropdownLoader(false);
    }, 2000);
  }

  async function checkForecastName(value) {
    if (!value) return;

    const request = {
      name: value,
      type: "Forecast",
      engagementId: engagement.id,
    };
    const data = await checkName({ request });
    if (data.code === 1) {
      setForecastErrorName(data.status);
    } else {
      setForecastErrorName("");
    }
  }
  useEffect(() => {
    if (engagement?.id) {
      GetAllHierarchyData();
    }
  }, [engagement?.id]);

  useEffect(
    () => () => {
      showModal(false);
      showOrgModal(false);
      showGeoModal(false);
      showCustomModal(false);
      setIsDataSaveInProgress(false);
      setForecastName("");
      setForecastDescription("");
      setPercentage("");
      setStartYear("");
      setEndYear("");
      setScopeArray([]);
      setHierarchyData([]);
      setDatatype("");
      setEmissionModalData([]);
      setGeoSpecModalData([]);
      setOrgSpecModalData([]);
      setCustomModalData([]);
      setForecastEmissionData({});
      setForecastOrganizationalData({});
      setForecastGeoData({});
      setForecastCustomData({});
      setForecastData({});
      setForecastErrorName("");
      setForecastErrorPercent("");
      setForecastErrorStartYear("");
      setForecastErrorEndYear("");
      setDataTypeError("");
      setForecastErrorEmissionModal("");
      setForecastErrorGeoModal("");
      setForecastErrorOrgModal("");
    },
    []
  );

  return (
    <div className="add-forecast-specification">
      <div className="add-forecast-specification-eng-header">
        <div className="add-forecast-specification-eng-header-title">
          {steps[currentStep]?.stepHeading}
        </div>
        <div className="add-forecast-specification-eng-header-stepper">
          <Stepper
            steps={steps}
            currentStep={currentStep}
            stepIndex={stepIndex}
          />
        </div>
      </div>
      <div className="add-forecast-content">
        <div className="add-forecast-specification-header ">
          <span className="add-forecast-specification-header-main">
            {addForecastSpecificationHeader}
          </span>
        </div>
        <div className="add-forecast-specification-body">
          <div className="add-forecast-specification-content-body-row">
            <span className="add-forecast-specification-content-body-name-title decarb-input-error-label">
              {addForecastSpecificationForecastName}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="ghost"
                tooltipText={forecastNameTooltip}
              ></Tooltip>
            </span>
            <Input
              type={"text"}
              value={forecastName}
              onChange={(event) => {
                setForecastName(event.target.value);
                setForecastErrorName("");
              }}
              onBlur={(event) => {
                checkForecastName(event.target.value);
              }}
              errorMessage={forecastErrorName}
              isError={forecastErrorName !== ""}
              disableErrorPlaceholder={false}
              disabled={isDataSaveInProgress || forecastData?.id}
            ></Input>
          </div>
          <div className="add-forecast-specification-content-body-row">
            <span className="add-forecast-specification-content-body-desc-title">
              {addForecastSpecificationForecastDesc}
              <Tooltip
                trigger={<EmissionIcon />}
                variant="default"
                tooltipText={forecastDescriptionTooltip}
              ></Tooltip>
            </span>
            <div style={{ flex: 1 }}>
              <TextArea
                value={forecastDescription}
                onChange={(event) => {
                  setForecastDescription(event.target.value);
                }}
                disableErrorPlaceholder={false}
                disabled={isDataSaveInProgress}
              />
            </div>
          </div>
          <div className="add-forecast-specification-content-body-row">
            <span className="add-forecast-specification-content-body-name-title decarb-input-error-label">
              {addForecastSpecificationDataType}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="default"
                tooltipText={forecastDataTypeTooltip}
              ></Tooltip>
            </span>
            <Select
              value={dataTypefield}
              options={addForecastSpecificationDataTypeOptions}
              onChange={handleDatatypeChange}
              errorMessage={dataTypeError}
              isError={dataTypeError !== ""}
              disableErrorPlaceholder={false}
              disabled={isDataSaveInProgress}
            ></Select>
            <span className="add-forecast-specification-content-body-name-title decarb-input-error-label">
              {addForecastSpecificationPercentValue}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="default"
                tooltipText={forecastPercentageTooltip}
              ></Tooltip>
            </span>
            <Input
              type="decimal"
              maxLength={percentageValue?.includes("-") ? 6 : 5}
              value={percentageValue}
              allowNegative
              onChange={(event) => {
                setPercentage(event.target.value);
                setForecastErrorPercent("");

                if (!event.target.value) {
                  setForecastErrorPercent(
                    addForecastSpecificationPercentValueRequired
                  );
                }

                if (
                  event.target.value &&
                  (!decimalNumberRegExp.test(event.target.value) ||
                    event.target.value > 100 ||
                    event.target.value < -100)
                ) {
                  setForecastErrorPercent(targetPercentageError);
                }
              }}
              errorMessage={forecastErrorPercent}
              isError={forecastErrorPercent !== ""}
              disableErrorPlaceholder={false}
              disabled={isDataSaveInProgress}
            ></Input>
          </div>
          <div className="add-forecast-specification-content-body-row">
            <span className="add-forecast-specification-content-body-name-title decarb-input-error-label">
              {addForecastSpecificationStartYear}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="default"
                tooltipText={forecastStartYearTooltip}
              ></Tooltip>
            </span>
            <Input
              type="number"
              maxLength={4}
              value={startYear}
              onChange={(event) => {
                let year = event.target.value;
                setStartYear(year);
                setForecastErrorStartYear("");

                if (!year) {
                  setForecastErrorStartYear(
                    addForecastSpecificationStartYearRequired
                  );
                }

                if (year && parseFloat(year) <= engagement.mostRecentYear) {
                  setForecastErrorStartYear(
                    addForecastSpecificationStartYearGreaterThanMRY
                  );
                }

                if (year && parseFloat(year) >= engagement?.foreCastEndYear) {
                  setForecastErrorStartYear(
                    addForecastSpecificationStartYearLessThanFEY
                  );
                }

                if (
                  year &&
                  endYear &&
                  parseFloat(year) >= parseFloat(endYear)
                ) {
                  setForecastErrorStartYear(
                    addForecastSpecificationStartYearGreaterThanEndYear
                  );
                }
              }}
              errorMessage={forecastErrorStartYear}
              isError={forecastErrorStartYear !== ""}
              disableErrorPlaceholder={false}
              disabled={isDataSaveInProgress}
            ></Input>
            <span className="add-forecast-specification-content-body-name-title decarb-input-error-label">
              {addForecastSpecificationEndYear}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="default"
                tooltipText={forecastEndYeartooltip}
              ></Tooltip>
            </span>
            <Input
              type="number"
              maxLength={4}
              value={endYear}
              onChange={(event) => {
                let year = event.target.value;

                setEndYear(event.target.value);
                setForecastErrorEndYear("");

                if (!year) {
                  setForecastErrorEndYear(
                    addForecastSpecificationEndYearRequired
                  );
                }

                if (year && parseFloat(year) <= engagement.mostRecentYear) {
                  setForecastErrorEndYear(
                    addForecastSpecificationEndYearGreaterThanMRY
                  );
                }

                if (year && parseFloat(year) > engagement?.foreCastEndYear) {
                  setForecastErrorEndYear(
                    addForecastSpecificationEndYearLessThanFEY
                  );
                }

                if (
                  year &&
                  startYear &&
                  parseFloat(year) <= parseFloat(startYear)
                ) {
                  setForecastErrorEndYear(
                    addForecastSpecificationEndYearLessThanStartYear
                  );
                }
              }}
              errorMessage={forecastErrorEndYear}
              isError={forecastErrorEndYear !== ""}
              disableErrorPlaceholder={false}
              disabled={isDataSaveInProgress}
            ></Input>
          </div>
          <div className="add-forecast-specification-content-body-row">
            <span className="add-forecast-specification-content-body-name-title decarb-input-error-label">
              {emission}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="default"
                tooltipText={emissionTooltip}
              ></Tooltip>
            </span>
            <div className="add-forecast-specification-body-error-button">
              <LoadingIndicatorEmbed show={dropdownloader}>
                <Button
                  variant="primary-alt"
                  onClick={openEmissionModal}
                  disabled={
                    isDataSaveInProgress ||
                    (state?.batchGroupId && dropdownloader) ||
                    (state?.batchGroupID &&
                      forecastEmissionData.category_level1?.length === 0)
                  }
                  className={"add-forecast-specification-content-body-button "}
                >
                  <AddIcon />
                  {addForecastSpecificationAddEmissionBtn}
                  <p className="number">
                    {" "}
                    {emissionModalData.scope?.length > 0
                      ? emissionModalData.scope?.length
                      : forecastEmissionData?.scopeArray?.length ?? 0}
                  </p>
                </Button>
              </LoadingIndicatorEmbed>
              {forecastErrorEmissionModal ? (
                <MotifErrorMessage className="decarb-input-error-message">
                  {forecastErrorEmissionModal}
                </MotifErrorMessage>
              ) : (
                <div className="decarb-input-hide-error">No Error</div>
              )}
            </div>
            <span className="add-forecast-specification-content-body-name-title decarb-input-error-label">
              {addForecastSpecificationGeo}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="default"
                tooltipText={forecastGeographyTooltip}
              ></Tooltip>
            </span>
            <div className="add-forecast-specification-body-error-button">
              <LoadingIndicatorEmbed show={dropdownloader}>
                <Button
                  variant="primary-alt"
                  onClick={() => {
                    setForecastErrorGeoModal("");
                    showGeoModal(true);
                  }}
                  disabled={
                    isDataSaveInProgress ||
                    (state?.batchGroupId && dropdownloader) ||
                    (state?.batchGroupID &&
                      forecastGeoData.region_level1?.length === 0)
                  }
                  className={"add-forecast-specification-content-body-button "}
                >
                  <AddIcon />
                  {addForecastSpecificationAddGeoBtn}
                  <p className="number">
                    {geoSpecModalData.region_level1?.length > 0
                      ? geoSpecModalData.region_level1?.length
                      : forecastGeoData?.region_level1?.length ?? 0}
                  </p>
                </Button>
              </LoadingIndicatorEmbed>
              {forecastErrorGeoModal ? (
                <MotifErrorMessage className="decarb-input-error-message">
                  {forecastErrorGeoModal}
                </MotifErrorMessage>
              ) : (
                <div className="decarb-input-hide-error">No Error</div>
              )}
            </div>
          </div>
          <div className="add-forecast-specification-content-body-row">
            <span className="add-forecast-specification-content-body-name-title decarb-input-error-label">
              {addForecastSpecificationOrg}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="default"
                tooltipText={forecastOrgfTooltip}
              ></Tooltip>
            </span>
            <div className="add-forecast-specification-body-error-button">
              <LoadingIndicatorEmbed show={dropdownloader}>
                <Button
                  variant="primary-alt"
                  onClick={() => {
                    setForecastErrorOrgModal("");
                    showOrgModal(true);
                  }}
                  disabled={
                    isDataSaveInProgress ||
                    (state?.batchGroupId && dropdownloader) ||
                    (state?.batchGroupID &&
                      forecastOrganizationalData.entity_level1?.length === 0)
                  }
                  className={"add-forecast-specification-content-body-button "}
                >
                  <AddIcon />
                  {addForecastSpecificationAddOrgBtn}
                  <p className="number">
                    {" "}
                    {orgSpecModalData.entity_level1?.length > 0
                      ? orgSpecModalData.entity_level1?.length
                      : forecastOrganizationalData?.entity_level1?.length ?? 0}
                  </p>
                </Button>
              </LoadingIndicatorEmbed>
              {forecastErrorOrgModal ? (
                <MotifErrorMessage className="decarb-input-error-message">
                  {forecastErrorOrgModal}
                </MotifErrorMessage>
              ) : (
                <div className="decarb-input-hide-error">No Error</div>
              )}
            </div>
            <span className="add-forecast-specification-content-body-name-title decarb-input-error-label">
              {addForecastSpecificationCustom}
              <Tooltip
                trigger={<EmissionIcon />}
                variant="default"
                tooltipText={forecastCustomTooltip}
              ></Tooltip>
            </span>
            <div className="add-forecast-specification-body-error-button">
              <LoadingIndicatorEmbed show={dropdownloader}>
                <Button
                  variant="primary-alt"
                  onClick={() => {
                    showCustomModal(true);
                  }}
                  disabled={
                    isDataSaveInProgress ||
                    (state?.batchGroupId && dropdownloader)
                  }
                  className={"add-forecast-specification-content-body-button "}
                >
                  <AddIcon />
                  {addForecastSpecificationAddCustomBtn}
                  <p className="number">
                    {" "}
                    {customModalData.custom_specification1?.length > 0
                      ? customModalData.custom_specification1?.length
                      : forecastCustomData?.custom_specification1?.length ?? 0}
                  </p>
                </Button>
              </LoadingIndicatorEmbed>
              <div className="decarb-input-hide-error">No Error</div>
            </div>
          </div>
        </div>
      </div>

      <div className="add-forecast-specification-footer">
        <Button
          variant="secondary"
          onClick={handleCancelClick}
          disabled={isDataSaveInProgress}
        >
          {CancelBtn}
        </Button>
        <div className="new-engagement-footer-nav-buttons">
          <LoadingIndicatorEmbed show={isDataSaveInProgress}>
            <Button
              variant="primary"
              onClick={applyChanges}
              disabled={isDataSaveInProgress || forecastErrorName !== ""}
            >
              {SaveBtn}
            </Button>
          </LoadingIndicatorEmbed>
        </div>
      </div>

      <EmissionModal
        isModalVisible={isOpen}
        onClose={handleEmissionModalClose}
        hierarchyData={hierarchialData}
        emissionData={forecastEmissionData}
        onConfirm={handleEmissionModalConfirm}
        onEdit={handleEditData}
      />
      <OrganizationModal
        isModalVisible={isOrgModalOpen}
        onClose={handleOrgModalClose}
        onConfirm={handleOrgModalConfirm}
        scopeArray={scopeArray}
        hierarchyData={hierarchialData}
        forecastOrganizationalData={forecastOrganizationalData}
        selectedEmissionModalData={selectedemissionFilters}
        selectedOrgModalData={selectedorgFilters}
        selectedGeoData={selectedGeoFilters}
        onEdit={handleOrgEditData}
      />
      <GeographicalModal
        isModalVisible={isGeoModalOpen}
        onClose={handleGeoModalClose}
        scopeArray={scopeArray}
        hierarchyData={hierarchialData}
        forecastGeoData={forecastGeoData}
        onConfirm={handleGeoModalConfirm}
        selectedEmissionModalData={selectedemissionFilters}
        selectedOrgModalData={selectedorgFilters}
        selectedGeoData={selectedGeoFilters}
        onEdit={handleGeoEditData}
      />
      <CustomModal
        isModalVisible={isCustomModalOpen}
        onClose={handleCustomModalClose}
        onConfirm={handleCustomModalConfirm}
        scopeArray={scopeArray}
        hierarchyData={hierarchialData}
        forecastCustomData={forecastCustomData}
        selectedEmissionModalData={selectedemissionFilters}
        selectedOrgModalData={selectedorgFilters}
        selectedGeoData={selectedGeoFilters}
        selectedCustomData={selectedCustomFilters}
        onEdit={hanldeCustomEditData}
      />
    </div>
  );
}
