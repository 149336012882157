import { ENGAGEMENT_URL } from "./endpoints/engagement";
import { get, post, getListCrossRegion } from "./base.service";
import axios from "axios";

async function saveManageEmissions({ request }) {
  return await post(
    // ENGAGEMENT_URL.SaveUploadedFileDetail, request);
    `/WebApi/Invoke`,
    {
      ActionVerb: "POST",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.SaveUploadedFileDetail,
      QueryString: "",
      Data: request,
    }
  );
}

async function uploadExcelFile({ request }) {
  // return await post(`/WebApi/Invoke`, {
  //   ActionVerb: "POST",
  //   ControllerName: "Engagement",
  //   ActionName: ENGAGEMENT_URL.UploadExcelFile,
  //   QueryString: "",
  //   Data: request,
  // });
  return await axios
    .post(`${ENGAGEMENT_URL.UploadExcelFile}`, request, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      throw new Error("Error in response - " + res);
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

async function downloadFileFromBlod({ request }) {
  return await axios
    .get(
      `${ENGAGEMENT_URL.DownloadFileFromBlob}?fileName=${request.fileName}&moduleName=${request.moduleName}`,
      { responseType: "arraybuffer" }
    )
    .then((res) => {
      if (res.status === 200) {
        console.log(res.data);
        return res;
      }
      throw new Error("Error in response - " + res);
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}
async function uploadTextFile({ request }) {
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Engagement",
    ActionName: ENGAGEMENT_URL.UploadTextFile,
    QueryString:
      "FileName=" + request.FileName + `&` + "ModuleName=" + request.ModuleName,
    Data: "",
  });
}

async function updateEngagement({ request, url = "" }) {
  return await post(
    // `${url}${ENGAGEMENT_URL.UpdateEngagement}`, request);
    `/WebApi/Invoke`,
    {
      ActionVerb: "POST",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.UpdateEngagement,
      QueryString: "",
      Data: request,
    }
  );
}

async function getUploadedFiles({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.GetAllUploadedFiles}?EngagementID=${request.id}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.GetAllUploadedFiles,
      QueryString: "EngagementID=" + request.id,
      Data: "",
    }
  );
}

async function getEngagement({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.GetEngagement}?id=${request.id}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.GetEngagement,
      QueryString: "id=" + request.id,
      Data: "",
    }
  );
}

async function GetAllEngagements({ request }) {
  return await getListCrossRegion(
    `${ENGAGEMENT_URL.GetAllEngagements}`,
    request
  );
}

async function createEngagement({ request }) {
  return await post(
    // ENGAGEMENT_URL.CreateEngagement, request
    `/WebApi/Invoke`,
    {
      ActionVerb: "POST",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.CreateEngagement,
      QueryString: "",
      Data: request,
    }
  );
}

async function getAllProjects({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.GetAllProjects}?EngagementID=${request.id}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.GetAllProjects,
      QueryString: "EngagementID=" + request.id,
      Data: "",
    }
  );
}

async function updateProject({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.UpdateProject}`, request.data
    `/WebApi/Invoke`,
    {
      ActionVerb: "POST",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.UpdateProject,
      QueryString: "",
      Data: request.data,
    }
  );
}

async function getAllMasterSector() {
  return await post(
    // ENGAGEMENT_URL.GetAllMasterSector
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.GetAllMasterSector,
      QueryString: "",
      Data: "",
    }
  );
}

async function saveSector({ request }) {
  return await post(
    // ENGAGEMENT_URL.SaveSector, request
    `/WebApi/Invoke`,
    {
      ActionVerb: "POST",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.SaveSector,
      QueryString: "",
      Data: request,
    }
  );
}

async function getAllSector({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.GetAllsectorL}?EngagementId=${request.id}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.GetAllsectorL,
      QueryString: "EngagementId=" + request.id,
      Data: "",
    }
  );
}

async function getAllEngagementTeam({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.GetAllEngagementTeam}?EngagementId=${request.id}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.GetAllEngagementTeam,
      QueryString: "EngagementId=" + request.id,
      Data: "",
    }
  );
}

async function saveTeamMember({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.SaveEngagementTeam}`, request
    `/WebApi/Invoke`,
    {
      ActionVerb: "POST",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.SaveEngagementTeam,
      QueryString: "",
      Data: request,
    }
  );
}

async function getStepperSteps() {
  return await post(
    // process.env.REACT_APP_API_URL_REG_US + ENGAGEMENT_URL.GetStepperSteps
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.GetStepperSteps,
      QueryString: "",
      Data: "",
    }
  );
}

async function getAllSpecRecords({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.GetAllRecordsBySpecification}?Specification=${request.specification}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.GetAllRecordsBySpecification,
      QueryString: "Specification=" + request.specification,
      Data: "",
    }
  );
}

async function getUploadedFileProgress({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.GetUploadedFileProgress}?EngagementID=${request.engagementId}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.GetUploadedFileProgress,
      QueryString: "EngagementID=" + request.engagementId,
      Data: "",
    }
  );
}

async function getHierarchyLevelData({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.GetHierarchicalLevelData}?EnagagementName=${request.engagement}&ClientName=${request.clientName}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.GetHierarchicalLevelData,
      QueryString:
        "EnagagementName=" +
        request.engagement +
        `&` +
        "ClientName=" +
        request.clientName,
      Data: "",
    }
  );
}

async function getReplaceHierarchyData({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.GetHierarchicalLevelData}?EnagagementName=${request.engagement}&ClientName=${request.clientName}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "Project",
      ActionName: ENGAGEMENT_URL.GetReplaceHierarchies,
      QueryString:
        "EngagementName=" +
        request.engagementCode +
        `&` +
        "ProjectID=" +
        request.projectID,
      Data: "",
    }
  );
}

async function getFinancialParametersHrchyData({ request }) {
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "GET",
    ControllerName: "Engagement",
    ActionName: ENGAGEMENT_URL.GetFinancialParameters,
    QueryString: "EngagementName=" + request.engagementCode,
    Data: "",
  });
}

async function getTargetValueByScope({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.GetTargetValueByScope}?Scope=${request.scope}&Type=${request.type}&EngagementCode=${request.engagementCode}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.GetTargetValueByScope,
      QueryString:
        "Scope=" +
        request.scope +
        `&` +
        "Type=" +
        request.type +
        `&` +
        "EngagementCode=" +
        request.engagementCode,
      Data: "",
    }
  );
}

async function GetEmisionValueByScope({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.GetEmisionValueByScope}?Scope=${request.scope}&Type=${request.type}&EngagementCode=${request.engagementCode}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.GetEmisionValueByScope,
      QueryString:
        "Scope=" +
        request.scope +
        `&` +
        "Type=" +
        request.type +
        `&` +
        "EngagementCode=" +
        request.engagementCode,
      Data: "",
    }
  );
}

async function deleteEngagementTeamMember({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.DeleteEngagementTeamMember}?engagementId=${request.engagementId}&engagementTeamId=${request.engagementTeamMemberId}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "POST",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.DeleteEngagementTeamMember,
      QueryString:
        "engagementId=" +
        request.engagementId +
        `&` +
        "engagementTeamId=" +
        request.engagementTeamMemberId,
      Data: "",
    }
  );
}
async function checkIfEngagementCodeExist({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.CheckIfEngagementCodeExist}?engagementCode=${request.engCode}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "POST",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.CheckIfEngagementCodeExist,
      QueryString: "engagementCode=" + request.engCode,
      Data: "",
    }
  );
}

async function getHeadersbyModuleForValidation({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.GetHeadersbyModuleForValidation}?moduleName=${request.moduleName}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.GetHeadersbyModuleForValidation,
      QueryString: "moduleName=" + request.moduleName,
      Data: "",
    }
  );
}

async function updateForecastEndYear({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.UpdateForecastEndYear}?engagementId=${request.engagementId}&forecastEndYear=${request.forecastEndYear}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "POST",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.UpdateForecastEndYear,
      QueryString:
        "engagementId=" +
        request.engagementId +
        `&` +
        "forecastEndYear=" +
        request.forecastEndYear,
      Data: "",
    }
  );
}

async function updateSkipForecast({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.UpdateSkipForecast}?engagementId=${request.engagementId}&skipvalue=${request.skipvalue}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "POST",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.UpdateSkipForecast,
      QueryString:
        "engagementId=" +
        request.engagementId +
        `&` +
        "skipvalue=" +
        request.skipvalue,
      Data: "",
    }
  );
}

async function updateMostRecentEndYear({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.UpdateMostRecentYear}?engagementId=${request.engagementId}&MostRecentYear=${request.mostRecentYear}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "POST",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.UpdateMostRecentYear,
      QueryString:
        "engagementId=" +
        request.engagementId +
        `&` +
        "MostRecentYear=" +
        request.mostRecentYear,
      Data: "",
    }
  );
}

async function calculateEmissions({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.CalculateEmissions}?engagementId=${request.engagementId}&Scope=${request.scope}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.CalculateEmissions,
      QueryString:
        "engagementId=" + request.engagementId + `&` + "Scope=" + request.scope,
      Data: "",
    }
  );
}

async function getDataValidationModule1Data({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.GetDataValidationModule1Data}?&engagementCode=${request.engagementCode}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.GetDataValidationModule1Data,
      QueryString: "engagementCode=" + request.engagementCode,
      Data: "",
    }
  );
}

async function getDataValidationModule2Data({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.GetDataValidationModule2Data}?&engagementCode=${request.engagementCode}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.GetDataValidationModule2Data,
      QueryString: "engagementCode=" + request.engagementCode,
      Data: "",
    }
  );
}

async function resetM2Data({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.resetM2Data}?EngagementID=${request.engagementId}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "POST",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.resetM2Data,
      QueryString: "EngagementID=" + request.engagementId,
      Data: "",
    }
  );
}

async function getCopletedCountByEngagement({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.GetCopletedCountByEngagement}?Id=${request.engagementId}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.GetCopletedCountByEngagement,
      QueryString: "Id=" + request.engagementId,
      Data: "",
    }
  );
}

async function getM1FileUploadVersionHistory({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.GetM1FileUploadVersionHistory}?engagementID=${request.engagementId}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.GetM1FileUploadVersionHistory,
      QueryString: "engagementID=" + request.engagementId,
      Data: "",
    }
  );
}

async function getM2FileUploadVersionHistory({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.GetM2FileUploadVersionHistory}?engagementID=${request.engagementId}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.GetM2FileUploadVersionHistory,
      QueryString: "engagementID=" + request.engagementId,
      Data: "",
    }
  );
}

async function getConsentData({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.GetConsentFlag}?EmailId=${request.email}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.GetConsentFlag,
      QueryString: "EmailId=" + request.email,
      Data: "",
    }
  );
}

async function updateConsentData({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.UpdateConsentFlag}?userEmailId=${request}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "POST",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.UpdateConsentFlag,
      QueryString: "userEmailId=" + request,
      Data: "",
    }
  );
}

async function getRegions() {
  return await post(
    // `${process.env.REACT_APP_API_URL_REG_US}${ENGAGEMENT_URL.GetRegions}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "Engagement",
      ActionName: "GetRegions",
      QueryString: "",
      Data: "",
    }
  );
}

async function syncModulebyData({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.SyncModulebyData}?EngagementCode=${request.engCode}&ModuleName=${request.moduleName}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "POST",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.SyncModulebyData,
      QueryString:
        "EngagementCode=" +
        request.engCode +
        `&` +
        "ModuleName=" +
        request.moduleName,
      Data: "",
    }
  );
}

async function releaseAlllocks({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.ReleaseAlllocks}?userEmailId=${request.email}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "POST",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.ReleaseAlllocks,
      QueryString: "userEmailId=" + request.email,
      Data: "",
    }
  );
}

async function getAllFlags({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.GetAllFlags}?EngagementID=${request.engagementId}&tableName=${request.tableName}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "POST",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.GetAllFlags,
      QueryString:
        "EngagementID=" +
        request.engagementId +
        `&` +
        "tableName=" +
        request.tableName,
      Data: "",
    }
  );
}

async function getPowerBIToggle({ request }) {
  return await post(`/WebAPi/Invoke`, {
    ActionVerb: "GET",
    ControllerName: "Engagement",
    ActionName: ENGAGEMENT_URL.GetPowerBIToggles,
    QueryString: "module=ALL" + `&` + "engagementId=" + request.engagementId,
    Data: "",
  });
}

async function updatePowerBIToggle({ request }) {
  return await post(
    // `${ENGAGEMENT_URL.GetAllFlags}?EngagementID=${request.engagementId}&tableName=${request.tableName}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "POST",
      ControllerName: "Engagement",
      ActionName: ENGAGEMENT_URL.UpdatePowerBIToggle,
      QueryString:
        "module=" +
        request.module +
        `&` +
        "engagementId=" +
        request.engagementId +
        `&` +
        "flagValue=" +
        request.flagValue,
      Data: "",
    }
  );
}

async function getEngagementDetailsByUser({ request }) {
  return await post(`/WebAPi/Invoke`, {
    ActionVerb: "GET",
    ControllerName: "Engagement",
    ActionName: ENGAGEMENT_URL.GetEngagementDetailsbyUser,
    QueryString: "username=" + request.user,
    Data: "",
  });
}

export {
  saveManageEmissions,
  updateEngagement,
  getUploadedFiles,
  getEngagement,
  createEngagement,
  getAllProjects,
  updateProject,
  getAllMasterSector,
  saveSector,
  getAllSector,
  getAllEngagementTeam,
  saveTeamMember,
  GetAllEngagements,
  getStepperSteps,
  getAllSpecRecords,
  getUploadedFileProgress,
  getHierarchyLevelData,
  // getGMPData,
  getTargetValueByScope,
  GetEmisionValueByScope,
  deleteEngagementTeamMember,
  checkIfEngagementCodeExist,
  getHeadersbyModuleForValidation,
  updateForecastEndYear,
  updateSkipForecast,
  updateMostRecentEndYear,
  calculateEmissions,
  getDataValidationModule1Data,
  getDataValidationModule2Data,
  resetM2Data,
  getCopletedCountByEngagement,
  getM1FileUploadVersionHistory,
  getM2FileUploadVersionHistory,
  getConsentData,
  updateConsentData,
  getRegions,
  syncModulebyData,
  releaseAlllocks,
  getAllFlags,
  uploadExcelFile,
  uploadTextFile,
  getReplaceHierarchyData,
  getPowerBIToggle,
  updatePowerBIToggle,
  getEngagementDetailsByUser,
  getFinancialParametersHrchyData,
  downloadFileFromBlod,
};
