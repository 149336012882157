import React, { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DeleteLogo, EditIcon } from "../../../icons";
import { MotifIconButton } from "@ey-xd/motif-react";
import { routes } from "../../../routes/routes.constatns";
import { useSelector, useDispatch } from "react-redux";
import { AddTargetCustomDelete } from "./CustomDeleteModal";
import "./CustomEditComponent.scss";
import { deleteTarget } from "../../../store/services/forecast.service";
import { setTargetDeleted } from "../../../store/slices/forecastSlice";

export default memo(({ node, onDelete, isLocked }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const engagement = useSelector((state) => state.engagement.engagement);
  const [isModalVisible, setModal] = useState(false);

  const onClose = () => {
    setModal(false);
  };

  async function editRecord() {
    navigate(`${routes.addTargetSpecification}`, {
      state: {
        engagementId: engagement.id,
        batchGroupId: node.data.batchGroupId,
        isValidated: node.data.isValidated,
      },
      gestureEnabled: false,
    });
  }

  async function deleteRecord() {
    const request = {
      engagementId: engagement.id,
      batchGroupId: node.data.batchGroupId,
    };
    const response = await deleteTarget({ request });
    if (response.status === "Success") {
      onClose();
      dispatch(setTargetDeleted({ isDeleted: true }));
      onDelete();
    }
  }

  return (
    <div className="add-target-table-custom-edit">
      <MotifIconButton onClick={editRecord} disabled={isLocked}>
        <EditIcon />
      </MotifIconButton>
      <MotifIconButton
        onClick={() => {
          setModal(true);
        }}
        disabled={isLocked}
      >
        <DeleteLogo />
      </MotifIconButton>
      <AddTargetCustomDelete
        isModalVisible={isModalVisible}
        onClose={onClose}
        onDelete={deleteRecord}
        data={node.data}
      />
    </div>
  );
});
