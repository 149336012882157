import React from "react";
import "./CustomDeleteModal.scss";
import { WarningIcon } from "../../../icons";
import {
  CancelBtn,
  DeleteBtn,
  deleteMessage,
  deleteMessageLine2,
  deleteMessageLine3,
  deleteTargetInfo,
  removeTarget,
} from "../../../util/constants";
import { Button } from "../../Button/Button";
import { Modal } from "../../Modal/Modal";

export function AddTargetCustomDelete({
  isModalVisible,
  onClose,
  onDelete,
  data,
}) {
  return (
    <Modal
      show={isModalVisible}
      onClose={onClose}
      header={
        <div className="file-version-history-modal-header">
          <div className="motif-fab">
            <WarningIcon />
          </div>
          <span>{removeTarget}</span>
        </div>
      }
      body={
        <>
          <span>{deleteTargetInfo}</span>
        </>
      }
      className={"add-forecast-delete-modal"}
      footer={
        <div className="custom-delete-footer">
          <Button variant="secondary" onClick={onClose}>
            {CancelBtn}
          </Button>
          <Button variant="primary" onClick={onDelete}>
            {DeleteBtn}
          </Button>
        </div>
      }
    />
  );
}
