import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Table } from "../Table/Table";
import "./FinancialsTable.scss";
import CustomActionsRenderer from "./customActionRenderer";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../Button/Button";
import { ConfirmationModal } from "../CommonUIComponents/ConfirmationModal/ConfimationModal";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import { nextStep } from "../../store/slices/engagementSlice";
import {
  deleteRuleModalHeader,
  deleteRuleModalBody,
  deleteRuleModalBodyDesc,
  calculate,
  calculateTooltip,
} from "../../util/constants";
import {
  getColumnValueFlags,
  SetProjectStatustoFinalize,
} from "../../store/services/project.service";
import {
  getGMPRulesMapData,
  deleteGmpRule,
  applyGMPRules,
} from "../../store/services/gmp.service";
import {
  setrulesMapData,
  setIsVariableApply,
} from "../../store/slices/gmpSlice";
import { LoadingIndicatorEmbed } from "../LoadingIndicator/LoadingIndicatorEmbed";
import { routes } from "../../routes/routes.constatns";
import { Tooltip } from "../CommonUIComponents/Tooltip/Tooltip";
import { EmissionIcon } from "../../icons";
import { setProjectStatus } from "../../store/slices/projectSlice";

export function FinancialsTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const steps = useSelector((state) => state.stepper.steps);
  const selectedRegion = useSelector(
    (state) => state.engagement.selectedRegion
  );
  const gmp = useSelector((state) => state.gmp.gmp);
  const engagement = useSelector((state) => state.engagement.engagement);
  const stepIndex = useSelector((state) => state.engagement.stepIndex);
  const project = useSelector((state) => state.project.project);
  const [tableData, setTableData] = useState([]);
  const [selectedRowData, setRuleData] = useState([]);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [resetCompleted, setresetCompleted] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [filterValidateRows, setFilterValidateRows] = useState([]);
  const lockingData = useSelector((state) => state.project?.lockingData);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const user = useSelector((state) => state.user.user);
  let refreshIntervalId = undefined;
  let refreshIntervalCount = 0;

  // screen refresh once financial falg is completed
  useEffect(() => {
    if (resetCompleted) {
      screenRefreshafterApply();
    }
  }, [resetCompleted]);

  useEffect(() => {
    if (gmp?.rulesMapData) {
      setTableData(gmp.rulesMapData);
      checkIsValidateFlag(gmp.rulesMapData);
    }
  }, [gmp?.rulesMapData]);

  const checkIsValidateFlag = (data) => {
    let filterArray = data.filter(
      (element) => element?.isValidated?.toUpperCase() === "N"
    );
    setFilterValidateRows(filterArray);
  };
  // Refreshingthe screen after reset flag check is commplete
  const screenRefreshafterApply = async () => {
    getRulesMapData();
    setShowLoader(false);
    refreshIntervalCount = 0;
  };

  // Onclick of delete icon
  async function onDeleteClick(data) {
    setRuleData(data);
    setDeleteModalVisible(true);
  }

  // On confirmation of deleteion
  async function onDeleteProjectModalConfirm() {
    setDeleteLoader(true);
    setDeleteModalVisible(false);
    const request = {
      parametername: selectedRowData?.parameter_name,
    };
    const response = await deleteGmpRule({ request });
    dispatch(setIsVariableApply({ isVariableApply: false }));
    getRulesMapData();
  }

  // Get GMP rules data to refresh table
  const getRulesMapData = async () => {
    const request = {
      projectId: project.projectId,
    };
    const data = await getGMPRulesMapData({ request });
    dispatch(setrulesMapData({ rulesMapData: data }));
    setDeleteLoader(false);
  };

  const onDeleteProjectModalClose = () => {
    setDeleteModalVisible(false);
  };

  const applyVariables = async () => {
    // setVariableApply(true);
    dispatch(setIsVariableApply({ isVariableApply: true }));
    setShowLoader(true);
    const request = {
      engCode: engagement.engagementCode,
      projectId: project.projectId,
    };
    const data = await applyGMPRules({ request });
    console.error(data);
    try {
      refreshIntervalId = window.setInterval(checkFlags, 2000);
    } catch (error) {
      window.clearInterval(refreshIntervalId);
      refreshIntervalId = undefined;
      setShowLoader(false);
    }
  };

  // Check the  Financial calulation flag
  const checkFlags = async () => {
    refreshIntervalCount++;
    if (refreshIntervalCount > 30) {
      window.clearInterval(refreshIntervalId);
      refreshIntervalId = undefined;
      setShowLoader(false);
      refreshIntervalCount = 0;
    }
    const flagsPayload = {
      columnName: "ApplyGMP",
      tableName: "projectrule",
      id: project.projectId,
    };
    const response = await getColumnValueFlags({ flagsPayload });
    if (response.columnValue === "3") {
      if (refreshIntervalId) {
        window.clearInterval(refreshIntervalId);
        refreshIntervalId = undefined;
      }
      setresetCompleted(true);
      setShowLoader(false);
      finalise();
      dispatch(setProjectStatus({ status: 5 }));
    }
  };

  const finalise = async () => {
    let newEngagement = engagement;
    const request = {
      projectId: project.projectId,
    };
    let data = await SetProjectStatustoFinalize({ request });
  };
  return (
    <div className="financials-table">
      <div className="financials-table-title">
        <span>Parameters selected for the project</span>
      </div>
      <LoadingIndicatorEmbed
        show={deleteLoader}
        className={"decarb-loading-indicator-transparent"}
      >
        <Table
          rowData={tableData}
          colDefs={[
            {
              headerName: "Parameter name",
              field: "parameter_name",
              headerTooltip: "Parameter name",
              width: 400,
            },
            // {
            //   headerName: "Rule name",
            //   field: "projectRuleName",
            //   headerTooltip: "Rule name",
            // },
            {
              headerName: "Parameter type",
              field: "gmP_data_type",
              headerTooltip: "Parameter type",
              width: 250,
            },
            {
              headerName: "Dependent or independent",
              field: "variable_type",
              headerTooltip: "Dependent or independent",
              width: 280,
            },
            {
              cellRenderer: CustomActionsRenderer,
              suppressMovable: true,
              cellRendererParams: () => {
                return {
                  onDeleteClick: onDeleteClick,
                };
              },
              field: "actions",
              headerName: "",
              flex: 1,
              width: 150,
              suppressMovable: true,
            },
          ]}
          draggable={false}
          rowClassRules={{
            "gmp-link-table-pending-validation": (params) => {
              return params?.data?.isValidated === "N";
            },
          }}
        />
      </LoadingIndicatorEmbed>
      <ConfirmationModal
        isModalVisible={isDeleteModalVisible}
        onClose={onDeleteProjectModalClose}
        onConfirm={onDeleteProjectModalConfirm}
        modalHeaderText={deleteRuleModalHeader}
        modalBodyText={deleteRuleModalBody}
      />
      <div className="secondary-btn-apply">
        <LoadingIndicatorEmbed show={showLoader}>
          <Button
            variant="primary-alt"
            disabled={
              gmp?.rulesMapData?.length === 0 ||
              filterValidateRows.length > 0 ||
              gmp?.isVariableApply === true ||
              (lockingData?.data?.lockStatus === 1 &&
                lockingData?.data?.lockedBy !== user.username)
            }
            onClick={applyVariables}
          >
            {calculate}
          </Button>
        </LoadingIndicatorEmbed>
        <span className="new-label">
          &nbsp;
          <Tooltip
            trigger={<EmissionIcon />}
            variant="ghost"
            tooltipText={calculateTooltip}
          ></Tooltip>
        </span>
      </div>
    </div>
  );
}
