import React, { useEffect, useState } from "react";
import { Modal } from "../../Modal/Modal";
import { Table } from "../../Table/Table";
import { BiglogLogo } from "../../../icons";
import {
  CancelBtn,
  changeLogDescription,
  changeLogReplaceTableColDefs,
  changeLogTableColDefs,
  deleteLastRule,
  emissionsChangeLog,
} from "../../../util/constants";
import "../Emissions.scss";
import { Button } from "../../Button/Button";
import {
  deleteLastAppliedRule,
  getReduceDefautViewData,
  getSaveProjectRule,
  getColumnValueFlags,
  recreateProjectM3,
} from "../../../store/services/project.service";
import { useSelector, useDispatch } from "react-redux";
import {
  setDisplayDataChangelog,
  setEmissionChangeLogTableData,
  setReduceDefautViewData,
  setSelectedRules,
  setSimulateFlag,
  setGraphSimulateFlag,
  setChangeLogApplied,
  setM3Step2Loader,
} from "../../../store/slices/projectSlice";
import { LoadingIndicatorEmbed } from "../../LoadingIndicator/LoadingIndicatorEmbed";

function ModalHeader() {
  return (
    <div className="emissions-change-log-header">
      <BiglogLogo />
      <span className="emissions-change-log-header-title">
        {emissionsChangeLog}
      </span>
      <span className="emissions-change-log-header-description">
        {changeLogDescription}
      </span>
    </div>
  );
}
function ModalBody({ loader }) {
  const tableData = useSelector((state) => state.project.changeLogPayloadData);
  const dispatch = useDispatch();
  const projectType = useSelector((state) => state.project.project.projectType);
  const [tableHeaders, setTableHeaders] = useState([]);

  // Fetching headers based on Project type
  useEffect(() => {
    fetchHeaders();
  }, []);

  // Fetching projectIDs of all the rules
  useEffect(() => {
    let selectRules = [
      ...new Set(tableData.map((element) => element.projectRuleID)),
    ];
    dispatch(setSelectedRules({ selectedRules: selectRules }));
  }, [tableData]);

  // Function toget the Headers
  const fetchHeaders = () => {
    if (projectType === 2) {
      setTableHeaders(changeLogReplaceTableColDefs);
    } else {
      setTableHeaders(changeLogTableColDefs);
    }
  };

  return (
    <div className="emissions-change-log-content">
      <Table
        colDefs={tableHeaders}
        rowData={tableData}
        rowClassRules={{
          "change-log-table-pending-validation": (params) => {
            return params.data.isValidated === "N";
          },
        }}
        loading={loader}
        showVerticalScroll={false}
      ></Table>
    </div>
  );
}
function ModalFooter({ onApply, reloadTable }) {
  const project = useSelector((state) => state.project.project);
  const dispatch = useDispatch();
  const filtersPayload = useSelector(
    (state) => state.project.appliedFilterPayload
  );
  const selectedRules = useSelector((state) => state.project.selectedRules);
  const engagement = useSelector((state) => state.engagement.engagement);
  const [showLoader, setLoader] = useState(false);
  const [recreateComplete, setRecreateComplete] = useState(false);
  const lockingData = useSelector((state) => state.project?.lockingData);
  const user = useSelector((state) => state.user.user);
  const tableData = useSelector((state) => state.project.changeLogPayloadData);
  let refreshIntervalId = undefined;
  let refreshIntervalCount = 0;

  // On recreate flag check complete
  useEffect(() => {
    if (recreateComplete) {
      refreshScreenafterRecreate();
    }
  }, [recreateComplete]);

  // Screen refresh on Recreate flag check complete
  const refreshScreenafterRecreate = async () => {
    const request = filtersPayload;
    const response = await getReduceDefautViewData({ request });
    dispatch(setReduceDefautViewData({ defaultViewData: response }));
    // dispatch(setM3Step2Loader({ step2GraphTableLoader: false }));
    dispatch(setSimulateFlag({ isSimulate: false }));
    dispatch(setGraphSimulateFlag({ isGraphSimulate: false }));
    // reloadTable();
    setRecreateComplete(false);
    setLoader(false);
  };

  // On recareta button click
  const recreate = async () => {
    setLoader(true);
    let request = {
      projectId: project.projectId,
    };
    const data = await deleteLastAppliedRule({ request });
    request = {
      EngagementCode: engagement.engagementCode,
      ProjectID: project.projectId,
      projectRuleID: selectedRules,
    };
    // dispatch(setM3Step2Loader({ step2GraphTableLoader: true }));
    const response = await recreateProjectM3({ request });
    try {
      refreshIntervalId = window.setInterval(checkFlags, 2000);
    } catch (error) {
      window.clearInterval(refreshIntervalId);
      refreshIntervalId = undefined;
      setLoader(false);
    }
    reloadTable();
    // setLoader(false);
  };

  const checkFlags = async () => {
    refreshIntervalCount++;
    if (refreshIntervalCount > 30) {
      window.clearInterval(refreshIntervalId);
      refreshIntervalId = undefined;
      refreshIntervalCount = 0;
    }
    const flagsPayload = {
      columnName: "Recreate",
      tableName: "Project",
      id: project.projectId,
    };
    const response = await getColumnValueFlags({ flagsPayload });
    if (response.columnValue === "3") {
      if (refreshIntervalId) {
        window.clearInterval(refreshIntervalId);
        refreshIntervalId = undefined;
      }
      setRecreateComplete(true);
    }
  };

  return (
    <div className="emissions-change-log-footer">
      <Button onClick={() => onApply()} variant="secondary">
        {CancelBtn}
      </Button>
      <LoadingIndicatorEmbed show={showLoader}>
        <Button
          onClick={() => recreate()}
          variant="primary"
          disabled={
            (lockingData?.data?.lockStatus === 1 &&
              lockingData?.data?.lockedBy !== user.username &&
              project.projectId) ||
            tableData.length === 0
          }
        >
          {deleteLastRule}
        </Button>
      </LoadingIndicatorEmbed>
    </div>
  );
}
export function ChangeLog({ isChangeLogOpen, onClose }) {
  const project = useSelector((state) => state.project.project);
  const [showLoader, setloader] = useState(false);
  const dispatch = useDispatch();

  // Load table data on modal open
  useEffect(() => {
    if (isChangeLogOpen) {
      setloader(true);
      loadTableData();
      dispatch(setChangeLogApplied({ isApplied: false }));
    }
  }, [isChangeLogOpen]);

  const loadTableData = async () => {
    let request = {
      projectID: project.projectId,
    };
    const data = await getSaveProjectRule({ request });
    dispatch(setEmissionChangeLogTableData({ changeLogTableData: data }));
    setTimeout(() => {
      setloader(false);
    }, 1000);
  };
  return (
    <div className="emissions-change-log">
      <Modal
        show={isChangeLogOpen}
        onClose={onClose}
        header={<ModalHeader></ModalHeader>}
        body={<ModalBody loader={showLoader}></ModalBody>}
        footer={
          <ModalFooter
            onApply={onClose}
            reloadTable={loadTableData}
          ></ModalFooter>
        }
        className={"emissions-change-log-modal"}
      />
    </div>
  );
}
