import React, { useEffect, useState } from "react";
import { Modal } from "../../Modal/Modal";
import { LoadingIndicatorEmbed } from "../../LoadingIndicator/LoadingIndicatorEmbed";
import "../Emissions.scss";
import { Select } from "../../Select/Select";
import { Button } from "../../Button/Button";
import {
  ApplyBtn,
  cat1,
  cat2,
  cat3,
  createNewCategory,
  createNewEntity,
  createNewRegion,
  ent1,
  ent2,
  ent3,
  ent4,
  extendScopes,
  newHierarchyWarningMessage,
  newTrgtHrchy,
  reg1,
  reg2,
  reg3,
  reset,
  scope,
  searchCategory,
  searchNewEntity,
  searchNewRegion,
} from "../../../util/constants";
import { Tooltip } from "../../CommonUIComponents/Tooltip/Tooltip";
import { EmissionIcon } from "../../../icons";
import { Input } from "../../Input/Input";
import { useDispatch, useSelector } from "react-redux";
import { setExtendNewHierarchy } from "../../../store/slices/projectSlice";
import MultiSelectCreatableOption from "../../CommonUIComponents/MultiSelectCreatableOption";

function ModalBody({ onApply, hierarchy }) {
  const [scopeSelected, setScope] = useState("");
  const [category1, setCategory1] = useState("");
  const [category2, setCategory2] = useState("");
  const [category3, setCategory3] = useState("");
  const [region1, setRegion1] = useState("");
  const [region2, setRegion2] = useState("");
  const [region3, setRegion3] = useState("");
  const [entitylevel1, setEntity1] = useState("");
  const [entitylevel2, setEntity2] = useState("");
  const [entitylevel3, setEntity3] = useState("");
  const [entitylevel4, setEntity4] = useState("");
  const hierarchialData = useSelector((state) => state.project.hierarchialData);
  const [category1Options, setCategory1Options] = useState([]);
  const [category2Options, setCategory2Options] = useState([]);
  const [category3Options, setCategory3Options] = useState([]);
  const [region1Options, setregion1Options] = useState([]);
  const [region2Options, setregion2Options] = useState([]);
  const [region3Options, setregion3Options] = useState([]);
  const [entity1Options, setEntity1Options] = useState([]);
  const [entity2Options, setEntity2Options] = useState([]);
  const [entity3Options, setEntity3Options] = useState([]);
  const [entity4Options, setEntity4Options] = useState([]);
  const [disableApply, setDisableApply] = useState(true);
  const [showError, setErrorMessage] = useState(false);
  const [resetDisable, setResetDisable] = useState(true);
  const selectedAllFilters = useSelector(
    (state) => state.project.selectedUIAllFilters
  );
  const newExtendhierarchy = useSelector(
    (state) => state.project.extendNewHierarchy
  );
  const [creatablecat1, setCreatableCat1] = useState(true);
  const [creatablecat2, setCreatableCat2] = useState(true);
  const [creatablecat3, setCreatableCat3] = useState(true);
  const [creatablereg1, setcreatableReg1] = useState(true);
  const [creatableReg2, setcreatableReg2] = useState(true);
  const [creatableReg3, setcreatableReg3] = useState(true);
  const [creatableEntity1, setcreatableEntity1] = useState(true);
  const [creatableEntity2, setcreatableEntity2] = useState(true);
  const [creatableEntity3, setcreatableEntity3] = useState(true);
  const [creatableEntity4, setcreatableEntity4] = useState(true);
  const [resetOptions, setReset] = useState(false);
  // const []
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(newExtendhierarchy);
    let data = [...hierarchialData];
    if (hierarchy === "Geography") {
      let array = [...new Set(data.map((element) => element["region_level1"]))];
      let options = [];
      array.forEach((value) => {
        options.push({ label: value, value: value, disabled: false });
      });
      setregion1Options(options);
      if (newExtendhierarchy.saved) {
        setRegion1(newExtendhierarchy.region1);
        setRegion2(newExtendhierarchy.region2);
        setRegion3(newExtendhierarchy.region3);
      }
    } else if (hierarchy === "Organisational") {
      let array = [...new Set(data.map((element) => element["entity_level1"]))];
      let options = [];
      array.forEach((value) => {
        options.push({ label: value, value: value, disabled: false });
      });
      setEntity1Options(options);
      if (newExtendhierarchy.saved) {
        setEntity1(newExtendhierarchy.entity1);
        setEntity2(newExtendhierarchy.entity2);
        setEntity2(newExtendhierarchy.entity3);
        setEntity4(newExtendhierarchy.entity4);
      }
    }
    if (newExtendhierarchy.saved) {
      if (hierarchy === "Emission") {
        setScope(newExtendhierarchy.scope.value);
        handleOnChange(newExtendhierarchy.scope.value, "scope");
        setCategory1(newExtendhierarchy.category1);
        setCategory2(newExtendhierarchy.category2);
        setCategory3(newExtendhierarchy.category3);
      }
    }
    setDisableApply(true);
  }, [hierarchy]);

  useEffect(() => {
    if (scopeSelected !== "" || region1 !== "" || entitylevel1 !== "") {
      setResetDisable(false);
    } else {
      setResetDisable(true);
    }
  }, [scopeSelected, region1, entitylevel1]);

  // onChange function
  const handleOnChange = async (value, field) => {
    let data = [...hierarchialData];
    let array = [];
    let options = [];
    let disabledOptions = [];
    let a = [];

    switch (field) {
      case "scope":
        setScope(value);
        data = data.filter((element) => element.scope === value);
        array = [...new Set(data.map((element) => element["category_level1"]))];
        array.forEach((element) => {
          options.push({ label: element, value: element, disabled: false });
        });
        setCategory1Options(options);
        break;
      case "Category 1":
        setCategory1(value.length > 0 ? value[0].value : value);
        value.length > 0 ? setCreatableCat1(false) : setCreatableCat1(true);
        data = data.filter(
          (element) =>
            element.scope === scopeSelected &&
            element.category_level1 === value[0]?.value
        );
        array = [...new Set(data.map((element) => element["category_level2"]))];
        array.forEach((element) => {
          options.push({ label: element, value: element, disabled: false });
        });
        setCategory2Options(options);
        category1Options.forEach((element) => {
          if (element.value === value[0]?.value || value[0] === undefined) {
            disabledOptions.push({
              label: element.label,
              value: element.value,
              disabled: false,
            });
          } else if (element.value !== undefined) {
            disabledOptions.push({
              label: element.label,
              value: element.value,
              disabled: true,
            });
          }
        });
        if (value.length > 0 && value[0] !== "") {
          a = [...new Set(disabledOptions.map((element) => element["value"]))];
          if (!a.includes(value[0]?.value)) {
            disabledOptions.push({
              label: value[0]?.value,
              value: value[0]?.value,
              disabled: false,
            });
            setCreatableCat1(false);
          }
        } else {
          setCreatableCat1(true);
        }
        setCategory1Options(disabledOptions);
        checkIfItIsNew("category_level1", value[0]?.value);
        break;
      case "Category 2":
        setCategory2(value[0]?.value);
        value.length > 0 ? setCreatableCat2(false) : setCreatableCat2(true);
        data = data.filter(
          (element) =>
            element.scope === scopeSelected &&
            element.category_level2 === value[0]?.value
        );
        array = [...new Set(data.map((element) => element["category_level3"]))];
        array.forEach((element) => {
          options.push({ label: element, value: element, disabled: false });
        });
        setCategory3Options(options);
        category2Options.forEach((element) => {
          if (element.value === value[0]?.value || value[0] === undefined) {
            disabledOptions.push({
              label: element.label,
              value: element.value,
              disabled: false,
            });
          } else if (element.value !== undefined) {
            disabledOptions.push({
              label: element.label,
              value: element.value,
              disabled: true,
            });
          }
        });
        if (value.length > 0 && value[0] !== "") {
          a = [...new Set(disabledOptions.map((element) => element["value"]))];
          console.log(a);
          if (!a.includes(value[0]?.value)) {
            disabledOptions.push({
              label: value[0]?.value,
              value: value[0]?.value,
              disabled: false,
            });
            setCreatableCat2(false);
          }
        } else {
          setCreatableCat2(true);
        }
        setCategory2Options(disabledOptions);
        checkIfItIsNew("category_level2", value[0]?.value);
        break;
      case "Category 3":
        setCategory3(value[0]?.value);
        value.length > 0 ? setCreatableCat3(false) : setCreatableCat3(true);
        checkIfItIsNew("category_level3", value[0]?.value);
        category3Options.forEach((element) => {
          if (element.value === value[0]?.value || value[0] === undefined) {
            disabledOptions.push({
              label: element.label,
              value: element.value,
              disabled: false,
            });
          } else if (element.value !== undefined) {
            disabledOptions.push({
              label: element.label,
              value: element.value,
              disabled: true,
            });
          }
        });
        if (value.length > 0 && value[0] !== "") {
          a = [...new Set(disabledOptions.map((element) => element["value"]))];
          console.log(a);
          if (!a.includes(value[0]?.value)) {
            disabledOptions.push({
              label: value[0]?.value,
              value: value[0]?.value,
              disabled: false,
            });
            setCreatableCat3(false);
          }
        }
        setCategory3Options(disabledOptions);
        break;
      case "Region 1":
        setRegion1(value[0]?.value);
        if (value.length > 0) {
          setcreatableReg1(false);
        } else {
          setcreatableReg1(true);
        }
        data = data.filter(
          (element) => element.region_level1 === value[0]?.value
        );
        array = [...new Set(data.map((element) => element["region_level2"]))];
        array.forEach((element) => {
          options.push({ label: element, value: element, disabled: false });
        });
        setregion2Options(options);
        region1Options.forEach((element) => {
          if (element.value === value[0]?.value || value[0] === undefined) {
            disabledOptions.push({
              label: element.label,
              value: element.value,
              disabled: false,
            });
          } else if (element.value !== undefined) {
            disabledOptions.push({
              label: element.label,
              value: element.value,
              disabled: true,
            });
          }
        });
        if (value.length > 0 && value[0] !== "") {
          a = [...new Set(disabledOptions.map((element) => element["value"]))];
          console.log(a);
          if (!a.includes(value[0]?.value)) {
            disabledOptions.push({
              label: value[0]?.value,
              value: value[0]?.value,
              disabled: false,
            });
            setcreatableReg1(false);
          }
        }
        setregion1Options(disabledOptions);
        checkIfItIsNew("region_level1", value[0]?.value);
        break;
      case "Region 2":
        setRegion2(value[0]?.value);
        if (value.length > 0) {
          setcreatableReg2(false);
        } else {
          setcreatableReg2(true);
        }
        data = data.filter(
          (element) => element.region_level2 === value[0]?.value
        );
        array = [...new Set(data.map((element) => element["region_level3"]))];
        array.forEach((element) => {
          options.push({ label: element, value: element, disabled: false });
        });
        setregion3Options(options);
        region2Options.forEach((element) => {
          if (element.value === value[0]?.value || value[0] === undefined) {
            disabledOptions.push({
              label: element.label,
              value: element.value,
              disabled: false,
            });
          } else if (element.value !== undefined) {
            disabledOptions.push({
              label: element.label,
              value: element.value,
              disabled: true,
            });
          }
        });
        if (value.length > 0 && value[0] !== "") {
          a = [...new Set(disabledOptions.map((element) => element["value"]))];
          console.log(a);
          if (!a.includes(value[0]?.value)) {
            disabledOptions.push({
              label: value[0]?.value,
              value: value[0]?.value,
              disabled: false,
            });
            setcreatableReg2(false);
          }
        }
        setregion2Options(disabledOptions);
        checkIfItIsNew("region_level2", value[0]?.value);
        break;
      case "Region 3":
        setRegion3(value[0]?.value);
        if (value.length > 0) {
          setcreatableReg3(false);
        } else {
          setcreatableReg3(true);
        }
        region3Options.forEach((element) => {
          if (element.value === value[0]?.value || value[0] === undefined) {
            disabledOptions.push({
              label: element.label,
              value: element.value,
              disabled: false,
            });
          } else if (element.value !== undefined) {
            disabledOptions.push({
              label: element.label,
              value: element.value,
              disabled: true,
            });
          }
        });
        if (value.length > 0 && value[0] !== "") {
          a = [...new Set(disabledOptions.map((element) => element["value"]))];
          console.log(a);
          if (!a.includes(value[0]?.value)) {
            disabledOptions.push({
              label: value[0]?.value,
              value: value[0]?.value,
              disabled: false,
            });
            setcreatableReg3(false);
          }
        }
        setregion3Options(disabledOptions);
        checkIfItIsNew("region_level3", value[0]?.value);
        break;
      case "Entity 1":
        setEntity1(value[0]?.value);
        value.length > 0
          ? setcreatableEntity1(false)
          : setcreatableEntity1(true);
        data = data.filter(
          (element) => element.entity_level1 === value[0]?.value
        );
        array = [...new Set(data.map((element) => element["entity_level2"]))];
        array.forEach((element) => {
          options.push({ label: element, value: element, disabled: false });
        });
        setEntity2Options(options);
        entity1Options.forEach((element) => {
          if (element.value === value[0]?.value || value[0] === undefined) {
            disabledOptions.push({
              label: element.label,
              value: element.value,
              disabled: false,
            });
          } else if (element.value !== undefined) {
            disabledOptions.push({
              label: element.label,
              value: element.value,
              disabled: true,
            });
          }
        });
        if (value.length > 0 && value[0] !== "") {
          a = [...new Set(disabledOptions.map((element) => element["value"]))];
          console.log(a);
          if (!a.includes(value[0]?.value)) {
            disabledOptions.push({
              label: value[0]?.value,
              value: value[0]?.value,
              disabled: false,
            });
            setcreatableEntity1(false);
          }
        }
        setEntity1Options(disabledOptions);
        checkIfItIsNew("entity_level1", value[0]?.value);
        break;
      case "Entity 2":
        setEntity2(value[0]?.value);
        value.length > 0
          ? setcreatableEntity2(false)
          : setcreatableEntity2(true);
        data = data.filter(
          (element) => element.entity_level2 === value[0]?.value
        );
        array = [...new Set(data.map((element) => element["entity_level3"]))];
        array.forEach((element) => {
          options.push({ label: element, value: element, disabled: false });
        });
        setEntity3Options(options);
        entity2Options.forEach((element) => {
          if (element.value === value[0]?.value || value[0] === undefined) {
            disabledOptions.push({
              label: element.label,
              value: element.value,
              disabled: false,
            });
          } else if (element.value !== undefined) {
            disabledOptions.push({
              label: element.label,
              value: element.value,
              disabled: true,
            });
          }
        });
        if (value.length > 0 && value[0] !== "") {
          a = [...new Set(disabledOptions.map((element) => element["value"]))];
          console.log(a);
          if (!a.includes(value[0]?.value)) {
            disabledOptions.push({
              label: value[0]?.value,
              value: value[0]?.value,
              disabled: false,
            });
            setcreatableEntity2(false);
          }
        }
        setEntity2Options(disabledOptions);
        checkIfItIsNew("entity_level2", value[0]?.value);
        break;
      case "Entity 3":
        setEntity3(value[0]?.value);
        value.length > 0
          ? setcreatableEntity3(false)
          : setcreatableEntity3(true);
        data = data.filter(
          (element) => element.entity_level3 === value[0]?.value
        );
        array = [...new Set(data.map((element) => element["entity_level4"]))];
        array.forEach((element) => {
          options.push({ label: element, value: element, disabled: false });
        });
        setEntity4Options(options);
        entity3Options.forEach((element) => {
          if (element.value === value[0]?.value || value[0] === undefined) {
            disabledOptions.push({
              label: element.label,
              value: element.value,
              disabled: false,
            });
          } else if (element.value !== undefined) {
            disabledOptions.push({
              label: element.label,
              value: element.value,
              disabled: true,
            });
          }
        });
        if (value.length > 0 && value[0] !== "") {
          a = [...new Set(disabledOptions.map((element) => element["value"]))];
          console.log(a);
          if (!a.includes(value[0]?.value)) {
            disabledOptions.push({
              label: value[0]?.value,
              value: value[0]?.value,
              disabled: false,
            });
            setcreatableEntity3(false);
          }
        }
        setEntity3Options(disabledOptions);
        checkIfItIsNew("entity_level3", value[0]?.value);
        break;
      case "Entity 4":
        setEntity4(value[0]?.value);
        value.length > 0
          ? setcreatableEntity4(false)
          : setcreatableEntity4(true);
        entity4Options.forEach((element) => {
          if (element.value === value[0]?.value || value[0] === undefined) {
            disabledOptions.push({
              label: element.label,
              value: element.value,
              disabled: false,
            });
          } else if (element.value !== undefined) {
            disabledOptions.push({
              label: element.label,
              value: element.value,
              disabled: true,
            });
          }
        });
        if (value.length > 0 && value[0] !== "") {
          a = [...new Set(disabledOptions.map((element) => element["value"]))];
          console.log(a);
          if (!a.includes(value[0]?.value)) {
            disabledOptions.push({
              label: value[0]?.value,
              value: value[0]?.value,
              disabled: false,
            });
            setcreatableEntity4(false);
          }
        }
        setEntity4Options(disabledOptions);
        checkIfItIsNew("entity_level4", value[0]?.value);
        break;
      default:
        break;
    }
  };

  const saveValues = () => {
    let obj = {
      scope: { label: scopeSelected, value: scopeSelected },
      category1: { label: category1, value: category1 },
      category2: { label: category2, value: category2 },
      category3: { label: category3, value: category3 },
      region1: { label: region1, value: region1 },
      region2: { label: region2, value: region2 },
      region3: { label: region3, value: region3 },
      entity1: { label: entitylevel1, value: entitylevel1 },
      entity2: { label: entitylevel2, value: entitylevel2 },
      entity3: { label: entitylevel3, value: entitylevel3 },
      entity4: { label: entitylevel4, value: entitylevel4 },
      saved: true,
    };
    dispatch(setExtendNewHierarchy({ rule: obj }));
    onApply();
  };

  const resetFields = () => {
    setReset(true);
    setScope([]);
    setCategory1([]);
    // handleOnChange([], "Category 1");
    setCategory1Options([]);
    setCategory2Options([]);
    setCategory3Options([]);
    setCategory2([]);
    setCategory3([]);
    setRegion1([]);
    setRegion2([]);
    setRegion3([]);
    setEntity1([]);
    setEntity2([]);
    setEntity3([]);
    setEntity4([]);
    setDisableApply(true);
    dispatch(setExtendNewHierarchy({ rule: {} }));
  };

  useEffect(() => {
    setTimeout(() => {
      setReset(false);
    }, 1000);
  }, [resetOptions]);

  const checkIfItIsNew = (field, value) => {
    let original = [
      ...new Set(hierarchialData.map((element) => element[field])),
    ];
    if (original.includes(value) || value == undefined) {
      value === undefined ? setErrorMessage(false) : setErrorMessage(true);
      setDisableApply(true);
    } else {
      setErrorMessage(false);
      setDisableApply(false);
    }
  };

  return (
    <div className="extend-modal">
      {hierarchy === "Emission" && (
        <div className="extend-row">
          <div className="extend-row-input">
            <span>
              {scope}
              <span className="required-star">*</span>
            </span>
            <Select
              options={extendScopes}
              onChange={(event) => {
                handleOnChange(event, "scope");
              }}
              value={scopeSelected}
              multiple={false}
            ></Select>
          </div>
          <div className="extend-row-input">
            <span>
              {cat1}
              <span className="required-star">*</span>
            </span>

            <MultiSelectCreatableOption
              value={category1}
              onChange={(e) => {
                handleOnChange(e, "Category 1");
              }}
              id="base-year-scope-1-2"
              avilableOptions={category1Options}
              // label={cat1}
              overrideStrings={{
                create: createNewCategory,
                search: searchCategory,
              }}
              hasSelectAll={false}
              disabled={scopeSelected === ""}
              isCreatable={creatablecat1}
              reset={resetOptions}
            ></MultiSelectCreatableOption>
          </div>
          <div className="extend-row-input">
            <span>{cat2}</span>
            <MultiSelectCreatableOption
              value={category2}
              onChange={(e) => {
                handleOnChange(e, "Category 2");
              }}
              id="base-year-scope-1-2"
              avilableOptions={category2Options}
              // label={cat2}
              overrideStrings={{
                create: createNewCategory,
                search: searchCategory,
              }}
              hasSelectAll={false}
              disabled={category1 === ""}
              isCreatable={creatablecat2}
              reset={resetOptions}
            ></MultiSelectCreatableOption>
          </div>
          <div className="extend-row-input">
            <span>{cat3}</span>
            <MultiSelectCreatableOption
              value={category3}
              onChange={(e) => {
                handleOnChange(e, "Category 3");
              }}
              id="base-year-scope-1-2"
              avilableOptions={category3Options}
              // label={cat3}
              overrideStrings={{
                create: createNewCategory,
                search: searchCategory,
              }}
              hasSelectAll={false}
              disabled={category2 === ""}
              isCreatable={creatablecat3}
              reset={resetOptions}
            ></MultiSelectCreatableOption>
          </div>
        </div>
      )}
      {hierarchy === "Geography" && (
        <div className="extend-row">
          <div className="extend-row-input">
            <span>
              {reg1}
              <span className="required-star">*</span>
            </span>
            <MultiSelectCreatableOption
              value={region1}
              onChange={(e) => {
                handleOnChange(e, "Region 1");
              }}
              id="base-year-scope-1-2"
              avilableOptions={region1Options}
              // label={reg1}
              overrideStrings={{
                create: createNewRegion,
                search: searchNewRegion,
              }}
              hasSelectAll={false}
              isCreatable={creatablereg1}
              reset={resetOptions}
            ></MultiSelectCreatableOption>
          </div>
          <div className="extend-row-input">
            <span>{reg2}</span>
            <MultiSelectCreatableOption
              value={region2}
              onChange={(e) => {
                handleOnChange(e, "Region 2");
              }}
              id="base-year-scope-1-2"
              avilableOptions={region2Options}
              // label={reg2}
              overrideStrings={{
                create: createNewRegion,
                search: searchNewRegion,
              }}
              hasSelectAll={false}
              disabled={region1 === ""}
              isCreatable={creatableReg2}
              reset={resetOptions}
            ></MultiSelectCreatableOption>
          </div>
          <div className="extend-row-input">
            <span>{reg3}</span>
            <MultiSelectCreatableOption
              value={region3}
              onChange={(e) => {
                handleOnChange(e, "Region 3");
              }}
              id="base-year-scope-1-2"
              avilableOptions={region3Options}
              // label={reg3}
              overrideStrings={{
                create: createNewRegion,
                search: searchNewRegion,
              }}
              hasSelectAll={false}
              disabled={region2 === ""}
              isCreatable={creatableReg3}
              reset={resetOptions}
            ></MultiSelectCreatableOption>
          </div>
        </div>
      )}
      {hierarchy === "Organisational" && (
        <div className="extend-row">
          <div className="extend-row-input">
            <span>
              {ent1}
              <span className="required-star">*</span>
            </span>
            <MultiSelectCreatableOption
              value={entitylevel1}
              onChange={(e) => {
                handleOnChange(e, "Entity 1");
              }}
              id="base-year-scope-1-2"
              avilableOptions={entity1Options}
              // label={ent1}
              overrideStrings={{
                create: createNewEntity,
                search: searchNewEntity,
              }}
              hasSelectAll={false}
              isCreatable={creatableEntity1}
              reset={resetOptions}
            ></MultiSelectCreatableOption>
          </div>
          <div className="extend-row-input">
            <span>{ent2}</span>
            <MultiSelectCreatableOption
              value={entitylevel2}
              onChange={(e) => {
                handleOnChange(e, "Entity 2");
              }}
              id="base-year-scope-1-2"
              avilableOptions={entity2Options}
              // label={ent2}
              overrideStrings={{
                create: createNewEntity,
                search: searchNewEntity,
              }}
              hasSelectAll={false}
              disabled={entitylevel1 === ""}
              isCreatable={creatableEntity2}
              reset={resetOptions}
            ></MultiSelectCreatableOption>
          </div>
          <div className="extend-row-input">
            <span>{ent3}</span>
            <MultiSelectCreatableOption
              value={entitylevel3}
              onChange={(e) => {
                handleOnChange(e, "Entity 3");
              }}
              id="base-year-scope-1-2"
              avilableOptions={entity3Options}
              // label={ent3}
              overrideStrings={{
                create: createNewEntity,
                search: searchNewEntity,
              }}
              hasSelectAll={false}
              disabled={entitylevel2 === ""}
              isCreatable={creatableEntity3}
              reset={resetOptions}
            ></MultiSelectCreatableOption>
          </div>
          <div className="extend-row-input">
            <span>{ent4}</span>
            <MultiSelectCreatableOption
              value={entitylevel4}
              onChange={(e) => {
                handleOnChange(e, "Entity 4");
              }}
              id="base-year-scope-1-2"
              avilableOptions={entity4Options}
              // label={ent4}
              overrideStrings={{
                create: createNewEntity,
                search: searchNewEntity,
              }}
              hasSelectAll={false}
              disabled={entitylevel3 === ""}
              isCreatable={creatableEntity4}
              reset={resetOptions}
            ></MultiSelectCreatableOption>
          </div>
        </div>
      )}
      {showError && (
        <div>
          <span className="extend-modal-error-message">
            {newHierarchyWarningMessage}
          </span>
        </div>
      )}
      <div className="extend-modal-buttons">
        <Button
          variant="secondary"
          disabled={resetDisable}
          onClick={() => resetFields()}
        >
          {reset}
        </Button>
        <Button
          variant="primary-alt"
          onClick={() => saveValues()}
          disabled={disableApply}
        >
          {ApplyBtn}
        </Button>
      </div>
    </div>
  );
}
export function ExtendModal({ isModalOpen, onClose, hierarchy }) {
  return (
    <Modal
      show={isModalOpen}
      onClose={onClose}
      header={
        <div className="emission-filter-modal-header">
          <span>{newTrgtHrchy}</span>
        </div>
      }
      //   className={"emission-gmp-modal"}
      body={
        <ModalBody
          onApply={() => {
            onClose();
          }}
          hierarchy={hierarchy}
        ></ModalBody>
      }
    ></Modal>
  );
}
