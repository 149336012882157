import { routes } from "./routes.constatns";
import { Navigate, Route } from "react-router-dom";
import { useEffect } from "react";
import App from "../App";
import {
  CreateNewEngagement,
  Home,
  PowerBI,
  ViewEngagement,
  CreateNewProject,
  AddGMP,
  AddForecast,
  ImportExcel,
  AddTarget,
  AddTargetSpecification,
  AddForecastSpecification,
} from "../components";
import { ExternalUserPowerBI } from "../components/PowerBI/ExternalUserPowerBI";
import { CreateNewScenario } from "../components/Simulate/components/createNewScenario/CreateNewScenario";
import { ProjectsLibrary } from "../components/ProjectsLibrary/ProjectsLibrary";
import { ProjectDetails } from "../components/ProjectsLibrary/components/ProjectDetails/ProjectDetails";

const isURLAllowed = process.env.REACT_APP_URL_BASE_ALLOW_HOME_URL === 'true';
console.log('isURLAllowed:', isURLAllowed);
const externalUrl = process.env.REACT_APP_URL_REG_ALL;

const RedirectToExternal = () => {
  useEffect(() => {
    window.location.href = externalUrl;
  }, []);

  return null;
};
export const AppRoutes = (
  <Route path={routes.root} element={<App />}>
    {isURLAllowed ?
    <>
    <Route index element={<Navigate to={`/${routes.home}`} />} /> 
    <Route path={routes.home} element={<Home />} />
    </>
    :  (
       <Route path="*" element={<RedirectToExternal />} />  // Redirect to default URL
    )
     }
    <Route path={routes.createNewEngagement}>
      <Route index element={<CreateNewEngagement />} />
      <Route path={routes.createNewProject} element={<CreateNewProject />} />
      <Route path={routes.addGMP} element={<AddGMP />} />
      <Route path={routes.importExcel} element={<ImportExcel />} />
      <Route path={routes.addTarget}>
        <Route index element={<AddTarget />} />
        <Route
          path={routes.addTargetSpecification}
          element={<AddTargetSpecification />}
        />
      </Route>
      <Route path={routes.addforecast}>
        <Route index element={<AddForecast />} />
        <Route
          path={routes.addForecastSpecification}
          element={<AddForecastSpecification />}
        />
      </Route>
      <Route path={routes.createNewScenario} element={<CreateNewScenario />} />
    </Route>
    {isURLAllowed ?
    <Route path={routes.viewEngagement} element={<ViewEngagement />} /> 
    :(
       <Route path="*" element={<RedirectToExternal />} /> 
    )
    }
    <Route path={routes.powerBI} element={<PowerBI />} />
    <Route path={routes.externalUserPowerBI} element={<ExternalUserPowerBI />} />
    <Route path={routes.decarbProjectsLibrary}>
      <Route index element={<ProjectsLibrary />} />
      <Route
        path={routes.decarbProjectsLibraryDetails}
        element={<ProjectDetails />}
      />
    </Route> :
    
    {isURLAllowed ?
    <Route path={routes.decarbProjectsLibraryDetails}>
    <Route index element={<ProjectDetails />} />
      </Route>
       :(
         <Route path="*" element={<RedirectToExternal />} />  // Redirect to default URL
      )
      }
  </Route>
);

export default AppRoutes;
