export const routes = {
  root: "/",
  home: "home",
  powerBI: "power-bi",
  externalUserPowerBI: "power-bi-report",
  forecast: "forecast",
  createNewEngagement: "create-new-engagement",
  viewEngagement: "view-engagement",
  createNewProject: "create-new-project",
  addGMP: "add-gmp",
  addforecast: "add-forecast",
  addTarget: "add-target",
  importExcel: "import-excel",
  addForecastSpecification: "add-forecast-specification",
  addTargetSpecification: "add-target-specification",
  createNewScenario: "create-new-scenario",
  decarbProjectsLibrary: "decarbonization-projects-library",
  decarbProjectsLibraryDetails: "details",
};
