import { useEffect, useState } from "react";
import { FolderIcon } from "../../icons";
import { Modal } from "../Modal/Modal";
import { Select } from "../Select/Select";
import { useSelector } from "react-redux";
import { Button } from "../Button/Button";
import { CancelBtn, SubmitBtn } from "../../util/constants";
import { routes } from "../../routes/routes.constatns";
import { Link } from "react-router-dom";

function ModalHeader() {
  return (
    <div className="file-version-history-modal-header">
      <div className="motif-fab">
        <FolderIcon />
      </div>
      <span>Data hosting location</span>
    </div>
  );
}

function ModalBody({ onChange, value, options }) {
  return (
    <div style={{ display: "flex", gap: "2rem", flexDirection: "column" }}>
      <span>
        Select a data hosting location for your engagement. Based on your choice,
         your engagement data will be hosted in the specified region.
      </span>
      <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <span style={{ color: "#a4a3b1" }}>
          Data storage location <span className="required-star">*</span>
        </span>
        <Select onChange={onChange} value={value} options={options} />
      </div>
    </div>
  );
}

function ModalFooter({
  handleCancelClick,
  selectedValue,
  engagementId,
  regionList,
}) {
  let url = "";

  if (selectedValue) {
    var regionUrl = regionList.find((x) => x.id === selectedValue).appUrl;
    url = `${regionUrl}${routes.createNewEngagement}?regionId=${selectedValue}`;

    if (engagementId) {
      url = url + `&engagementId=${engagementId}`;
    }
  }

  return (
    <div className="view-endagement-delete-modal-footer">
      <Button variant="secondary" onClick={handleCancelClick}>
        {CancelBtn}
      </Button>
      <Link
        to={url}
        className={`motif-button motif-button-primary motif-button-large decarb-button ${!selectedValue ? "decarb-text-area-hide-label" : ""
          }`}
        disabled={!selectedValue}
      >
        {SubmitBtn}
      </Link>
      <Button
        variant="primary"
        disabled={!selectedValue}
        className={selectedValue ? "decarb-text-area-hide-label" : ""}
      >
        {SubmitBtn}
      </Button>
    </div>
  );
}

export function SelectRegionModal({
  isModalVisible,
  onClose,
  engagementId = "",
}) {
  const [selectedValue, setSelectedValue] = useState(0);
  const regionList = useSelector((state) => state.engagement.regionList);
  const [optionsList, setOptionsList] = useState([]);
  function onChange(value) {
    setSelectedValue(parseInt(value));
  }

  useEffect(
    () => () => {
      setSelectedValue(0);
    },
    []
  );
  useEffect(() => {
    let array = [];
    regionList.forEach((element) => {
      if (element.id === 1) {
        array.push({ id: element.id, name: "United States - Americas" });
      } else if (element.id === 2) {
        array.push({ id: element.id, name: "Singapore - APAC" });
      } else {
        array.push({ id: element.id, name: "Netherlands - EMEIA" });
      }
    });
    setOptionsList(array);
  }, [regionList]);

  return (
    <Modal
      show={isModalVisible}
      onClose={onClose}
      header={<ModalHeader />}
      body={
        <ModalBody
          onChange={onChange}
          value={selectedValue}
          options={optionsList}
        />
      }
      footer={
        <ModalFooter
          handleCancelClick={onClose}
          selectedValue={selectedValue}
          engagementId={engagementId}
          regionList={regionList}
        />
      }
      className={"change-region-modal"}
    />
  );
}
