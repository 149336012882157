import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { routes } from "../../routes/routes.constatns";
import {
  Button,
  DownloadLink,
  FileUploader,
  Input,
  LoadingIndicator,
} from "..";
import "./ManageEmissions.scss";
import { SaveIcon, GraphIcon, DownloadIcon, EmissionIcon } from "../../icons";
import template from "../../assets/templates/EyDecarb_ManageEmissions_Template.xlsx";
import { useState, useEffect } from "react";
import { Tooltip as MotifTooltip } from "../CommonUIComponents/Tooltip/Tooltip";
import {
  saveManageEmissions,
  getUploadedFiles,
  getUploadedFileProgress,
  getHeadersbyModuleForValidation,
  updateMostRecentEndYear,
  getDataValidationModule1Data,
  updateSkipForecast,
  uploadExcelFile,
} from "../../store/services/engagement.service";
import { useDispatch, useSelector } from "react-redux";
import {
  getFileUploadHistoryM1,
  setEngagementMostRecentYear,
  setIsForecastEndYearDisabled,
  setIsMostRecentYearDisabled,
  setIsSaveAndNextDisabled,
  setShowForecastFlag,
  setShowTargetFlag,
  setSkipForecast,
} from "../../store/slices/engagementSlice";
import {
  manageEmissionBodyUploadTitle,
  PoweBiBtn,
  manageEmissionHeaderMain,
  manageEmisionHeaderDesc,
  manageEmissionBodyTemplateTitle,
  manageEmissionBodyUploadRecentYear,
  manageEmissionBodyUploadSaveFile,
  saveButtonTooltip,
  DownloadErrorReportBtn,
  DownloadTemplateBtn,
  FourDigitYear,
  FileUploadIsInProgress,
  FileUploadIsInSuccess,
  DataVerificationIsInProgress,
  DataVerificationIsInFailed,
  DataVerificationIsInSuccess,
  GeneratingErrorReport,
  DataLoadIsInProgress,
  DataLoadIsInSuccess,
  ExcelDataVerificationFailed,
  FileVerificationIsInProgress,
  FailedStatus,
  DataLoadHasFailed,
  uploadSuccessMessage1,
  uploadSuccessMessage2,
  uploadSuccessMessage3,
  manageEmissionBodyUploadFileHistory,
  templatefileM1tooltip,
  mostRecentYeartooltip,
  versionHistoryTooltip,
} from "../../util/constants";
import { uploadFileToBlob } from "../../store/services/azureBlob.service";
import { formarDateTime } from "../../util/dateUtil";
import { createExcelFile, validateExcel } from "../../util/excelUtil";
import {
  setShowForecast,
  setShowTarget,
} from "../../store/services/forecast.service";
import { RepeatIcon } from "../../icons/repeat";
import { FileVersionHistoryModal } from "./FileVersionHistoryModal";
import { saveAs } from "file-saver";
import { getLockingData } from "../../store/slices/projectSlice";
import { LockerModal } from "../LockerModal/lockerModal";

const DataCategory = "Activity Data";
const ModuleName = "Module1";
let refreshIntervalId = undefined;
let refreshIntervalCount = 0;

export function ManageEmissions() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const engagement = useSelector((state) => state.engagement.engagement);
  const isMostRecentYearDisabled = useSelector(
    (state) => state.engagement.isMostRecentYearDisabled
  );
  const user = useSelector((state) => state.user.user);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [percentUploaded, setPercentUploaded] = useState(0);
  const [uploaded, setUploaded] = useState(false);
  const [showPercentage, setShowPercentage] = useState(false);
  const [startUploadProcess, setStartUploadProcess] = useState(false);
  const [fileStatus, setFileStatus] = useState(0);
  const [startUploadProcessStatus, setStartUploadProcessStatus] = useState("");
  const [mostRecentYear, setMostRecentYear] = useState(
    engagement?.mostRecentYear
  );
  const [excelHeaders, setExcelHeaders] = useState([]);
  const [setError, setErrorState] = useState(false);
  const [excelTemplateError, setExcelTemplateError] = useState("");
  const [excelStatus, setExcelStatus] = useState("Error");
  const [excelDataVerificationFailed, setExcelDataVerificationFailed] =
    useState("");
  const [excelDataVerificationFailedFile, setExcelDataVerificationFailedFile] =
    useState(undefined);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);
  const [
    isFileVersionHistoryModalVisible,
    setIsFileVersionHistoryModalVisible,
  ] = useState(false);
  const [open, setTooltipOpen] = useState(false);
  const [lockedData, setLock] = useState();
  const [lockedBy, setLockedby] = useState();
  const [isLockerModalOpen, setLockerModalOpen] = useState(false);
  const [fileContent, setFileContent] = useState("");

  /* istanbul ignore next */

  // on file drop
  async function onDrop(files) {
    if (
      parseFloat(mostRecentYear) > 2000 &&
      parseFloat(mostRecentYear) < 2100
    ) {
      setErrorState(false);
      setExcelTemplateError("");
      setUploaded(false);
      setExcelStatus("Error");
      setStartUploadProcess(true);
      setStartUploadProcessStatus(FileVerificationIsInProgress);
      setExcelDataVerificationFailed("");
      setExcelDataVerificationFailedFile(undefined);
      dispatch(setIsMostRecentYearDisabled({ isDisabled: true }));
      setIsDeleteButtonDisabled(false);
      setShowPercentage(true);

      const data =
        excelHeaders.length === 0
          ? await getHeadersbyModuleForValidation({
              request: { moduleName: ModuleName },
            })
          : excelHeaders;
      setExcelHeaders(data);
      // const reader = new FileReader();
      // reader.onload = () => {
      //   const base64String = btoa(reader.result);
      //   setFileContent(base64String);
      // };
      // reader.readAsDataURL(files[0]);
      if (files[0].size / 1048576 <= 28) {
        validateExcel(files[0], data, (result) => {
          if (result.status === "Error") {
            setExcelTemplateError(result.message);
            setExcelStatus(result.status);
            setStartUploadProcess(false);
            setStartUploadProcessStatus("");
          } else {
            setExcelTemplateError("");
            setFilesToUpload(files);
            setTooltipOpen(true);
            setExcelStatus(result.status);
            setStartUploadProcess(false);
            setStartUploadProcessStatus("");
          }
        });
      } else {
        setExcelTemplateError("File size cannot exceed 28MB");
        setExcelStatus("Error");
        setStartUploadProcess(false);
        setStartUploadProcessStatus("");
      }
    } else {
      setErrorState(true);
    }
  }

  /* istanbul ignore next */

  // on Save file click
  async function onSaveFileClickHandler() {
    setTooltipOpen(false);
    setShowPercentage(false);

    const file = filesToUpload[0];
    const fileName = `EYDecarb_${
      engagement.engagementCode
    }_${ModuleName}_${formarDateTime(new Date(), "ddMMyyyy HH:mm:ss")}.xlsx`;

    const recentYear = parseFloat(mostRecentYear);

    setStartUploadProcess(true);
    setStartUploadProcessStatus(FileUploadIsInProgress);
    setIsDeleteButtonDisabled(true);

    try {
      let request = {
        engagementId: engagement.id,
        clientName: engagement.clientName,
        sectorId: engagement.sectorId,
        dataCategory: DataCategory,
        fileName: fileName,
        mostRecentYear: recentYear,
        uploadedBy: user.username,
        updatedDate: new Date().toString(),
      };
      await saveManageEmissions({ request });
      // await uploadFileToBlob({
      //   file: file,
      //   moduleName: `DecarbActivityData/${ModuleName}`,
      //   fileName: fileName,
      // });
      const formData = new FormData();
      formData.append("file", file);
      formData.append("TextFileName", fileName);
      request = formData;
      console.log("Formdata", formData.entries());
      // request = {
      //   FileName: fileName,
      //   ContentType:
      //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      //   FileContent: fileContent,
      // };
      await uploadExcelFile({ request });
      refreshIntervalId = window.setInterval(checkUploadStatus, 5000);
    } catch (error) {
      setStartUploadProcess(false);
    }
  }

  /* istanbul ignore next */

  //Checking file upload status
  async function checkUploadStatus() {
    refreshIntervalCount++;

    if (refreshIntervalCount > 100) {
      window.clearInterval(refreshIntervalId);
      refreshIntervalId = undefined;
      setIsDeleteButtonDisabled(false);
      setStartUploadProcessStatus(
        "Data load has failed. This may have occurred due to some technical error. Please try again and contact support team if issue persists."
      );

      return;
    }

    const request = { engagementId: engagement.id };
    const response = await getUploadedFileProgress({ request });
    setStartUploadProcessStatus(FileUploadIsInProgress);

    if (response.data.length > 0) {
      const filteredData = response.data.filter(
        (x) => x.dataCategory === DataCategory
      );

      const data = filteredData.sort((a, b) =>
        a.fileName.localeCompare(b.fileName)
      )[filteredData.length - 1];

      if (data && data.dataCategory === DataCategory) {
        if (data.loadedToBLob === 1) {
          let uploadPercent = 25;
          let uploadPercentStatus = FileUploadIsInSuccess;
          let finalSuccesMessage = "";

          if (
            data.dataValidation_Status?.toLowerCase() ===
            "In Progress".toLowerCase()
          ) {
            uploadPercent = 50;
            uploadPercentStatus = DataVerificationIsInProgress;
          }
          if (
            data.dataValidation_Status?.toLowerCase() === "Failed".toLowerCase()
          ) {
            uploadPercent = 50;
            uploadPercentStatus = DataVerificationIsInFailed;
            setPercentUploaded(uploadPercent);
            setStartUploadProcessStatus(uploadPercentStatus);

            if (refreshIntervalId) {
              window.clearInterval(refreshIntervalId);
              refreshIntervalId = undefined;
            }
            setIsDeleteButtonDisabled(false);

            const errorData = await getDataValidationModule1Data({
              request: { engagementCode: engagement.engagementCode },
            });

            if (errorData && errorData.length > 0) {
              setExcelDataVerificationFailed(GeneratingErrorReport);

              const generatedExcel = await createExcelFile([
                { data: errorData, name: "Errors" },
              ]);
              const url = window.URL.createObjectURL(generatedExcel);
              setExcelDataVerificationFailedFile(url);
              setExcelDataVerificationFailed(
                ExcelDataVerificationFailed.replace("$1", errorData.length)
              );
            }
          }
          if (
            data.dataValidation_Status?.toLowerCase() ===
            "Success".toLowerCase()
          ) {
            uploadPercent = 50;
            uploadPercentStatus = "Getting ready for data load";

            if (
              data.stagingUpload.toLowerCase() === "In Progress".toLowerCase()
            ) {
              uploadPercent = 75;
              uploadPercentStatus = DataVerificationIsInProgress;
            }
            if (data.stagingUpload.toLowerCase() === "Failed".toLowerCase()) {
              uploadPercent = 75;
              uploadPercentStatus = DataLoadHasFailed;

              setPercentUploaded(uploadPercent);
              setStartUploadProcessStatus(uploadPercentStatus);

              if (refreshIntervalId) {
                window.clearInterval(refreshIntervalId);
                refreshIntervalId = undefined;
              }
              setIsDeleteButtonDisabled(false);
            }
            if (data.stagingUpload.toLowerCase() === "Success".toLowerCase()) {
              uploadPercent = 75;
              uploadPercentStatus = DataVerificationIsInSuccess;

              if (
                data.module1Refresh.toLowerCase() ===
                "In Progress".toLowerCase()
              ) {
                uploadPercent = 90;
                uploadPercentStatus = DataLoadIsInProgress;
              }
              if (
                data.module1Refresh.toLowerCase() === "Failed".toLowerCase()
              ) {
                uploadPercent = 90;
                uploadPercentStatus = DataLoadHasFailed;

                setPercentUploaded(uploadPercent);
                setStartUploadProcessStatus(uploadPercentStatus);

                if (refreshIntervalId) {
                  window.clearInterval(refreshIntervalId);
                  refreshIntervalId = undefined;
                }
                setIsDeleteButtonDisabled(false);
              }
              if (
                data.module1Refresh.toLowerCase() === "Success".toLowerCase()
              ) {
                uploadPercentStatus = DataLoadIsInSuccess;
                uploadPercent = 100;
                if (data.final_record_count !== 0) {
                  finalSuccesMessage =
                    uploadSuccessMessage1 +
                    data.staging_record_count +
                    uploadSuccessMessage2;
                }
                if (data.final_record_count === 0) {
                  finalSuccesMessage = uploadSuccessMessage3;
                }
                setIsDeleteButtonDisabled(false);

                if (fileStatus === 2) {
                  dispatch(setSkipForecast({ skipForecast: 0 }));

                  await updateSkipForecast({
                    request: {
                      engagementId: engagement.id,
                      skipvalue: 0,
                    },
                  });

                  dispatch(setShowForecastFlag({ show: 0 }));
                  await setShowForecast({
                    request: { engagementId: engagement.id, status: 0 },
                  });

                  dispatch(setShowTargetFlag({ show: 0 }));
                  await setShowTarget({
                    request: { engagementId: engagement.id, status: 0 },
                  });

                  dispatch(setIsForecastEndYearDisabled({ isDisabled: false }));
                }
              }
            }
          }

          setPercentUploaded(uploadPercent);
          setStartUploadProcessStatus(uploadPercentStatus);

          if (uploadPercent !== 100) {
            setStartUploadProcess(true);
            setExcelTemplateError("");
          } else {
            setUploaded(true);
            setShowPercentage(false);
            setTooltipOpen(false);
            setExcelTemplateError(finalSuccesMessage);
            dispatch(setIsSaveAndNextDisabled({ isDisabled: false }));
            setStartUploadProcess(false);
            setFileStatus(1);

            if (refreshIntervalId) {
              window.clearInterval(refreshIntervalId);
              refreshIntervalId = undefined;
            }
          }
        } else {
          let uploadPercent = 0;
          let uploadPercentStatus = FileUploadIsInProgress;
          setPercentUploaded(uploadPercent);
          setStartUploadProcessStatus(uploadPercentStatus);
          setStartUploadProcess(true);
        }
      }
    }
  }

  /* istanbul ignore next */

  //on file delete
  function onDelete() {
    setFilesToUpload([]);
    setPercentUploaded(0);
    setUploaded(false);
    setShowPercentage(false);
    setTooltipOpen(false);
    setStartUploadProcess(false);
    setStartUploadProcessStatus("");
    setExcelTemplateError("");
    setExcelDataVerificationFailed("");
    setExcelDataVerificationFailedFile(undefined);
    dispatch(setIsMostRecentYearDisabled({ isDisabled: false }));

    if (refreshIntervalId) {
      window.clearInterval(refreshIntervalId);
      refreshIntervalId = undefined;
    }

    if (fileStatus === 1) {
      setFileStatus(2);
    }

    dispatch(setIsSaveAndNextDisabled({ isDisabled: true }));
  }

  /* istanbul ignore next */
  useEffect(() => {
    async function fetchData() {
      const request = { id: engagement.id };
      const data = await getUploadedFiles({ request });
      if (data && data.data.length > 0) {
        const fl = data.data.find((x) => x.dataCategory === DataCategory);

        if (fl) {
          setFilesToUpload([{ name: fl.fileName }]);

          checkUploadStatus();
          refreshIntervalId = window.setInterval(checkUploadStatus, 5000);
          setFileStatus(1);
        }
      }
    }

    if (engagement?.id) {
      fetchData();
      setExcelTemplateError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engagement?.id, dispatch]);

  //on Most recent year value change
  const mostRecentYearChange = (event) => {
    let year = event.target.value;
    setMostRecentYear(year);
    if (parseFloat(year) > 2000 && parseFloat(year) < 2100) {
      setErrorState(false);
    } else {
      setErrorState(true);
    }
  };

  useEffect(
    () => () => {
      window.clearInterval(refreshIntervalId);
      refreshIntervalId = undefined;
    },
    []
  );

  // to get a locked user flags
  /* istanbul ignore next */
  useEffect(() => {
    const request = {
      LockedBy: user.username,
      ModuleName: "Engagement",
      EngagementID: engagement.id,
    };

    dispatch(getLockingData(request))
      .then((action) => {
        if (action) {
          const lockedData = action.payload.data.lockStatus;
          const lockedBy = action.payload.data.lockedBy;
          setLockedby(lockedBy);
          setLock(lockedData);
          if (lockedData === 2) {
            setLockerModalOpen(true);
          }
        } else {
          console.error("Failed to fetch locking data:", action.error);
        }
      })
      .catch((error) => {
        console.error("Error fetching locking data:", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  /* istanbul ignore next */

  // on click of Version history
  function onFileVersionClickHandler() {
    setIsFileVersionHistoryModalVisible(true);

    const request = {
      engagementId: engagement.id,
    };
    dispatch(getFileUploadHistoryM1(request));
  }

  //On click of view error report
  function onSaveErrorReportClickHandler() {
    const fileName = `EYDecarb_${
      engagement.engagementCode
    }_${ModuleName}_DataLoadErrors_${formarDateTime(
      new Date(),
      "ddMMyyyy HH:mm:ss"
    )}.xlsx`;
    saveAs(excelDataVerificationFailedFile, fileName);
  }

  //on click of View Power Bi report
  function onViewPowerBiClickHandler() {
    navigate(`/${routes.powerBI}`, {
      state: {
        reportId: process.env.REACT_APP_POWER_BI_REPORT_ID_M1,
        module: "M1",
        engagemnentId: engagement.id,
        engagementCode: engagement.engagementCode,
      },
      gestureEnabled: false,
    });
  }

  const saveMostRecentYear = async (event) => {
    let year = event.target.value;
    if (!setError && parseFloat(year) > 2000 && parseFloat(year) < 2100) {
      await updateMostRecentEndYear({
        request: {
          engagementId: engagement.id,
          mostRecentYear: parseFloat(mostRecentYear),
        },
      });
      dispatch(
        setEngagementMostRecentYear({ recentYear: parseFloat(mostRecentYear) })
      );
    }
  };

  return (
    <div className="manage-emissions">
      <div className="manage-emissions-header">
        <span className="manage-emissions-header-main">
          {manageEmissionHeaderMain}
        </span>
        <span className="manage-emissions-header-desc">
          {manageEmisionHeaderDesc}
        </span>
      </div>
      <div className="manage-emissions-body">
        <div className="manage-emissions-body-template">
          <span className="manage-emissions-body-template-title">
            {manageEmissionBodyTemplateTitle}&nbsp;
            <MotifTooltip
              trigger={<EmissionIcon />}
              variant="ghost"
              tooltipText={templatefileM1tooltip}
            ></MotifTooltip>
          </span>

          <DownloadLink template={template} text={DownloadTemplateBtn} />
        </div>
        <div className="manage-emissions-body-upload">
          <div className="manage-emissions-body-upload-year">
            <span className="manage-emissions-body-upload-year-title">
              {manageEmissionBodyUploadRecentYear}
              <span className="required-star">*</span>
              <MotifTooltip
                trigger={<EmissionIcon />}
                variant="ghost"
                tooltipText={mostRecentYeartooltip}
              ></MotifTooltip>
            </span>
            <Input
              className="base-year-scope-3"
              label="Input"
              onChange={mostRecentYearChange}
              onBlur={saveMostRecentYear}
              value={mostRecentYear}
              required
              type="number"
              maxLength={4}
              isError={setError}
              errorMessage={FourDigitYear}
              disabled={
                isMostRecentYearDisabled ||
                (lockedData === 1 && lockedBy !== user.username)
              }
            />
            <MotifTooltip
              trigger={
                <Button
                  onClick={onFileVersionClickHandler}
                  variant="text"
                  // disabled={filesToUpload.length === 0 || startUploadProcess}
                  className={"manage-emissions-body-upload-file-history-button"}
                >
                  <RepeatIcon />
                  {manageEmissionBodyUploadFileHistory}
                </Button>
              }
              variant="ghost"
              tooltipText={versionHistoryTooltip}
            ></MotifTooltip>
          </div>
          <div className="manage-emissions-body-upload-section">
            <span className="manage-emissions-body-upload-title">
              {manageEmissionBodyUploadTitle}
            </span>
            <div className="manage-emissions-body-upload-file">
              <div className="manage-emissions-body-upload-file-uploader">
                <FileUploader
                  filesToUpload={filesToUpload}
                  onDrop={onDrop}
                  accept={{
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                      [".xlsx"],
                  }}
                  multiple={false}
                  percentUploaded={percentUploaded}
                  onDelete={onDelete}
                  uploaded={uploaded || showPercentage}
                  disabled={
                    uploaded ||
                    startUploadProcess ||
                    (lockedData === 1 && lockedBy !== user.username)
                  }
                  isDeleteButtonDisabled={
                    isDeleteButtonDisabled ||
                    (lockedData === 1 && lockedBy !== user.username)
                  }
                />
              </div>
              <div
                className={
                  startUploadProcess
                    ? "show-file-upload-status"
                    : "hide-file-upload-status"
                }
              >
                <span>{startUploadProcessStatus}</span>
                <LoadingIndicator
                  show={
                    !startUploadProcessStatus
                      .toLowerCase()
                      .includes(FailedStatus.toLowerCase())
                  }
                  fullscreen={false}
                />
              </div>
              <div className="manage-emissions-body-upload-uploader-manage-file-buttons">
                <Tooltip
                  open={open}
                  placement="top"
                  arrow
                  title={saveButtonTooltip}
                >
                  <div>
                    <Button
                      onClick={onSaveFileClickHandler}
                      variant="primary-alt"
                      disabled={
                        filesToUpload.length === 0 ||
                        uploaded ||
                        startUploadProcess
                      }
                    >
                      <SaveIcon />
                      {manageEmissionBodyUploadSaveFile}
                    </Button>
                  </div>
                </Tooltip>
                <Button
                  onClick={onViewPowerBiClickHandler}
                  variant="secondary"
                  disabled={!uploaded || setError}
                >
                  <GraphIcon />
                  {PoweBiBtn}
                </Button>
              </div>
              <div
                className={`${
                  excelStatus === "Success"
                    ? "template-upload-success"
                    : "template-upload-error"
                } ${
                  excelTemplateError
                    ? "show-file-upload-status"
                    : "hide-file-upload-status"
                }`}
              >
                {excelTemplateError}
              </div>
              <div
                className={`excel-upload-data-verification-error ${
                  excelDataVerificationFailed
                    ? "show-file-upload-status"
                    : "hide-file-upload-status"
                }`}
              >
                <span>{excelDataVerificationFailed}</span>

                <LoadingIndicator
                  show={
                    excelDataVerificationFailed &&
                    !excelDataVerificationFailedFile
                  }
                  fullscreen={false}
                >
                  <Button
                    onClick={onSaveErrorReportClickHandler}
                    variant="secondary"
                    className={"forecast-download-error-report-button"}
                  >
                    <DownloadIcon />
                    {DownloadErrorReportBtn}
                  </Button>
                </LoadingIndicator>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FileVersionHistoryModal
        isModalVisible={isFileVersionHistoryModalVisible}
        onClose={() => {
          setIsFileVersionHistoryModalVisible(false);
        }}
      />
      {lockedData === 2 && lockedBy === user.username && (
        <LockerModal
          isOpen={isLockerModalOpen}
          onClose={() => setLockerModalOpen(false)}
        />
      )}
    </div>
  );
}
