import { POWER_BI_URL } from "./endpoints/powerBI";
import axios from "axios";
import { get, post } from "./base.service";
// async function getPowerBIEmbedToken({ request }) {
//   const instance = axios.create();

//   return await instance
//     .get
//     // `${POWER_BI_URL.GetEmbedToken}?userName=${request.username}&datasetId=${request.datasetId}`,
//     // `${POWER_BI_URL.GetEmbedToken}?userName=${request.username}&datasetId=84DD8366-85B0-45EB-A278-B7FDB254C880`,

//     // {
//     //   withCredentials: true,
//     //   baseURL: request.baseUrl,
//     //   headers: {
//     //     Authorization: `Bearer ${request.accessToken}`,
//     //   },
//     // }
//     ();
// }

async function getPowerBIEmbedToken({ request }) {
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "GET",
    ControllerName: "PowerBI",
    ActionName: "GetEmbedPowerBIToken",
    QueryString:
      "userName=" + request.username + `&` + "moduleName=" + request.module,
    Data: "",
  });
}

export { getPowerBIEmbedToken };
